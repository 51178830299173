import React from 'react';
import classNames from 'classnames';

import './Checkbox.scss';

const Checkbox = ({ label, checked, id, name, disabled, onChange }) => {
  return (
    <label
      htmlFor={id}
      className={classNames('form__checkbox', {
        'form__checkbox--disabled': disabled,
      })}
    >
      <input
        type="checkbox"
        id={id}
        name={name}
        checked={checked}
        disabled={disabled}
        onChange={() => onChange(name, !checked)}
      />
      <span className="form__checkbox__check" />
      <span className="form__checkbox__label">{label}</span>
    </label>
  );
};

export default Checkbox;
