import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './Icon.scss';

import { ReactComponent as ArrowRight } from 'assets/icons/icon_arrow-right.svg';
import { ReactComponent as ArrowLeft } from 'assets/icons/icon_arrow-left.svg';
import { ReactComponent as Calendar } from 'assets/icons/icon_calendar.svg';
import { ReactComponent as Check } from 'assets/icons/icon_check.svg';
import { ReactComponent as Chevron } from 'assets/icons/icon_chevron.svg';
import { ReactComponent as Circle } from 'assets/icons/icon_circle.svg';
import { ReactComponent as Cross } from 'assets/icons/icon_cross.svg';
import { ReactComponent as Edit } from 'assets/icons/icon_edit.svg';
import { ReactComponent as Image } from 'assets/icons/icon_image.svg';
import { ReactComponent as Plus } from 'assets/icons/icon_plus.svg';
import { ReactComponent as Rectangle } from 'assets/icons/icon_rectangle.svg';
import { ReactComponent as Repeat } from 'assets/icons/icon_repeat.svg';
import { ReactComponent as Triangle } from 'assets/icons/icon_triangle.svg';
import { ReactComponent as Url } from 'assets/icons/icon_url.svg';
import { ReactComponent as Video } from 'assets/icons/icon_video.svg';
import { ReactComponent as Warning } from 'assets/icons/icon_warning.svg';
import { ReactComponent as TwoFactorAuthentication } from 'assets/icons/icon_two-factor-authentication.svg';
import { ReactComponent as Folder } from 'assets/icons/icon_folder.svg';
import { ReactComponent as MoreOptions } from 'assets/icons/icon_more_options.svg';

export const IconList = {
  'arrow-right': <ArrowRight />,
  'arrow-left': <ArrowLeft />,
  calendar: <Calendar />,
  check: <Check />,
  chevron: <Chevron />,
  circle: <Circle />,
  cross: <Cross />,
  edit: <Edit />,
  image: <Image />,
  plus: <Plus />,
  rectangle: <Rectangle />,
  repeat: <Repeat />,
  triangle: <Triangle />,
  url: <Url />,
  video: <Video />,
  warning: <Warning />,
  twoFactorAuthentication: <TwoFactorAuthentication />,
  folder: <Folder />,
  more_options: <MoreOptions />,
};

const Icon = ({ name, block, className, size }) => {
  return (
    <span
      className={classNames('icon', className, {
        'icon--block': block,
        [`icon--${size}`]: size,
      })}
    >
      {IconList[name] || <img src={`/icons/icon_${name}.svg`} alt={name} />}
    </span>
  );
};

Icon.propTypes = {
  block: PropTypes.bool,
  className: PropTypes.string,
  size: PropTypes.oneOf(['', 'small', 'medium', 'large']), // TODO: Make all icons behave in size based on icon size, instead of size that is hardcoded in the SVG.
};

Icon.defaultProps = {
  block: true,
  className: null,
  size: '',
};

export default Icon;
