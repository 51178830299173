import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import rrulePlugin from '@fullcalendar/rrule';

import '@fullcalendar/core/main.css';
import '@fullcalendar/list/main.css';
import '@fullcalendar/timegrid/main.css';

const hourToScroll = new Date().getHours() - 2;

const calendarOptions = {
  plugins: [rrulePlugin, timeGridPlugin, listPlugin, interactionPlugin],
  allDaySlot: false,
  slotEventOverlap: false,
  nowIndicator: true,
  firstDay: 1,
  scrollTime: `${hourToScroll > 0 ? hourToScroll : 0}:00:00`,
  columnHeaderFormat: {
    weekday: 'short',
    day: 'numeric',
  },
  titleFormat: {
    year: 'numeric',
    month: 'long',
  },
  slotLabelFormat: {
    week: 'narrow',
    hour12: false,
    hour: 'numeric',
    minute: '2-digit',
    meridiem: false,
  },
  header: {
    left: 'title',
    center: '',
    right: 'timeGridWeek  prev,today,next',
  },
  buttonText: {
    list: 'Show in list',
    today: 'Today',
    timeGridWeek: 'Show in calendar',
  },
  buttonIcons: {
    prev: '',
    next: '',
  },
};

export default calendarOptions;
