import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import Truncate from 'react-truncate-markup';
import classNames from 'classnames';

import Card from 'components/card/Card';
import Button from 'components/button/Button';
import MediaCardImage from 'components/media/card-image/MediaCardImage';
import { editMediaAction } from 'store/media/mediaActions';
import Icon from 'components/icon/Icon';

import './MediaCard.scss';

const MediaCard = ({
  id,
  title,
  image,
  type,
  selectable,
  selected,
  setSelected,
}) => {
  const [isTruncated, setTruncated] = useState(false);
  const dispatch = useDispatch();
  const [imageNotLoaded, setImageNotLoaded] = useState(true);

  const cardClick = () => {
    if (selectable) {
      setSelected(id, !selected);
    } else {
      dispatch(editMediaAction(id));
    }
  };

  return (
    <button
      type="button"
      tabIndex="0"
      onClick={cardClick}
      onKeyDown={e => e.key === 13 && cardClick}
      className={classNames({ button__interaction: !selectable })}
      title={isTruncated ? title : ''}
    >
      <Card
        className={classNames('media-card', {
          'media-card--selectable': selectable,
          'media-card--selected': selected,
          'media-card--with-image': type === 'image' && imageNotLoaded,
        })}
      >
        {selectable && (
          <div className="media-card__check">
            <Icon name="check" />
          </div>
        )}

        <MediaCardImage {...{ type, title, image, setImageNotLoaded }} />

        <div className="media-card__content">
          <h3
            className={classNames('media-card__title', {
              'media-card__title--url':
                title.startsWith('http') || title.startsWith('rstp'),
            })}
          >
            <Truncate
              lines={2}
              ellipsis={`...${
                title.length > 7
                  ? title.substring(title.length - 7, title.length)
                  : ''
              }`}
              onTruncate={truncated => setTruncated(truncated)}
              trimWhitespace
            >
              <span>{title}</span>
            </Truncate>
          </h3>
        </div>

        <Button
          tag="span"
          text={<Icon name="arrow-right" />}
          size="small"
          hasShadow={false}
          className="card__button"
          scheme="card"
          onClick={() => dispatch(editMediaAction(id))}
        />
      </Card>
    </button>
  );
};

MediaCard.propTypes = {
  id: PropTypes.number,
  title: PropTypes.string,
  image: PropTypes.string,
  type: PropTypes.oneOf(['rtsp', 'video', 'image', 'rss', 'other']),
  selectable: PropTypes.bool,
  selected: PropTypes.bool,
  setSelected: PropTypes.func,
};

MediaCard.defaultProps = {
  id: null,
  title: '',
  image: '',
  type: 'other',
  selectable: false,
  selected: false,
  setSelected: null,
};

export default MediaCard;
