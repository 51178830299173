import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';

import { setInfoBarShow, setInfoBarMessage } from 'store/info/infoActions';
import Button from 'components/button/Button';
import Icon from 'components/icon/Icon';

import './Infobar.scss';

const Infobar = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [timer, setTimer] = useState(null);
  const [time, setTime] = useState(0);
  const { show, message, state, action, timeout } = useSelector(
    state => state.info.bar,
  );

  const icons = {
    '': 'check',
    warning: 'warning',
    error: 'cross',
  };

  useEffect(() => {
    const { state } = history.location;

    if (state?.infoMessage) {
      setTimeout(() => {
        dispatch(setInfoBarMessage(state.infoMessage));
      }, 0);

      history.replace({ state: null });
    }

    // only hide infoBar on route change when it is still visible and there is no sign of a group being added or deleted.
    // TODO: Refactor if statement, because there are a lot of exceptional situations right now.
    if (
      show &&
      state &&
      !state.groupAdded &&
      !state.groupDeleted &&
      !state.infoMessage &&
      !state.unprocessableMediaItems
    ) {
      dispatch(setInfoBarShow(false));
    }
  }, []);

  useEffect(() => {
    if (show && timeout > 0) {
      // clear the infobar of the message
      clearTimeout(timer);

      setTimer(
        setTimeout(() => {
          setTime(time + 1);
          dispatch(setInfoBarShow(false));
        }, timeout),
      );
    } else if (timeout === 0) {
      clearTimeout(timer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show, timeout, time]);

  return (
    <CSSTransition
      in={show}
      timeout={300}
      classNames="fixed-bottom"
      unmountOnExit
    >
      <div className="infobar">
        <span
          className={classNames('infobar__icon', {
            'infobar__icon--error': state === 'error',
            'infobar__icon--warning': state === 'warning',
          })}
        >
          <Icon name={icons[state]} size="small" />
        </span>
        <span className="infobar__text">{message}</span>
        {(Array.isArray(action) &&
          action.map(ac => (
            <Button
              key={`infobar_button_${ac.text}`}
              type="button"
              handler={ac.handle}
              className={`infobar__action ${ac.className}`}
              size="medium"
              hasShadow={false}
              color={ac.color || ''}
              text={ac.text}
            />
          ))) ||
          (action && (
            <Button
              type="button"
              handler={action.handle}
              className="infobar__action"
              size="small"
              hasShadow={false}
              scheme="link"
              text={action.text}
            />
          ))}
      </div>
    </CSSTransition>
  );
};

export default Infobar;
