import { DevicesActionTypes } from './devicesActions';

export const devicesInitialState = {
  request: false,
  signs: [],
  error: null,
};

const devicesReducer = (state = devicesInitialState, action) => {
  switch (action.type) {
    case DevicesActionTypes.DEVICES_REQUESTED:
      return { ...devicesInitialState, request: true };
    case DevicesActionTypes.DEVICES_SUCCEEDED:
      return { ...action.payload, request: false, error: null };
    case DevicesActionTypes.DEVICES_FAILED:
      return { ...devicesInitialState, request: false, error: action.payload };
    case DevicesActionTypes.DEVICE_ADDED:
      return {
        ...state,
        signs: [action.payload, ...state.signs],
      };
    case DevicesActionTypes.DEVICES_CLEARED:
      return devicesInitialState;
    case DevicesActionTypes.DEVICE_DELETED:
      return {
        ...state,
        signs: state.signs.filter(item => item.id !== action.payload),
      };
    default:
      return state;
  }
};

export default devicesReducer;
