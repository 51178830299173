import fetchData from 'store/fetchData';
import { Paths } from 'constants/Routes';
import { reverse } from 'named-urls';
import {
  setInfoBarMessage,
  setInfoBarError,
  setInfoBarWarning,
} from 'store/info/infoActions';
import { popupActionClear, popupActionSet } from 'store/popup/popupActions';
import PopupTypes from 'constants/PopupTypes';
import { hasUserRights } from '../auth/hasUserRights';
import UserRights from '../../constants/UserRights';

export const MediaActionTypes = {
  MEDIA_REQUESTED: '@@media/requested',
  MEDIA_SUCCEEDED: '@@media/success',
  MEDIA_FAILED: '@@media/error',
  MEDIA_ADDED: '@@media/added',
  MEDIA_DELETED: '@@media/deleted',
  MEDIA_CLEARED: '@@media/cleared',
  MEDIA_SELECTABLE: '@@media/selectable',
  MEDIA_FOLDER_UPDATED: '@@media/folder_updated',
  MEDIA_FOLDER_ADDED: '@@media/folder_added',
  MEDIA_FOLDER_DELETED: '@@media/folder_deleted',
};

export const mediaRequested = () => ({
  type: MediaActionTypes.MEDIA_REQUESTED,
});

export const mediaSucceeded = media => ({
  type: MediaActionTypes.MEDIA_SUCCEEDED,
  payload: media,
});

export const mediaFailed = () => ({
  type: MediaActionTypes.MEDIA_FAILED,
});

export const mediaAdded = media => ({
  type: MediaActionTypes.MEDIA_ADDED,
  payload: media,
});

export const mediaDeleted = id => ({
  type: MediaActionTypes.MEDIA_DELETED,
  payload: id,
});

export const mediaFolderUpdated = folder => ({
  type: MediaActionTypes.MEDIA_FOLDER_UPDATED,
  payload: folder,
});

export const mediaFolderAdded = folder => ({
  type: MediaActionTypes.MEDIA_FOLDER_ADDED,
  payload: folder,
});

export const mediaFolderDeleted = id => ({
  type: MediaActionTypes.MEDIA_FOLDER_DELETED,
  payload: id,
});

export const mediaCleared = () => ({
  type: MediaActionTypes.MEDIA_CLEARED,
});

export const mediaSelectable = selectable => ({
  type: MediaActionTypes.MEDIA_SELECTABLE,
  payload: selectable,
});

export const addMediaAction = ({ message, media }) => dispatch => {
  dispatch(mediaAdded(media));
  dispatch(popupActionClear());

  dispatch(setInfoBarMessage(message));
};

export const deleteMediaAction = id => dispatch => {
  // call delete endpoint
  const promise = dispatch(
    fetchData(reverse(Paths.API.MEDIA_DELETE, { id }), { method: 'DELETE' }),
  );

  promise
    .then(json => json.json())
    .then(result => {
      const { message } = result;

      // media is deleted successfully - clear popup and show message
      dispatch(mediaDeleted(id));
      dispatch(popupActionClear());

      dispatch(setInfoBarMessage(message));
    })
    .catch(err => {
      // media could not be deleted - show error
      console.error(err);
      dispatch(setInfoBarError(err));
    });
};

export const editMediaAction = id => dispatch => {
  const promise = dispatch(fetchData(reverse(Paths.API.MEDIA_DETAIL, { id })));
  const hasMediaEditRights = dispatch(hasUserRights(UserRights.MEDIA_EDIT));

  promise
    .then(json => json.json())
    .then(({ media_item }) => {
      dispatch(popupActionSet(PopupTypes.MEDIA_PREVIEW, media_item));

      if (!hasMediaEditRights) {
        dispatch(
          setInfoBarWarning('You only have the rights to view this media item'),
        );
      }
    })
    .catch(() => {});
};

export const cancelUpdateMediaItems = arrangementId => dispatch => {
  const promise = dispatch(
    fetchData(
      reverse(Paths.API.ARRANGEMENT_MEDIA_ITEMS_CANCEL, {
        id: arrangementId,
      }),
      {
        method: 'POST',
      },
    ),
  );

  promise
    .then(json => json.json())
    .then(result => {
      console.log(result);
    });
};

export const filterMediaAction = ({ name, filetype }) => dispatch => {
  const promise = dispatch(`Paths.API.MEDIA)?name=${name}`);
  promise
    .then(json => json.json())
    .then(result => {
      console.log(result);
    })
    .catch(err => {
      console.error(err);
      dispatch(setInfoBarError('Could not fetch media'));
    });
};

export const fetchMediaAction = ({ pool_id, folder_id }) => dispatch => {
  dispatch(mediaRequested());

  let apiUrl = reverse(Paths.API.MEDIA);

  if (pool_id) {
    apiUrl = reverse(Paths.API.MEDIA_BY_POOL, { pool_id });
  } else if (folder_id) {
    apiUrl = reverse(Paths.API.MEDIA_BY_FOLDER, { folder_id });
  }

  const promise = dispatch(fetchData(apiUrl));

  promise
    .then(json => json.json())
    .then(result => {
      dispatch(mediaSucceeded(result));
    })
    .catch(() => {
      dispatch(mediaFailed());
      dispatch(setInfoBarError('Could not fetch media'));
    });
};

export const selectMediaItems = selectable => dispatch => {
  dispatch(mediaSelectable(selectable));
};

export const editMediaFolderAction = id => dispatch => {
  const promise = dispatch(
    fetchData(reverse(Paths.API.MEDIA_FOLDER_PATCH, { id })),
  );
  promise
    .then(json => json.json())
    .then(({ folder_info }) => {
      dispatch(popupActionSet(PopupTypes.MEDIA_FOLDER, folder_info));
    })
    .catch(() => {});
};

export const deleteMediaFolderAction = (
  id,
  breadCrumbs,
  history,
) => dispatch => {
  const promise = dispatch(
    fetchData(reverse(Paths.API.MEDIA_FOLDER_DELETE, { id }), {
      method: 'DELETE',
    }),
  );
  promise
    .then(() => {
      const currentFolderId =
        breadCrumbs &&
        breadCrumbs.length &&
        breadCrumbs[breadCrumbs.length - 1].id;

      if (id === currentFolderId && breadCrumbs.length >= 2) {
        const parentFolderId =
          breadCrumbs &&
          breadCrumbs.length &&
          breadCrumbs[breadCrumbs.length - 2].id;

        const location = {
          pathname: reverse(Paths.API.MEDIA_DELETE, { id: parentFolderId }),
        };
        history.push(location);
      }
      if (id === currentFolderId && breadCrumbs.length === 1) {
        const location = {
          pathname: reverse(Paths.API.MEDIA),
        };
        history.push(location);
      }
      dispatch(mediaFolderDeleted(id));
      dispatch(popupActionClear());
      dispatch(setInfoBarMessage('Folder has been removed'));
    })
    .catch(err => {
      console.error(err);
    });
};

export const saveMediaFolderAction = (values, id, history) => dispatch => {
  const folderData = new FormData();
  folderData.append('media_folder[title]', values.title);

  if (values.media_folder_id >= 0) {
    folderData.append(
      'media_folder[media_folder_id]',
      values.media_folder_id === 0 ? '' : values.media_folder_id,
    );
  }

  const promise = dispatch(
    fetchData(
      id
        ? reverse(Paths.API.MEDIA_FOLDER_PATCH, { id })
        : Paths.API.MEDIA_FOLDER_POST,
      {
        method: id ? 'PATCH' : 'POST',
        body: folderData,
      },
      false,
    ),
  );

  promise
    .then(json => json.json())
    .then(({ media_folder }) => {
      if (id) {
        dispatch(mediaFolderUpdated(media_folder));
        if (values.media_folder_id >= 0) {
          dispatch(
            setInfoBarMessage(
              'The folder has been repositioned',
              {
                handle: () => {
                  history.push({
                    pathname:
                      values.media_folder_id === 0
                        ? Paths.MEDIA
                        : reverse(Paths.MEDIA_FOLDER, {
                            folder_id: values.media_folder_id,
                          }),
                  });
                },
                text: 'Go to folder',
              },
              true,
            ),
          );
        } else {
          dispatch(setInfoBarMessage('The folder has been updated'));
        }
      } else {
        dispatch(mediaFolderAdded(media_folder.folder_info));
        dispatch(setInfoBarMessage('The folder has been created'));
      }

      if (values.media_folder_id >= 0) {
        dispatch(mediaFolderDeleted(id));
      }

      dispatch(popupActionClear());
    })
    .catch(() => {});
};
