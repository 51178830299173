import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Paths } from 'constants/Routes';
import { reverse } from 'named-urls';

import Deck from 'components/deck/Deck';
import MediaCardFolder from 'components/media/card-folder/MediaCardFolder';

import './Folders.scss';

const Folders = ({ editable }) => {
  const { folders } = useSelector(state => state.media);
  const pool = useSelector(state => state.pool);

  const folderPath = (poolId, folderId) => {
    const pathName = window.location.pathname;

    if (pathName.startsWith(Paths.MEDIA)) {
      return reverse(Paths.MEDIA_FOLDER, { folder_id: folderId });
    }
    return reverse(Paths.POOLS_MEDIA_FOLDER, {
      id: poolId,
      folder_id: folderId,
    });
  };

  return (
    <Deck>
      {folders &&
        folders.map(folder => (
          <MediaCardFolder
            title={folder.title}
            id={folder.id}
            key={folder.id}
            link={folderPath(pool.id, folder.id)}
            editable={editable}
          />
        ))}
      {!folders.length && <p className="folders__empty">No folders found</p>}
    </Deck>
  );
};

Folders.propTypes = {
  editable: PropTypes.bool,
};

Folders.defaultProps = {
  editable: false,
};

export default Folders;
