import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';

import FormBlock from 'components/form/block/FormBlock';
import Input from 'components/form/input/Input';
import Button from 'components/button/Button';
import { popupActionClear } from 'store/popup/popupActions';
import { infoBarHide, setInfoBarConfirm } from 'store/info/infoActions';
import {
  addArrangementAction,
  deleteArrangementAction,
  upsertArrangementAction,
  toggleActiveArrangementAction,
  arrangementApproved,
} from 'store/arrangements/arrangementsActions';
import Checkbox from 'components/form/checkbox/Checkbox';
import LayoutDropdown from 'components/layout/dropdown/LayoutDropdown';
import { setPoolDefaultArrangement } from 'store/pool/poolActions';
import Icon from 'components/icon/Icon';
import ArrangementMedia from 'components/arrangement/media/ArrangementMedia';
import Status from 'components/status/Status';
import { hasAdminRights, hasUserRights } from 'store/auth/hasUserRights';
import UserRights from 'constants/UserRights';
import ArrangementLayout from 'components/arrangement/layout/ArrangementLayout';

const Arrangement = ({
  id,
  name,
  media,
  template_id,
  isDefault,
  active,
  pending_approval,
  pending_changes,
}) => {
  const dispatch = useDispatch();
  const pool = useSelector(state => state.pool);
  const infoBar = useSelector(state => state.info.bar);
  const history = useHistory();
  const { state } = history.location;
  const isAdmin = dispatch(hasAdminRights());
  const hasArrangementEditRights = dispatch(
    hasUserRights(UserRights.ARRANGEMENTS_EDIT),
  );
  const [isActive, setActive] = useState(active);
  const [initialForm, setInitialForm] = useState({
    name: name || '',
    template: template_id || null,
    isDefault: isDefault || false,
  });

  const validationScheme = Yup.object().shape({
    name: Yup.string(),
    template: Yup.number()
      .nullable()
      .required('Layout is a required field'),
  });

  const clearHistoryFromArrangementState = () => {
    if (state && state.fromArrangement) {
      const clearHistoryFromArrangementState = {
        state: {
          fromArrangement: undefined,
        },
      };
      history.push(clearHistoryFromArrangementState);
    }
  };

  const onCancel = () => {
    clearHistoryFromArrangementState();
    dispatch(popupActionClear());
    if (infoBar.show) {
      dispatch(infoBarHide());
    }
  };

  const onSubmit = values => {
    const promise = dispatch(upsertArrangementAction(values, id, pool));

    promise
      .then(json => json.json())
      .then(result => {
        clearHistoryFromArrangementState();
        dispatch(addArrangementAction(result));

        if (id) {
          dispatch(arrangementApproved(result));
        }

        if (values.isDefault) {
          dispatch(setPoolDefaultArrangement(pool.id, result.arrangement));
        }
      });
  };

  useEffect(() => {
    setInitialForm({
      name: state?.fromArrangementFormFormValues?.name || name || '',
      template:
        state?.fromArrangementFormFormValues?.template || template_id || null,
      isDefault: isDefault || false,
    });
  }, [pending_changes]);

  return (
    <Formik
      initialValues={initialForm}
      validationSchema={validationScheme}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {({ values, errors, handleChange, setFieldValue, handleSubmit }) => (
        <form onSubmit={handleSubmit} className="form">
          <fieldset>
            <div>
              <FormBlock>
                <Input
                  type="text"
                  name="name"
                  id="name"
                  placeholder={id ? 'Show' : 'New show'}
                  value={
                    (pending_approval && pending_changes?.name) || values.name
                  }
                  error={errors.name}
                  onChange={handleChange}
                  fontSize="large"
                  suffix={<Icon name="edit" />}
                  isChanged={pending_approval && !!pending_changes?.name}
                  disabled={pending_approval || !hasArrangementEditRights}
                />
              </FormBlock>

              <div className="form__information">
                <span className="form__information__detail">
                  {pool.name} - {pool.location.name}
                </span>

                {id && (
                  <Status
                    isActive={isActive}
                    needsApproval={pending_approval}
                  />
                )}
              </div>
            </div>
          </fieldset>

          <fieldset>
            <div>
              <legend>Layout</legend>

              {values.template && (
                <ArrangementLayout
                  templateId={values.template}
                  bottomMargin
                  showNumbers
                />
              )}

              <FormBlock>
                <LayoutDropdown
                  poolId={pool.id}
                  label="Select a layout"
                  name="template"
                  id="template"
                  value={
                    (pending_approval && pending_changes?.template_id) ||
                    values.template
                  }
                  error={errors.template}
                  onChange={setFieldValue}
                  isChanged={pending_approval && !!pending_changes?.template}
                  disabled={pending_approval || !hasArrangementEditRights}
                />
              </FormBlock>
            </div>
          </fieldset>

          <ArrangementMedia
            media={media}
            arrangementId={id}
            templateId={values.template}
            formValues={values}
            pending_approval={pending_approval}
          />

          {id && (
            <fieldset>
              <div>
                <legend>Default show</legend>
                <p className="form__text">
                  {isDefault
                    ? `This show is set as default. To set another show as default, select
                    the 'Set as default show' option in that show.`
                    : `Setting this show as ‘default show’ will result into displaying
                    this show everytime there are no shows planned or triggers
                    activated. Setting this show as default show will override the
                    current default show.`}
                </p>
                <FormBlock>
                  <Checkbox
                    id="isDefault"
                    name="isDefault"
                    checked={values.isDefault}
                    onChange={setFieldValue}
                    label="Set as default show"
                    disabled={
                      !isAdmin || !!isDefault || pending_approval || !isActive
                    }
                  />
                </FormBlock>
              </div>
            </fieldset>
          )}
          <div>
            <FormBlock hasInlineChildren isEnd>
              {(id && !isDefault && hasArrangementEditRights && (
                <Button
                  tag="a"
                  size="medium"
                  text="Delete"
                  scheme="link"
                  hasShadow={false}
                  handler={() => {
                    if (hasArrangementEditRights) {
                      dispatch(
                        setInfoBarConfirm(
                          'Are you sure you want to delete this event?',
                          {
                            text: 'Yes, Delete',
                            handle: () =>
                              dispatch(deleteArrangementAction(id, true)),
                          },
                        ),
                      );
                    }
                  }}
                  disabled={!hasArrangementEditRights}
                />
              )) || (
                <Button
                  tag="button"
                  type="submit"
                  size="medium"
                  text="Cancel"
                  scheme="link"
                  hasShadow={false}
                  handler={() => {
                    onCancel();
                  }}
                />
              )}

              <div>
                {id && (
                  <Button
                    tag="a"
                    size="medium"
                    scheme="link"
                    text={isActive ? 'Deactivate' : 'Activate'}
                    hasShadow={false}
                    handler={() => {
                      if (
                        !pending_approval &&
                        !isDefault &&
                        hasArrangementEditRights
                      )
                        dispatch(
                          toggleActiveArrangementAction(
                            id,
                            isActive,
                            setActive,
                          ),
                        );
                    }}
                    disabled={
                      pending_approval || isDefault || !hasArrangementEditRights
                    }
                  />
                )}
                <Button
                  tag="button"
                  type="submit"
                  size="medium"
                  text="Save and close"
                  disabled={pending_approval || !hasArrangementEditRights}
                />
              </div>
            </FormBlock>
          </div>
        </form>
      )}
    </Formik>
  );
};

export default Arrangement;
