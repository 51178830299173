import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import './Text.scss';

const Text = ({ children, className, color, flatten, size, tag }) => {
  return React.createElement(
    tag,
    {
      className: classNames(
        {
          [`text--${size}`]: size,
          [`text--color-${color}`]: color,
          'text--flatten': flatten,
        },
        className,
      ),
    },
    children,
  );
};

Text.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  color: PropTypes.oneOf(['grey-dark']),
  flatten: PropTypes.bool,
  size: PropTypes.oneOf(['small']),
  tag: PropTypes.oneOf(['p', 'div', 'span']),
};

Text.defaultProps = {
  children: [],
  className: null,
  color: null,
  flatten: false,
  size: null,
  tag: 'p',
};

export default Text;
