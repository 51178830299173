import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { reverse } from 'named-urls';

import { Paths } from 'constants/Routes';
import fetchData from 'store/fetchData';
import Dropdown from 'components/form/dropdown/Dropdown';

const TriggerDropdown = ({ poolId, ...props }) => {
  const dispatch = useDispatch();

  const [triggers, setTriggers] = useState([]);
  const [loadingTriggers, setLoadingTriggers] = useState(false);

  useEffect(() => {
    setLoadingTriggers(true);

    const promise = dispatch(
      fetchData(reverse(Paths.API.TOPICS, { pool_id: poolId })),
    );
    promise
      .then(json => json.json())
      .then(({ topics, ...result }) => {
        console.log(result);
        setTriggers(
          topics.map(topic => {
            return {
              label: topic.public_name,
              value: topic.id,
            };
          }),
        );
      })
      .finally(() => {
        setLoadingTriggers(false);
      });
  }, [dispatch, poolId]);

  return <Dropdown {...props} values={triggers} isLoading={loadingTriggers} />;
};

export default TriggerDropdown;
