import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import ArrangementCard from 'components/arrangement/card/ArrangementCard';
import Deck from 'components/deck/Deck';
import PopupTypes from 'constants/PopupTypes';
import PoolButton from 'components/pool/button/PoolButton';
import {
  fetchArrangementsAction,
  editArrangementAction,
} from 'store/arrangements/arrangementsActions';
import { hasUserRights } from 'store/auth/hasUserRights';
import UserRights from 'constants/UserRights';
import DeckEmpty from 'components/deck/DeckEmpty';
import Button from 'components/button/Button';

const PoolsArrangements = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const history = useHistory();
  const pool = useSelector(state => state.pool);
  const { arrangements } = useSelector(state => state.arrangements);
  const hasArrangementsViewRights = dispatch(
    hasUserRights(UserRights.ARRANGEMENTS_VIEW),
  );
  const hasArrangementsEditRights = dispatch(
    hasUserRights(UserRights.ARRANGEMENTS_EDIT),
  );

  const { default_arrangement } = pool;

  const noArrangementsViewRightsSubTitle = (
    <p>
      In case you should have view rights please contact your supervisor or send
      an email to{' '}
      <Button
        hasShadow={false}
        scheme="link"
        link="mailto:support@gamingsupport.com"
        text="support@gamingsupport.com"
        size="inline"
      />
    </p>
  );

  useEffect(() => {
    const { state } = history.location;

    dispatch(
      fetchArrangementsAction(id, state?.unprocessableMediaItems, history),
    );

    if (state && state.fromArrangement) {
      dispatch(
        editArrangementAction(
          state.fromArrangement,
          pool.default_arrangement.id,
        ),
      );
    }
  }, [id]);

  return (
    <>
      {hasArrangementsViewRights ? (
        <>
          {default_arrangement && (
            <>
              <h2>Default show</h2>
              <Deck>
                <ArrangementCard
                  key={default_arrangement.id}
                  id={default_arrangement.id}
                  name={default_arrangement.name}
                  templateId={default_arrangement.template_id}
                  active={default_arrangement.active}
                  pendingApproval={default_arrangement.pending_approval}
                />
              </Deck>
            </>
          )}

          {arrangements.length > 0 && (
            <>
              <h2>Other shows</h2>
              <Deck>
                {arrangements.map(arrangement => {
                  return (
                    !arrangement.hidden && (
                      <ArrangementCard
                        key={arrangement.id}
                        id={arrangement.id}
                        name={arrangement.name}
                        templateId={arrangement.template.id}
                        active={arrangement.active}
                        pendingApproval={arrangement.pending_approval}
                      />
                    )
                  );
                })}
              </Deck>
            </>
          )}

          {hasArrangementsEditRights && (
            <PoolButton text="Add show" popupType={PopupTypes.ARRANGEMENT} />
          )}
        </>
      ) : (
        <DeckEmpty
          title="No view rights for shows"
          subtitle={noArrangementsViewRightsSubTitle}
        />
      )}
    </>
  );
};

export default PoolsArrangements;
