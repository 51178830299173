import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import { RRule, rrulestr } from 'rrule';

import FormBlock from 'components/form/block/FormBlock';
import Dropdown from 'components/form/dropdown/Dropdown';
import DropdownMultiple from 'components/form/dropdown-multiple/DropdownMultiple';
import Input from 'components/form/input/Input';
import DateInput from 'components/form/datetime/DateInput';
import { parseISO } from 'date-fns';
import { hasUserRights } from 'store/auth/hasUserRights';
import UserRights from 'constants/UserRights';
import { RecurringOptions, MonthlyIntervalOptions } from './Recurring.options';

const Recurring = ({
  id,
  name,
  value,
  onChange,
  startDate,
  pending_approval,
  pending_changes,
}) => {
  const dispatch = useDispatch();
  const hasEventEditRights = dispatch(hasUserRights(UserRights.EVENTS_EDIT));
  const [repeat, setRepeat] = useState(RecurringOptions.NO_REPEAT);

  // Daily values
  const [dailyInterval, setDailyInterval] = useState(1);

  // Weekly values
  const [weeklyDays, setWeeklyDays] = useState([]);

  // Monthly values
  const [monthlyDays, setMonthlyDays] = useState([]);
  const [monthlyInterval, setMonthlyInterval] = useState(1);
  const [monthlyIntervalDay, setMonthlyIntervalDay] = useState(0);

  const [rrule, setRrule] = useState(value);
  const [dateUntil, setDateUntil] = useState(
    new Date(startDate).setFullYear(new Date(startDate).getFullYear() + 1),
  );
  const popup = useSelector(state => state.popup);

  const dayList = [
    { label: 'Monday', value: RRule.MO.weekday },
    { label: 'Tuesday', value: RRule.TU.weekday },
    { label: 'Wednesday', value: RRule.WE.weekday },
    { label: 'Thursday', value: RRule.TH.weekday },
    { label: 'Friday', value: RRule.FR.weekday },
    { label: 'Saturday', value: RRule.SA.weekday },
    { label: 'Sunday', value: RRule.SU.weekday },
  ];

  const rruleToObject = rrule => {
    if (rrule === undefined || rrule === null) return {};

    const result = {};
    const rrulePairs = rrule.split(';');
    rrulePairs.forEach(pair => {
      const pairSplit = pair.split('=');
      result[pairSplit[0]] = decodeURIComponent(pairSplit[1] || '');
    });

    return result;
  };

  const currentRruleObject = rruleToObject(rrule);
  const changedRruleObject = rruleToObject(pending_changes?.rrule);

  const rruleChanged = (currentRruleObject, changedRruleObject) => {
    if (currentRruleObject === undefined && changedRruleObject === undefined)
      return false;

    const untilChanged = currentRruleObject.UNTIL !== changedRruleObject.UNTIL;
    const freqChanged = currentRruleObject.FREQ !== changedRruleObject.FREQ;
    const bySetPosChanged =
      currentRruleObject.BYSETPOS !== changedRruleObject.BYSETPOS;
    const byDayChanged = currentRruleObject.BYDAY !== changedRruleObject.BYDAY;
    const byMonthDayChanged =
      currentRruleObject.BYMONTHDAY !== changedRruleObject.BYMONTHDAY;
    const intervalChanged =
      currentRruleObject.INTERVAL !== changedRruleObject.INTERVAL;

    return {
      UNTIL: untilChanged,
      FREQ: freqChanged,
      INTERVAL: intervalChanged,
      BYSETPOS: bySetPosChanged,
      BYDAY: byDayChanged,
      BYMONTHDAY: byMonthDayChanged,
    };
  };

  const monthDaysStringToKeyValueArray = monthDaysString => {
    const result = [];

    if (!monthDaysString) {
      return result;
    }

    const monthDays = monthDaysString.split(',');

    monthDays.forEach(day => {
      result.push({ label: Number(day), value: Number(day) });
    });

    return result;
  };

  const weekDaysStringToKeyValueArray = weekDaysString => {
    const weekDays = weekDaysString.split(',');
    const result = [];

    weekDays.forEach(day => {
      const index = dayList.findIndex(
        x => x.label.toUpperCase().substring(0, 2) === day,
      );
      const dayName = dayList[index].label;

      result.push({ label: dayName, value: index });
    });

    return result;
  };

  const setStateFromRRule = rule => {
    switch (rule.options.freq) {
      case RRule.DAILY:
        setRepeat(RecurringOptions.DAILY);
        setDailyInterval(rule.options.interval);
        break;

      case RRule.WEEKLY:
        setRepeat(RecurringOptions.WEEKLY);
        setWeeklyDays(
          rule.options.byweekday.map(day =>
            dayList.find(d => {
              return d.value === day;
            }),
          ),
        );
        break;

      case RRule.MONTHLY:
        if (rule.options.bymonthday && rule.options.bymonthday.length > 0) {
          setRepeat(RecurringOptions.MONTHLY_NUMBER);
          setMonthlyDays(
            rule.options.bymonthday.map(day => {
              return { label: day, value: day };
            }),
          );
        } else if (rule.options.bysetpos) {
          setRepeat(RecurringOptions.MONTHLY_DAY);
          setMonthlyInterval(rule.options.bysetpos[0]);
          setMonthlyIntervalDay(rule.options.byweekday[0]);
        }
        break;

      default:
        setRepeat(RecurringOptions.NO_REPEAT);
        break;
    }
    setDateUntil(rule.options.until);
  };

  // When popup is opened and rrule is updated, update form state for repeat values
  useEffect(() => {
    if (popup.data?.rrule) {
      const rruleString = rrulestr(popup.data.rrule);
      setStateFromRRule(rruleString);
    } else {
      setRepeat(RecurringOptions.NO_REPEAT);
    }
    // eslint-disable-next-line
  }, [popup.data?.rrule, pending_changes]);

  // when any of the rule related fields are changed
  useEffect(() => {
    if (repeat !== RecurringOptions.NO_REPEAT) {
      const ruleOptions = {
        until: new Date(dateUntil),
      };

      switch (repeat) {
        default:
        case RecurringOptions.DAILY:
          ruleOptions.freq = RRule.DAILY;
          ruleOptions.interval = dailyInterval;
          break;

        case RecurringOptions.WEEKLY:
          ruleOptions.freq = RRule.WEEKLY;
          ruleOptions.byweekday = weeklyDays.map(day => day.value);
          break;

        case RecurringOptions.MONTHLY_NUMBER:
          ruleOptions.freq = RRule.MONTHLY;
          ruleOptions.bymonthday = monthlyDays.map(day => day.value);
          break;

        case RecurringOptions.MONTHLY_DAY:
          ruleOptions.freq = RRule.MONTHLY;
          ruleOptions.bysetpos = monthlyInterval;
          ruleOptions.byweekday = monthlyIntervalDay;
          break;
      }

      const rule = new RRule(ruleOptions);
      setRrule(rule.toString().replace('RRULE:', ''));
    } else {
      setRrule('');
    }
  }, [
    repeat,
    dailyInterval,
    weeklyDays,
    monthlyDays,
    monthlyInterval,
    monthlyIntervalDay,
    startDate,
    dateUntil,
  ]);

  // when the rrule is changed
  useEffect(() => {
    onChange(name, rrule);
  }, [name, rrule, onChange]);

  const rruleIsChangedByDayValue = changedRruleObject.BYDAY;
  const rruleIsChangedByMonthDayValue = changedRruleObject.BYMONTHDAY;
  const rruleIsChangedFreqValue =
    changedRruleObject.FREQ && changedRruleObject.FREQ.toLowerCase();
  const rruleIsChangedIntervalValue = changedRruleObject.INTERVAL;
  const rruleIsChangedBySetPosValue = changedRruleObject.BYSETPOS;
  const rruleIsChangedUntilValue =
    changedRruleObject.UNTIL && parseISO(changedRruleObject.UNTIL);
  const rruleIsChanged = pending_changes?.rrule !== undefined;
  const rruleBySetPosIsChanged =
    rruleIsChanged &&
    rruleChanged(currentRruleObject, changedRruleObject).BYSETPOS === true;
  const rruleByDayIsChanged =
    rruleIsChanged && rruleChanged(rrule, changedRruleObject).BYDAY === true;
  const rruleByMonthDayIsChanged =
    rruleIsChanged &&
    rruleChanged(currentRruleObject, changedRruleObject).BYMONTHDAY === true;
  const rruleFreqIsChanged =
    (rruleIsChanged &&
      rruleChanged(currentRruleObject, changedRruleObject).FREQ === true) ||
    (rruleIsChanged &&
      repeat === 'monthly_day' &&
      rruleIsChangedByDayValue === undefined &&
      rruleIsChangedBySetPosValue === undefined) ||
    (rruleIsChanged &&
      repeat === 'monthly_number' &&
      rruleIsChangedByDayValue !== undefined &&
      rruleIsChangedBySetPosValue !== undefined);
  const rruleIntervalIsChanged =
    rruleIsChanged &&
    rruleChanged(currentRruleObject, changedRruleObject).INTERVAL === true;
  const rruleUntilIsChanged =
    rruleIsChanged &&
    rruleChanged(currentRruleObject, changedRruleObject).UNTIL === true;

  const freqOptions = [
    { label: 'Not repeating', value: RecurringOptions.NO_REPEAT },
    { label: 'Daily', value: RecurringOptions.DAILY },
    { label: 'Weekly', value: RecurringOptions.WEEKLY },
    { label: 'Monthly by day', value: RecurringOptions.MONTHLY_DAY },
    {
      label: 'Monthly by number',
      value: RecurringOptions.MONTHLY_NUMBER,
    },
  ];

  return (
    <>
      <FormBlock ignoreSplit>
        <Dropdown
          label="Repeat"
          name={name}
          id={id}
          values={freqOptions}
          width={200}
          value={
            (pending_approval &&
              rruleFreqIsChanged &&
              rruleIsChangedFreqValue !== 'monthly' &&
              rruleIsChangedFreqValue) ||
            (pending_approval &&
              rruleFreqIsChanged &&
              rruleIsChangedBySetPosValue !== undefined &&
              rruleIsChangedFreqValue &&
              `${rruleIsChangedFreqValue}_day`) ||
            (pending_approval &&
              rruleFreqIsChanged &&
              rruleIsChangedBySetPosValue === undefined &&
              rruleIsChangedFreqValue &&
              `${rruleIsChangedFreqValue}_number`) ||
            (pending_approval &&
              rruleFreqIsChanged &&
              !rruleIsChangedFreqValue &&
              RecurringOptions.NO_REPEAT) ||
            repeat
          }
          onChange={(func, value) => setRepeat(value)}
          isChanged={
            (pending_approval &&
              pending_changes &&
              !!pending_changes.rrule &&
              rruleFreqIsChanged) ||
            (pending_approval &&
              pending_changes &&
              pending_changes.rrule === null) ||
            (pending_approval && pending_changes === undefined)
          }
          disabled={pending_approval || !hasEventEditRights}
        />

        <CSSTransition
          in={
            rruleFreqIsChanged
              ? rruleIsChangedFreqValue === 'daily'
              : repeat === RecurringOptions.DAILY
          }
          timeout={{
            enter: 300,
          }}
          classNames="toggle"
          unmountOnExit
        >
          <Input
            name="daily_occurances"
            id="daily_occurances"
            type="number"
            label="Every"
            suffix="day(s)"
            width={120}
            scheme="white"
            value={
              (pending_approval &&
                rruleIntervalIsChanged &&
                Number(rruleIsChangedIntervalValue)) ||
              dailyInterval
            }
            min={1}
            onChange={e => setDailyInterval(e.target.value)}
            isChanged={
              (pending_approval &&
                pending_changes &&
                !!pending_changes.rrule &&
                rruleIsChangedFreqValue === 'daily' &&
                rruleIntervalIsChanged) ||
              (pending_approval && pending_changes === undefined)
            }
            disabled={pending_approval || !hasEventEditRights}
          />
        </CSSTransition>

        <CSSTransition
          in={
            rruleFreqIsChanged
              ? rruleIsChangedFreqValue === 'weekly'
              : repeat === RecurringOptions.WEEKLY
          }
          timeout={{
            enter: 300,
          }}
          classNames="toggle"
          unmountOnExit
        >
          <DropdownMultiple
            name="weekly_days"
            id="weekly_days"
            label="Which days of the week"
            scheme="white"
            width={300}
            value={
              (pending_approval &&
                pending_changes &&
                rruleByDayIsChanged &&
                weekDaysStringToKeyValueArray(rruleIsChangedByDayValue)) ||
              weeklyDays
            }
            onChange={v => setWeeklyDays(v)}
            values={dayList}
            isChanged={
              (pending_approval &&
                pending_changes &&
                !!pending_changes.rrule &&
                rruleByDayIsChanged) ||
              (pending_approval && pending_changes === undefined)
            }
            disabled={pending_approval || !hasEventEditRights}
          />
        </CSSTransition>

        <CSSTransition
          in={
            rruleFreqIsChanged
              ? rruleIsChangedByDayValue === undefined &&
                rruleIsChangedBySetPosValue === undefined &&
                rruleIsChangedIntervalValue === undefined &&
                rruleIsChangedFreqValue === 'monthly'
              : repeat === RecurringOptions.MONTHLY_NUMBER
          }
          timeout={{
            enter: 300,
          }}
          classNames="toggle"
          unmountOnExit
        >
          <DropdownMultiple
            name="monthly_nums"
            id="monthly_nums"
            label="Which days of the month"
            scheme="white"
            width={300}
            value={
              (pending_approval &&
                pending_changes &&
                rruleByMonthDayIsChanged &&
                monthDaysStringToKeyValueArray(
                  rruleIsChangedByMonthDayValue,
                )) ||
              monthlyDays
            }
            onChange={v => setMonthlyDays(v)}
            values={[...Array(31).keys()].map(d => {
              return { value: d + 1, label: d + 1 };
            })}
            isChanged={
              (pending_approval &&
                pending_changes &&
                !!pending_changes.rrule &&
                rruleIsChangedFreqValue === 'monthly' &&
                rruleByMonthDayIsChanged) ||
              (pending_approval && pending_changes === undefined)
            }
            disabled={pending_approval || !hasEventEditRights}
          />
        </CSSTransition>

        <CSSTransition
          in={
            rruleFreqIsChanged
              ? rruleIsChangedByDayValue !== undefined &&
                rruleIsChangedBySetPosValue !== undefined
              : repeat === RecurringOptions.MONTHLY_DAY
          }
          timeout={300}
          classNames="fade"
          unmountOnExit
        >
          <Dropdown
            label="Every"
            name="monthly_interval"
            id="monthly_interval"
            values={[
              { label: 'First', value: MonthlyIntervalOptions.FIRST },
              { label: 'Second', value: MonthlyIntervalOptions.SECOND },
              { label: 'Third', value: MonthlyIntervalOptions.THIRD },
              { label: 'Fourth', value: MonthlyIntervalOptions.FOURTH },
              {
                label: 'Last',
                value: MonthlyIntervalOptions.LAST,
              },
            ]}
            width={170}
            value={
              (pending_approval &&
                rruleBySetPosIsChanged &&
                Number(rruleIsChangedBySetPosValue)) ||
              monthlyInterval
            }
            onChange={(func, value) => setMonthlyInterval(value)}
            isChanged={
              (pending_approval &&
                pending_changes &&
                !!pending_changes.rrule &&
                rruleIsChangedFreqValue === 'monthly' &&
                repeat === 'monthly_day' &&
                rruleBySetPosIsChanged) ||
              (pending_approval && pending_changes === undefined)
            }
            disabled={pending_approval || !hasEventEditRights}
          />
        </CSSTransition>
        <CSSTransition
          in={
            rruleFreqIsChanged
              ? rruleIsChangedByDayValue !== undefined &&
                rruleIsChangedBySetPosValue !== undefined
              : repeat === RecurringOptions.MONTHLY_DAY
          }
          timeout={300}
          classNames="fade"
          unmountOnExit
        >
          <Dropdown
            label="Day of the month"
            name="monthly_interval_day"
            id="monthly_interval_day"
            values={dayList}
            width={170}
            value={
              (pending_approval &&
                rruleByDayIsChanged &&
                dayList.findIndex(
                  x =>
                    x.label.toUpperCase().substring(0, 2) ===
                    rruleIsChangedByDayValue,
                )) ||
              monthlyIntervalDay
            }
            onChange={(func, value) => setMonthlyIntervalDay(value)}
            isChanged={
              (pending_approval &&
                pending_changes &&
                !!pending_changes.rrule &&
                rruleIsChangedFreqValue === 'monthly' &&
                rruleByDayIsChanged) ||
              (pending_approval && pending_changes === undefined)
            }
            disabled={pending_approval || !hasEventEditRights}
          />
        </CSSTransition>
      </FormBlock>

      <CSSTransition
        in={
          (repeat !== RecurringOptions.NO_REPEAT &&
            pending_changes?.rrule !== null) ||
          rruleIsChangedUntilValue
        }
        timeout={300}
        classNames="fade"
        unmountOnExit
      >
        <FormBlock>
          <DateInput
            name="dateUntil"
            id="dateUntil"
            label="Repeat until"
            onChange={(func, value) => setDateUntil(value)}
            value={
              (pending_approval &&
                rruleUntilIsChanged &&
                rruleIsChangedUntilValue) ||
              dateUntil
            }
            isChanged={
              (pending_approval && rruleUntilIsChanged) ||
              (pending_approval && pending_changes === undefined)
            }
            disabled={pending_approval || !hasEventEditRights}
          />
        </FormBlock>
      </CSSTransition>
    </>
  );
};

export default Recurring;
