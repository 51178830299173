import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { reverse } from 'named-urls';

import { Paths } from 'constants/Routes';
import fetchData from 'store/fetchData';
import Dropdown from 'components/form/dropdown/Dropdown';

const ArrangementDropdown = ({ poolId, ...props }) => {
  const dispatch = useDispatch();
  const [arrangements, setArrangements] = useState([]);
  const [loadingArrangements, setLoadingArrangements] = useState(false);

  useEffect(() => {
    setLoadingArrangements(true);
    const promise = dispatch(
      fetchData(
        `${reverse(Paths.API.ARRANGEMENTS_BY_POOL, {
          pool_id: poolId,
        })}?active=true`,
      ),
    );
    promise
      .then(json => json.json())
      .then(result => {
        const arrs = result.arrangements.map(arrangement => {
          return {
            label: arrangement.name,
            value: arrangement.id,
          };
        });

        setArrangements(arrs);
      })
      .catch(err => {
        console.error(err);
        setArrangements([]);
      })
      .finally(() => {
        setLoadingArrangements(false);
      });

    return () => {
      setLoadingArrangements(false);
      setArrangements([]);
    };
  }, [poolId]);

  return (
    <Dropdown
      {...props}
      values={arrangements}
      isLoading={loadingArrangements}
    />
  );
};

export default ArrangementDropdown;
