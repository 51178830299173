import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Card from 'components/card/Card';
import ArrangementLayout from 'components/arrangement/layout/ArrangementLayout';
import Button from 'components/button/Button';
import Icon from 'components/icon/Icon';
import { editArrangementAction } from 'store/arrangements/arrangementsActions';
import Status from 'components/status/Status';

import './ArrangementCard.scss';

const ArrangementCard = ({ id, name, templateId, active, pendingApproval }) => {
  const dispatch = useDispatch();
  const pool = useSelector(state => state.pool);

  const cardClick = () => {
    dispatch(editArrangementAction(id, pool.default_arrangement?.id));
  };

  return (
    <button
      type="button"
      tabIndex="0"
      onClick={cardClick}
      onKeyDown={e => e.key === 13 && cardClick}
      className="button__interaction"
    >
      <Card className="arrangement-card" zoomable>
        <ArrangementLayout templateId={templateId} />
        <h3 className="arrangement-card__title">{name}</h3>

        <div className="card__state">
          <Status isActive={active} needsApproval={pendingApproval} />
        </div>

        <Button
          tag="span"
          text={<Icon name="arrow-right" />}
          size="small"
          hasShadow={false}
          className="card__button"
          scheme="card"
        />
      </Card>
    </button>
  );
};

export default ArrangementCard;
