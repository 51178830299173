import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import PopupTypes from 'constants/PopupTypes';
import PoolButton from 'components/pool/button/PoolButton';
import Deck from 'components/deck/Deck';
import DeckEmpty from 'components/deck/DeckEmpty';

import DevicesCard from 'components/devices/card/DevicesCard';
import { fetchDevicesAction } from 'store/devices/devicesActions';
import { hasUserRights } from 'store/auth/hasUserRights';
import UserRights from 'constants/UserRights';
import Button from 'components/button/Button';

const PoolsDevices = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const pool = useSelector(state => state.pool);
  const { signs } = useSelector(state => state.devices);
  const hasDevicesEditRights = dispatch(hasUserRights(UserRights.SIGNS_EDIT));
  const hasDevicesViewRights = dispatch(hasUserRights(UserRights.SIGNS_VIEW));

  useEffect(() => {
    dispatch(fetchDevicesAction(pool.id, history));
  }, [dispatch, history, pool]);

  const noDevicesViewRightsSubTitle = (
    <p>
      In case you should have view rights please contact your supervisor or send
      an email to{' '}
      <Button
        hasShadow={false}
        scheme="link"
        link="mailto:support@gamingsupport.com"
        text="support@gamingsupport.com"
        size="inline"
      />
    </p>
  );

  return (
    <>
      {hasDevicesViewRights ? (
        <>
          {signs.length > 0 && (
            <Deck>
              {signs.map(device => {
                return (
                  <DevicesCard
                    key={device.id}
                    id={device.id}
                    name={device.name}
                    active={device.online_status}
                    pendingApproval={device.pending_approval}
                  />
                );
              })}
            </Deck>
          )}
          {signs.length === 0 && (
            <DeckEmpty
              title="No devices added"
              subtitle="Click on the blue + to start adding devices to the group"
            />
          )}
          {hasDevicesEditRights && (
            <PoolButton text="Add device" popupType={PopupTypes.DEVICE} />
          )}
        </>
      ) : (
        <DeckEmpty
          title="No view rights for devices"
          subtitle={noDevicesViewRightsSubTitle}
        />
      )}
    </>
  );
};

export default PoolsDevices;
