import React from 'react';

import Header from 'components/header/Header';

import './Private.scss';
import { useSelector } from 'react-redux';

const PrivateBody = ({ children }) => {
  const { error } = useSelector(state => state.info);

  return (
    <>
      <Header />

      {!error && <div className="private-body">{children}</div>}
    </>
  );
};

export default PrivateBody;
