import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import equal from 'fast-deep-equal';

import Input from 'components/form/input/Input';
import DateInput from 'components/form/datetime/DateInput';
import TimeInput from 'components/form/datetime/TimeInput';
import Recurring from 'components/form/recurring/Recurring';
import FormBlock from 'components/form/block/FormBlock';
import Button from 'components/button/Button';
import {
  addEventAction,
  deleteEventAction,
  toggleActiveEventAction,
} from 'store/events/eventsActions';
import ArrangementDropdown from 'components/arrangement/dropdown/ArrangementDropdown';
import useCalendarDates from 'hooks/useCalendarDates';
import { popupActionClear } from 'store/popup/popupActions';
import { setInfoBarConfirm, infoBarHide } from 'store/info/infoActions';
import Status from 'components/status/Status';
import Icon from 'components/icon/Icon';
import { format, parseISO } from 'date-fns';
import { hasUserRights } from 'store/auth/hasUserRights';
import UserRights from 'constants/UserRights';

const Event = ({
  startDate,
  endDate,
  roundTime,
  id,
  title,
  rrule,
  arrangement,
  active,
  pending_approval,
  pending_changes,
}) => {
  const dispatch = useDispatch();
  const pool = useSelector(state => state.pool);
  const infoBar = useSelector(state => state.info.bar);

  const hasEventEditRights = dispatch(hasUserRights(UserRights.EVENTS_EDIT));

  const [start, end] = useCalendarDates(startDate, endDate, roundTime);

  const [isActive, setActive] = useState(active);

  const [initialForm, setInitialForm] = useState({
    name: title || '',
    startDate: start,
    startTime: start,
    endDate: end,
    endTime: end,
    rrule: rrule || '',
    arrangement,
  });

  const dateTimeChanges = (currentDateString, changedDateString) => {
    const currentDate = format(new Date(currentDateString), 'MM/dd/yyyy');
    const changedDate = format(new Date(changedDateString), 'MM/dd/yyyy');
    const currentTime = format(new Date(currentDateString), 'p');
    const changedTime = format(new Date(changedDateString), 'p');

    const dateChanged =
      currentDate !== changedDate && currentTime === changedTime;
    const timeChanged =
      currentDate === changedDate && currentTime !== changedTime;
    const dateAndTimeChanged =
      currentDate !== changedDate && currentTime !== changedTime;

    if (dateChanged) {
      return 'dateChanged';
    }
    if (timeChanged) {
      return 'timeChanged';
    }
    if (dateAndTimeChanged) {
      return 'dateAndTimeChanged';
    }
    return 'nothingChanged';
  };

  const combineDates = (d, t) => {
    const date = new Date(d);
    const time = new Date(t);

    const newDate = new Date(
      date.getYear(),
      date.getMonth(),
      date.getDay(),
      time.getHours(),
      time.getMinutes(),
    );

    return newDate;
  };

  const validationScheme = Yup.object().shape({
    name: Yup.string().required('Name is a required field'),
    arrangement: Yup.number().required('Show is a required field'),
    endTime: Yup.date().when(
      ['startDate', 'endDate', 'startTime'],
      (startDate, endDate, startTime) => {
        const startToCompare = combineDates(startDate, startTime);

        return Yup.date().test(
          'cannot-be-before',
          'End date/time cannot be before or equal to start date/time',
          value => {
            const endToCompare = combineDates(endDate, value);
            return startToCompare < endToCompare;
          },
        );
      },
    ),
  });

  const onSubmit = values => {
    const {
      name,
      startDate,
      startTime,
      endDate,
      endTime,
      rrule,
      arrangement,
    } = values;

    if (equal(values, initialForm)) {
      dispatch(popupActionClear());
      return;
    }

    // Combine time and date fields
    startDate.setHours(startTime.getHours());
    startDate.setMinutes(startTime.getMinutes());
    startDate.setSeconds(0);
    endDate.setHours(endTime.getHours());
    endDate.setMinutes(endTime.getMinutes());
    endDate.setSeconds(0);

    const eventData = new FormData();
    eventData.append('scheduled_event[title]', name);
    eventData.append('scheduled_event[start_at]', startDate);
    eventData.append('scheduled_event[end_at]', endDate);
    eventData.append('scheduled_event[arrangement_id]', arrangement);
    eventData.append('pool_id', pool.id);
    eventData.append('arrangement_id', arrangement);

    if (rrule) {
      eventData.append('scheduled_event[rrule]', rrule);
    }

    dispatch(addEventAction(eventData, id));
  };

  useEffect(() => {
    setInitialForm({
      name: title || '',
      startDate: start,
      startTime: start,
      endDate: end,
      endTime: end,
      rrule: rrule || '',
      arrangement,
    });
  }, [pending_changes]);

  return (
    <Formik
      initialValues={initialForm}
      validationSchema={validationScheme}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {({
        values,
        handleChange,
        handleSubmit,
        setFieldValue,
        initialTouched,
        errors,
        touched,
      }) => (
        <form onSubmit={handleSubmit} className="form">
          <fieldset>
            <FormBlock>
              <Input
                type="text"
                name="name"
                id="name"
                placeholder="New event"
                value={
                  (pending_approval && pending_changes?.title) || values.name
                }
                onChange={handleChange}
                fontSize="large"
                error={touched.name && errors.name}
                suffix={<Icon name="edit" />}
                isChanged={
                  (pending_approval &&
                    pending_changes &&
                    !!pending_changes.title) ||
                  (pending_approval && pending_changes === undefined)
                }
                disabled={pending_approval || !hasEventEditRights}
              />
            </FormBlock>

            <div className="form__information">
              <span>
                {pool.name} - {pool.location.name}
              </span>

              {id && <Status isActive={isActive} />}
            </div>
          </fieldset>

          <fieldset>
            <legend>Date and time</legend>

            <FormBlock noSpacing>
              <FormBlock ignoreSplit>
                <DateInput
                  label="Starts"
                  name="startDate"
                  id="startDate"
                  placeholder="Date"
                  value={
                    (pending_approval &&
                      pending_changes?.start_at &&
                      parseISO(pending_changes.start_at)) ||
                    values.startDate
                  }
                  onChange={setFieldValue}
                  isChanged={
                    (pending_approval &&
                      pending_changes &&
                      !!pending_changes.start_at &&
                      (dateTimeChanges(startDate, pending_changes.start_at) ===
                        'dateChanged' ||
                        dateTimeChanges(startDate, pending_changes.start_at) ===
                          'dateAndTimeChanged')) ||
                    (pending_approval && pending_changes === undefined)
                  }
                  disabled={pending_approval || !hasEventEditRights}
                />
                <TimeInput
                  name="startTime"
                  id="startTime"
                  placeholder="Date"
                  value={
                    (pending_approval &&
                      pending_changes?.start_at &&
                      parseISO(pending_changes.start_at)) ||
                    values.startTime
                  }
                  onChange={setFieldValue}
                  isChanged={
                    (pending_approval &&
                      pending_changes &&
                      !!pending_changes.start_at &&
                      (dateTimeChanges(startDate, pending_changes.start_at) ===
                        'timeChanged' ||
                        dateTimeChanges(startDate, pending_changes.start_at) ===
                          'dateAndTimeChanged')) ||
                    (pending_approval && pending_changes === undefined)
                  }
                  disabled={pending_approval || !hasEventEditRights}
                />
              </FormBlock>
              <FormBlock ignoreSplit>
                <DateInput
                  label="Ends"
                  name="endDate"
                  id="endDate"
                  placeholder="Date"
                  value={
                    (pending_approval &&
                      pending_changes?.end_at &&
                      parseISO(pending_changes.end_at)) ||
                    values.endDate
                  }
                  onChange={setFieldValue}
                  error={touched.endDate && errors.endDate}
                  isChanged={
                    (pending_approval &&
                      pending_changes &&
                      !!pending_changes.end_at &&
                      (dateTimeChanges(endDate, pending_changes.end_at) ===
                        'dateChanged' ||
                        dateTimeChanges(endDate, pending_changes.end_at) ===
                          'dateAndTimeChanged')) ||
                    (pending_approval && pending_changes === undefined)
                  }
                  disabled={pending_approval || !hasEventEditRights}
                />
                <TimeInput
                  name="endTime"
                  id="endTime"
                  placeholder="Date"
                  value={
                    (pending_approval &&
                      pending_changes?.end_at &&
                      parseISO(pending_changes.end_at)) ||
                    values.endTime
                  }
                  onChange={setFieldValue}
                  isChanged={
                    (pending_approval &&
                      pending_changes &&
                      !!pending_changes.end_at &&
                      (dateTimeChanges(endDate, pending_changes.end_at) ===
                        'timeChanged' ||
                        dateTimeChanges(endDate, pending_changes.end_at) ===
                          'dateAndTimeChanged')) ||
                    (pending_approval && pending_changes === undefined)
                  }
                  disabled={pending_approval || !hasEventEditRights}
                />
              </FormBlock>
            </FormBlock>

            {errors.endTime && <FormBlock error={errors.endTime} />}

            <Recurring
              value={values.rrule}
              onChange={setFieldValue}
              name="rrule"
              id="rrule"
              startDate={values.startDate}
              pending_approval={pending_approval}
              pending_changes={pending_changes}
            />
          </fieldset>

          <fieldset>
            <legend>Shows</legend>

            <FormBlock ignoreSplit>
              <ArrangementDropdown
                label="Select a show"
                name="arrangement"
                id="arrangement"
                value={
                  (pending_approval && pending_changes?.arrangement?.id) ||
                  values.arrangement
                }
                onChange={setFieldValue}
                width={350}
                poolId={pool.id}
                error={touched.arrangement && errors.arrangement}
                isChanged={
                  (pending_approval &&
                    pending_changes &&
                    !!pending_changes.arrangement) ||
                  (pending_approval && pending_changes === undefined)
                }
                disabled={pending_approval || !hasEventEditRights}
              />
            </FormBlock>
          </fieldset>

          <FormBlock hasInlineChildren isEnd>
            {(id && hasEventEditRights && (
              <Button
                tag="a"
                size="medium"
                text="Delete"
                scheme="link"
                hasShadow={false}
                handler={() => {
                  if (hasEventEditRights) {
                    dispatch(
                      setInfoBarConfirm(
                        'Are you sure you want to delete this event?',
                        {
                          text: 'Yes, Delete',
                          handle: () => dispatch(deleteEventAction(id)),
                        },
                      ),
                    );
                  }
                }}
                disabled={!hasEventEditRights}
              />
            )) || (
              <Button
                tag="button"
                type="submit"
                size="medium"
                text="Cancel"
                scheme="link"
                hasShadow={false}
                handler={() => {
                  dispatch(popupActionClear());
                  if (infoBar.show) {
                    dispatch(infoBarHide());
                  }
                }}
              />
            )}

            <div>
              {id && (
                <Button
                  tag="a"
                  size="medium"
                  scheme="link"
                  text={isActive ? 'Deactivate' : 'Activate'}
                  hasShadow={false}
                  handler={() => {
                    if (hasEventEditRights) {
                      dispatch(
                        toggleActiveEventAction(
                          id,
                          isActive,
                          setActive,
                          pending_approval,
                        ),
                      );
                    }
                  }}
                  disabled={pending_approval || !hasEventEditRights}
                />
              )}
              <Button
                tag="button"
                type="submit"
                size="medium"
                text={initialTouched ? 'Save and close' : 'Close'}
                disabled={pending_approval || !hasEventEditRights}
              />
            </div>
          </FormBlock>
        </form>
      )}
    </Formik>
  );
};

export default Event;
