import React from 'react';
import Select from 'react-select';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Label from 'components/form/label/Label';
import Icon from 'components/icon/Icon';

import './Dropdown.scss';

const Dropdown = ({
  label,
  name,
  values,
  value,
  onChange,
  id,
  width,
  disabled,
  isLoading,
  error,
  isChanged,
}) => {
  const dropdownStyling = {
    container: provided => ({
      ...provided,
      width,
    }),
    indicatorSeparator: () => ({
      border: 0,
    }),
    dropdownIndicator: provided => ({
      ...provided,
      display: 'none',
    }),
  };

  const emptyOption = {
    label: 'Select...',
    value: '',
  };
  const selectedValue = values && values.find(option => option.value === value);

  return (
    <Label label={label} idFor={id} className="form__dropdown__label">
      <div
        className={classNames('form__dropdown__container', {
          'form__dropdown__container--disabled': disabled,
        })}
      >
        <Select
          styles={dropdownStyling}
          name={name}
          id={id}
          className={classNames('form__dropdown', {
            'form__dropdown--changed': isChanged,
          })}
          classNamePrefix="form__dropdown"
          options={values.length > 0 ? [emptyOption, ...values] : []}
          value={selectedValue}
          isDisabled={disabled}
          isLoading={isLoading}
          onChange={e => onChange(name, e && e.value)}
          menuPosition="fixed"
        />

        <Icon
          name="chevron"
          className={classNames('', {
            'form__dropdown__icon--disabled': disabled,
          })}
        />
        {error && <span className="form__container-error" />}
      </div>

      {error && <p className="form__error">{error}</p>}
    </Label>
  );
};

Dropdown.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  name: PropTypes.string,
  values: PropTypes.arrayOf(PropTypes.any),
  // eslint-disable-next-line react/forbid-prop-types
  value: PropTypes.any,
  onChange: PropTypes.func,
  width: PropTypes.number,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  isChanged: PropTypes.bool,
};

Dropdown.defaultProps = {
  label: '',
  name: '',
  values: [],
  value: '',
  onChange: () => {},
  width: null,
  disabled: false,
  isLoading: false,
  error: false,
  isChanged: false,
};

export default Dropdown;
