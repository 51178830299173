import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';

import Button from 'components/button/Button';
import Icon from 'components/icon/Icon';
import Label from 'components/form/label/Label';
import fetchData from 'store/fetchData';
import { Paths } from 'constants/Routes';
import BlankButton from 'components/button/BlankButton';

import './MediaFolderDropdown.scss';

const MediaFolderItem = ({ folder, currentId, setValue }) => {
  const [isOpen, setOpen] = useState(false);

  const subFolder = folder.sub_folders.sort((a, b) => {
    return a.media_folder.title.localeCompare(b.media_folder.title, 'en', {
      numeric: true,
      sensitivity: 'base',
    });
  });

  return (
    <div
      className={classNames('folder-dropdown__item', {
        'folder-dropdown__item--disabled': currentId === folder.media_folder.id,
      })}
    >
      <div className="folder-dropdown__item__info">
        {(folder.sub_folders && folder.sub_folders.length > 0 && (
          <Button
            onClick={() => setOpen(!isOpen)}
            text={isOpen ? '–' : '+'}
            scheme="link"
            size="small"
            hasShadow={false}
            className="folder-dropdown__item__toggle"
          />
        )) || <span className="folder-dropdown__item__toggle" />}

        <Button
          onClick={() => setValue(folder.media_folder)}
          className="folder-dropdown__item__name"
          scheme="link"
          size="small"
          hasShadow={false}
          text={folder.media_folder.title}
        />
      </div>

      <>
        {subFolder &&
          isOpen &&
          subFolder.map(child => (
            <MediaFolderItem
              key={child.media_folder.id}
              folder={child}
              setValue={setValue}
              currentId={currentId}
            />
          ))}
      </>
    </div>
  );
};

const MediaFolderDropdown = ({ label, name, folderId, onChange, disabled }) => {
  const dispatch = useDispatch();

  const [isOpen, setOpen] = useState(false);
  const [currentValue, setValue] = useState();
  const [menuWidth, setWidth] = useState(0);
  const [folders, setFolders] = useState([]);
  const [isDisabled, setDisabled] = useState(disabled);

  const dropdownRef = useRef();

  useEffect(() => {
    const promise = dispatch(fetchData(Paths.API.MEDIA_FOLDER));

    promise
      .then(json => json.json())
      .then(({ media_folders }) => {
        const sortedFolders = media_folders.sort((a, b) => {
          return a.media_folder.title.localeCompare(
            b.media_folder.title,
            'en',
            {
              numeric: true,
              sensitivity: 'base',
            },
          );
        });

        setFolders(sortedFolders);
      })
      .catch(err => {
        setDisabled(true);
        console.error(err);
      });
  }, []);

  useEffect(() => {
    if (isOpen) {
      const element = dropdownRef.current;
      if (element) {
        setWidth(element.offsetWidth);
      }
    }
  }, [isOpen]);

  useEffect(() => {
    setOpen(false);

    if (currentValue) {
      onChange(name, currentValue.id);
    }
  }, [currentValue]);

  return (
    <Label label={label} idFor={name}>
      <div
        className={classNames('folder-dropdown', {
          'folder-dropdown--open': isOpen,
          'folder-dropdown--disabled': isDisabled,
        })}
      >
        <BlankButton
          tag="div"
          handler={() => setOpen(!isOpen)}
          className="folder-dropdown__selected"
          elementRef={dropdownRef}
        >
          {currentValue ? currentValue.title : 'Select a folder'}
          <Icon name="chevron" className="folder-dropdown__selected__toggle" />
        </BlankButton>

        {isOpen && (
          <div
            className="folder-dropdown__selection"
            style={{ width: menuWidth }}
          >
            <div className="folder-dropdown__item">
              <div className="folder-dropdown__info">
                <Button
                  onClick={() => setValue({ title: 'Home', id: 0 })}
                  className="folder-dropdown__item__name"
                  scheme="link"
                  size="small"
                  hasShadow={false}
                  text="Home"
                />
              </div>
            </div>
            {folders.map(
              folder =>
                folder &&
                !folder.media_folder.media_folder_id && (
                  <MediaFolderItem
                    key={folder.media_folder.id}
                    folder={folder}
                    setValue={setValue}
                    currentId={folderId}
                  />
                ),
            )}
          </div>
        )}
      </div>
    </Label>
  );
};

export default MediaFolderDropdown;
