import { reverse } from 'named-urls';

import fetchData from 'store/fetchData';
import { infoBarHide, setInfoBarError } from 'store/info/infoActions';
import { popupActionClear } from 'store/popup/popupActions';
import { Paths } from 'constants/Routes';

export const PoolActionTypes = {
  POOL_REQUESTED: '@@pool/requested',
  POOL_SUCCEEDED: '@@pool/success',
  POOL_FAILED: '@@pool/error',
  POOL_ADDED: '@@pool/added',
  POOL_DELETED: '@@pool/deleted',
  POOL_CLEARED: '@@pool/cleared',
  POOL_DEFAULT: '@@pool/default',
};

export const poolRequested = () => ({
  type: PoolActionTypes.POOL_REQUESTED,
});

export const poolSucceeded = pool => ({
  type: PoolActionTypes.POOL_SUCCEEDED,
  payload: pool,
});

export const poolFailed = () => ({
  type: PoolActionTypes.POOL_FAILED,
});

export const poolAdded = pool => ({
  type: PoolActionTypes.POOL_ADDED,
  payload: pool,
});

export const poolDeleted = id => ({
  type: PoolActionTypes.POOL_DELETED,
  payload: id,
});

export const poolCleared = () => ({
  type: PoolActionTypes.POOL_CLEARED,
});

export const poolDefaultArrangement = arrangement => ({
  type: PoolActionTypes.POOL_DEFAULT,
  payload: arrangement,
});

export const deletePoolAction = (id, history) => async dispatch => {
  const promise = dispatch(
    fetchData(reverse(Paths.API.POOLS_DELETE, { id }), { method: 'DELETE' }),
  );
  promise
    .then(result => {
      if (result) {
        dispatch(poolDeleted(id));
        dispatch(infoBarHide());

        const location = {
          pathname: Paths.POOLS,
          state: {
            groupDeleted: true,
          },
        };
        history.push(location);
      }
    })
    .catch(err => {
      console.error(err);
      // dispatch(setInfoBarError(err));
    });
};

export const addPoolAction = (values, history) => dispatch => {
  const groupData = new FormData();
  groupData.append('pool[name]', values.name);
  groupData.append('pool[location_id]', values.location);

  const promise = dispatch(
    fetchData(
      Paths.API.POOLS_POST,
      {
        method: 'POST',
        body: groupData,
      },
      false,
    ),
  );

  promise
    .then(json => json.json())
    .then(({ pool }) => {
      dispatch(popupActionClear());
      const location = {
        pathname: reverse(Paths.POOLS_DEVICES, { id: pool.id }),
        state: {
          groupAdded: true,
        },
      };
      history.push(location);
      dispatch(poolAdded(pool));
    })
    .catch(err => {
      console.error(err);
      dispatch(setInfoBarError('Failed to add new group'));
    });
};

export const fetchPool = id => async dispatch => {
  dispatch(poolRequested());

  const promise = dispatch(fetchData(reverse(Paths.API.POOLS_DETAIL, { id })));
  if (promise) {
    promise
      .then(json => json.json())
      .then(result => {
        dispatch(poolSucceeded(result));
      })
      .catch(() => {
        dispatch(poolFailed());
        dispatch(setInfoBarError('Could not fetch pool'));
      });
  }
};

export const setPoolDefaultArrangement = (poolId, arrangement) => dispatch => {
  const poolData = new FormData();
  poolData.append('pool[arrangement_id]', arrangement.id);

  const promise = dispatch(
    fetchData(
      reverse(Paths.API.POOLS_PATCH, { id: poolId }),
      {
        method: 'PATCH',
        body: poolData,
      },
      false,
    ),
  );

  promise
    .then(json => json.json())
    .then(() => {
      dispatch(poolDefaultArrangement(arrangement));
    })
    .catch(err => {
      console.error(err);
    });
};
