export const hasUserRights = userRight => (dispatch, getState) => {
  const { user } = getState().auth;

  if (user.rights) {
    return user.rights.indexOf(userRight) >= 0;
  }
  return false;
};

export const hasAdminRights = () => (dispatch, getState) => {
  const { user } = getState().auth;
  return user.admin || false;
};
