import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { reverse } from 'named-urls';

import { Paths } from 'constants/Routes';
import fetchData from 'store/fetchData';
import Dropdown from 'components/form/dropdown/Dropdown';

const LayoutDropdown = ({ poolId, ...props }) => {
  const dispatch = useDispatch();
  const [layouts, setLayouts] = useState([]);
  const [loadingLayouts, setLoadingLayouts] = useState(false);

  useEffect(() => {
    setLoadingLayouts(true);

    const promise = dispatch(
      fetchData(reverse(Paths.API.TEMPLATES_BY_POOL, { pool_id: poolId })),
    );

    promise
      .then(json => json.json())
      .then(({ templates }) => {
        const layouts = templates.map(template => {
          return {
            label: template.label,
            value: template.value,
          };
        });
        setLayouts(layouts);
      })
      .catch(err => {
        console.error(err);
        setLayouts([]);
      })
      .finally(() => {
        setLoadingLayouts(false);
      });

    return () => {
      setLoadingLayouts(false);
      setLayouts([]);
    };
  }, [poolId]);

  return <Dropdown {...props} values={layouts} isLoading={loadingLayouts} />;
};

export default LayoutDropdown;
