import React from 'react';
import { ErrorMessage } from 'formik';
import PropTypes from 'prop-types';

import './FormMessage.scss';

const FormMessage = ({ name, type, errorMessage }) => {
  return type === 'formikErrorMessage' ? (
    <ErrorMessage component="div" className="form-message" name={name} />
  ) : (
    errorMessage && <div className="form-message">{errorMessage}</div>
  );
};

FormMessage.propTypes = {
  type: PropTypes.oneOf(['formikErrorMessage', '']),
  errorMessage: PropTypes.string,
};

FormMessage.defaultProps = {
  type: '',
  errorMessage: null,
};

export default FormMessage;
