import { InfoActionTypes } from 'store/info/infoActions';

export const infoInitialState = {
  bar: {
    show: false,
    message: '',
    state: '',
    action: null,
    timeout: 5000,
  },
  error: null,
};

const infoReducer = (state = infoInitialState, action) => {
  switch (action.type) {
    case InfoActionTypes.INFO_BAR_SET:
      return {
        ...state,
        bar: { ...infoInitialState.bar, ...action.payload },
      };
    case InfoActionTypes.INFO_ERROR_SET:
      return { ...infoInitialState, error: action.payload };
    case InfoActionTypes.INFO_BAR_HIDE:
      return { ...state, bar: { ...state.bar, show: false } };
    case InfoActionTypes.INFO_BAR_SHOW:
      return { ...state, bar: { ...state.bar, show: true } };
    case InfoActionTypes.INFO_BAR_CLEAR:
      return { ...infoInitialState };
    default:
      return { ...state };
  }
};

export default infoReducer;
