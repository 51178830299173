import { Paths } from 'constants/Routes';

export const LoginActionTypes = {
  LOGIN_REQUESTED: '@@auth/login/requested',
  LOGIN_SUCCEEDED: '@@auth/login/success',
  LOGIN_FAILED: '@@auth/login/error',
  LOGOUT: '@@auth/logout',
};

export const loginActionRequested = () => ({
  type: LoginActionTypes.LOGIN_REQUESTED,
  payload: {
    request: true,
  },
});

export const loginActionSucceeded = auth => ({
  type: LoginActionTypes.LOGIN_SUCCEEDED,
  payload: {
    ...auth,
    request: false,
  },
});

export const loginActionFailed = error => ({
  type: LoginActionTypes.LOGIN_FAILED,
  payload: {
    request: false,
    error,
  },
});

export const logoutAction = () => ({
  type: LoginActionTypes.LOGOUT,
});

export const submitLoginAction = (password, username) => async dispatch => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_API}${Paths.API.AUTH_USER}`,
    {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        username,
        password,
      }),
    },
  );

  if (response.status >= 400) {
    response.json().then(
      json => {
        dispatch(loginActionFailed(json.errors[0]));
      },
      () => {
        dispatch(loginActionFailed('Invalid login/password combination'));
      },
    );
  }

  if (response.status === 200) {
    response.json().then(json => dispatch(loginActionSucceeded(json)));
  }
};
