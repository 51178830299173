import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Switch, Route } from 'react-router';
import { useParams } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';

import { Paths } from 'constants/Routes';
import PoolNavigation from 'components/pool/navigation/PoolNavigation';
import { fetchPool } from 'store/pool/poolActions';
import Container from 'components/container/Container';

import PoolsSchedules from 'pages/pools/Schedules';
import PoolsTrigger from 'pages/pools/Triggers';
import PoolsArrangements from 'pages/pools/Arrangements';
import PoolsMedia from 'pages/pools/Media';
import PoolsInfo from 'pages/pools/Info';
import PoolsDevices from 'pages/pools/Devices';

import './Detail.scss';

const PoolsDetail = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const pool = useSelector(state => state.pool);
  const [routeChanged, setRouteChanged] = useState(false);

  useEffect(() => {
    const paramsId = Number(params.id);
    if (paramsId === undefined || paramsId !== pool.id) {
      dispatch(fetchPool(paramsId));
    }
    setRouteChanged(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="detail-page">
      {pool.id !== 0 && (
        <>
          <Container>
            <h1>{pool.name}</h1>
          </Container>

          <PoolNavigation pool={pool} />

          <Container className="detail-page__content">
            <CSSTransition
              in={routeChanged}
              timeout={300}
              classNames="fade"
              unmountOnExit
            >
              <Switch>
                <Route
                  path={Paths.POOLS_SCHEDULES}
                  component={PoolsSchedules}
                />
                <Route path={Paths.POOLS_TRIGGERS} component={PoolsTrigger} />
                <Route
                  path={Paths.POOLS_ARRANGEMENTS}
                  component={PoolsArrangements}
                />
                <Route
                  path={[Paths.POOLS_MEDIA_FOLDER, Paths.POOLS_MEDIA]}
                  component={PoolsMedia}
                />
                <Route path={Paths.POOLS_DEVICES} component={PoolsDevices} />
                <Route path={Paths.POOLS_INFO} component={PoolsInfo} />
              </Switch>
            </CSSTransition>
          </Container>
        </>
      )}
    </div>
  );
};

export default PoolsDetail;
