import { infoInitialState } from 'store/info/infoReducer';

export const InfoActionTypes = {
  INFO_BAR_SET: '@@info/bar/set',
  INFO_BAR_CLEAR: '@@info/bar/clear',
  INFO_BAR_HIDE: '@@info/bar/hide',
  INFO_BAR_SHOW: '@@info/bar/show',
  INFO_ERROR_SET: '@@info/error/set',
};

export const infoBarSet = bar => ({
  type: InfoActionTypes.INFO_BAR_SET,
  payload: { ...bar },
});

export const infoErrorSet = message => ({
  type: InfoActionTypes.INFO_ERROR_SET,
  payload: message,
});

export const infoBarHide = () => ({
  type: InfoActionTypes.INFO_BAR_HIDE,
});

export const infoBarShow = () => ({
  type: InfoActionTypes.INFO_BAR_SHOW,
});

export const infoBarClear = () => ({
  type: InfoActionTypes.INFO_BAR_CLEAR,
});

export const setInfoBarShow = show => dispatch => {
  dispatch(show ? infoBarShow() : infoBarHide());

  // clears the infobar after hiding it
  if (!show) {
    setTimeout(() => {
      dispatch(infoBarClear());
    }, 300);
  }
};

export const setInfoBarApproval = (approve, reject) => dispatch => {
  dispatch(
    infoBarSet({
      message:
        'Highlighted fields were changed by another user and require approval',
      state: 'warning',
      timeout: 0,
      action: [
        {
          text: 'Approve',
          handle: approve,
          color: 'green',
          className: 'infobar__action--approval',
        },
        {
          text: 'Reject',
          handle: reject,
          color: 'red',
          className: 'infobar__action--approval',
        },
      ],
    }),
  );
  dispatch(infoBarShow());
};

export const setInfoBarDelete = (message, confirm, cancel) => dispatch => {
  dispatch(
    infoBarSet({
      message,
      state: 'warning',
      timeout: 0,
      action: [
        {
          text: 'Delete',
          handle: confirm,
          color: 'red',
          className: 'infobar__action--delete',
        },
        {
          text: 'Cancel',
          handle: cancel,
          color: 'green',
          className: 'infobar__action--delete',
        },
      ],
    }),
  );
  dispatch(infoBarShow());
};

export const setInfoBarMessage = (message, action, timeout) => dispatch => {
  dispatch(infoBarShow(false));

  if (action) {
    dispatch(
      infoBarSet({
        message,
        state: '',
        timeout: timeout ? infoInitialState.bar.timeout : 0,
        action,
      }),
    );
  } else {
    dispatch(infoBarSet({ message }));
  }

  dispatch(infoBarShow());
};

export const setInfoBarWarning = (message, action) => dispatch => {
  dispatch(infoBarShow(false));
  dispatch(
    infoBarSet({
      message,
      state: 'warning',
      timeout: 0,
      action,
    }),
  );
  dispatch(infoBarShow());
};

export const setInfoBarError = (message, action) => dispatch => {
  dispatch(infoBarShow(false));
  dispatch(
    infoBarSet({
      message,
      state: 'error',
      timeout: 0,
      action,
    }),
  );
  dispatch(infoBarShow());
};

export const setInfoBarConfirm = (message, action) => dispatch => {
  dispatch(setInfoBarError(message, action));
};
