import React, { createRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Calendar as Cal } from '@fullcalendar/core';

import { popupActionSet } from 'store/popup/popupActions';
import { fetchEventsAction, editEventAction } from 'store/events/eventsActions';
import PopupTypes from 'constants/PopupTypes';

import { hasUserRights } from 'store/auth/hasUserRights';
import UserRights from 'constants/UserRights';
import calendarOptions from './CalendarOptions';

import './Calendar.scss';

const Calendar = () => {
  const dispatch = useDispatch();
  const { events } = useSelector(state => state.events);
  const pool = useSelector(state => state.pool);
  const calendarRef = createRef();
  const [calendar, setCalendar] = useState(null);
  const { id } = useParams();
  const hasScheduledEventsEditRights = dispatch(
    hasUserRights(UserRights.EVENTS_EDIT),
  );

  const [time, setTime] = useState(new Date());

  // open event popup with startdate set
  const dateClick = ({ dateStr }) => {
    if (hasScheduledEventsEditRights) {
      dispatch(
        popupActionSet(PopupTypes.EVENT, {
          startDate: new Date(dateStr),
          roundTime: true,
        }),
      );
    }
  };

  // open event popup with event information
  const eventClick = ({ event }) => {
    dispatch(editEventAction(Number(event.groupId)));
  };

  const fetchEvents = ({ currentStart, currentEnd }) => {
    // Only fetch events once pool.id equals id from querystring
    if (pool.id === Number(id)) {
      dispatch(fetchEventsAction(pool.id, currentStart, currentEnd));
    }
  };

  useEffect(() => {
    const calendarElement = calendarRef.current;

    // create calendar
    const cal = new Cal(calendarElement, {
      ...calendarOptions,
      dateClick: info => dateClick(info),
      eventClick: info => eventClick(info),
      datesRender: ({ view }) => fetchEvents(view),
    });

    // render calendar
    setCalendar(cal);
    cal.render();

    // update the current time twice every minute
    const timeInterval = setInterval(() => {
      setTime(new Date());
    }, 30000);

    return () => {
      clearInterval(timeInterval);
      cal.destroy();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pool.id]);

  // set the current time as a css variable for the 'now indicator'
  useEffect(() => {
    const currentTime = time ? new Date(time) : new Date();
    const calendarElement = calendarRef.current;

    let hours = currentTime.getHours();
    hours = hours < 10 ? `0${hours}` : hours;
    let minutes = currentTime.getMinutes();
    if (minutes < 10 || minutes > 50) {
      calendarElement.classList.add('calendar--overlapping-time');
    } else {
      calendarElement.classList.remove('calendar--overlapping-time');
    }
    minutes = minutes < 10 ? `0${minutes}` : minutes;

    calendarElement.style.setProperty('--time', `'${hours}:${minutes}'`);
  }, [calendarRef, time]);

  // add events to calendar when 'view' changes
  useEffect(() => {
    if (calendar) {
      const eventsArray = [];
      if (events) {
        events.forEach(
          event =>
            event &&
            event.event_occurances.forEach(occurance => {
              const eventObject = {
                id: `${event.id}-${occurance.id}`,
                groupId: event.id,
                title: event.title,
                start: occurance.start_at,
                end: occurance.end_at,
                classNames: [
                  'calendar__event',
                  !event.active ? 'calendar__event--inactive' : '',
                  new Date(occurance.end_at) < Date.now()
                    ? 'calendar__event--past'
                    : '',
                  event.pending_approval ? 'calendar__event--pending' : '',
                ],
              };

              eventsArray.push(eventObject);
            }),
        );
      }

      calendar.addEventSource(eventsArray, 'events-as-source');
    }

    return () => {
      if (calendar) {
        const sources = calendar.getEventSources();
        sources.forEach(source => {
          source.remove();
        });
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [events]);

  return (
    <>
      <div className="calendar" ref={calendarRef} />
    </>
  );
};

export default Calendar;
