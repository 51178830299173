export const Paths = {
  HOME: '/',
  LOGIN: '/login',
  TWOFACTOR: '/two_factor',
  MEDIA: '/media',
  MEDIA_FOLDER: '/media/:folder_id',

  POOLS: '/pools',
  POOLS_DETAIL: '/pools/:id',
  POOLS_SCHEDULES: '/pools/:id/schedules',
  POOLS_TRIGGERS: '/pools/:id/triggers',
  POOLS_ARRANGEMENTS: '/pools/:id/arrangements',
  POOLS_MEDIA: '/pools/:id/media',
  POOLS_MEDIA_FOLDER: '/pools/:id/media/:folder_id',
  POOLS_INFO: '/pools/:id/info',
  POOLS_DEVICES: '/pools/:id/devices',

  API: {
    POOLS: '/pools',
    POOLS_DETAIL: '/pools/:id',
    POOLS_POST: '/pools',
    POOLS_PATCH: '/pools/:id',
    POOLS_DELETE: '/pools/:id',

    DEVICES: '/signs',
    DEVICES_DETAIL: '/signs/:id',
    DEVICE_DELETE: '/signs/:id/unassign',
    DEVICES_PATCH: '/signs/:id',

    TRIGGERS: '/pool_triggers',
    TRIGGERS_BY_POOL: '/pool_triggers/for_pool/:pool_id',
    TOPICS: '/topics/:pool_id',

    EVENTS_BY_POOL: '/scheduled_events/pool/:pool_id',
    EVENTS_DETAIL: '/scheduled_events/:id',
    EVENTS_POST: '/scheduled_events',
    EVENTS_PATCH: '/scheduled_events/:id',
    EVENTS_DELETE: '/scheduled_events/:id',
    EVENTS_ACTIVATE: '/scheduled_events/:id/activate',
    EVENTS_DEACTIVATE: '/scheduled_events/:id/deactivate',
    EVENTS_APPROVE: '/scheduled_events/:id/approve',
    EVENTS_REJECT: '/scheduled_events/:id/reject',

    ARRANGEMENTS: '/arrangements',
    ARRANGEMENTS_POST: '/arrangements',
    ARRANGEMENTS_DETAIL: '/arrangements/:id',
    ARRANGEMENTS_PATCH: '/arrangements/:id',
    ARRANGEMENTS_DELETE: '/arrangements/:id',
    ARRANGEMENTS_BY_POOL: '/arrangements/for_pool/:pool_id',
    ARRANGEMENTS_ACTIVATE: '/arrangements/:id/activate',
    ARRANGEMENTS_DEACTIVATE: '/arrangements/:id/deactivate',
    ARRANGEMENTS_APPROVE: '/arrangements/:id/approve',
    ARRANGEMENTS_REJECT: '/arrangements/:id/reject',

    ARRANGEMENT_MEDIA_ITEMS_POST: '/arrangement_media_items',
    ARRANGEMENT_MEDIA_ITEMS_DELETE: '/arrangement_media_items/:id',
    ARRANGEMENT_MEDIA_ITEMS_CANCEL: '/arrangements/:id/cancel_update',

    TEMPLATE: '/templates/:id',
    TEMPLATES_BY_POOL: '/templates/for_pool/:pool_id',

    MEDIA: '/media',
    MEDIA_DETAIL: '/media/:id',
    MEDIA_POST: '/media',
    MEDIA_DELETE: '/media/:id',
    MEDIA_BY_POOL: '/media/for_pool/:pool_id',
    MEDIA_TYPES: '/media/types',
    MEDIA_BY_FOLDER: '/media_folders/:folder_id',

    MEDIA_FOLDER: '/media_folders',
    MEDIA_FOLDER_PATCH: '/media_folders/:id',
    MEDIA_FOLDER_POST: '/media_folders',
    MEDIA_FOLDER_DELETE: '/media_folders/:id',

    LOCATIONS: '/locations',

    AUTH_USER: '/auth_user',
    TWOFACTOR_SEND: '/two_factor/',
    TWOFACTOR_STATUS: '/two_factor/:id',
    TWOFACTOR_RESEND_EMAIL: '/two_factor?type=login',
  },
};

export const PublicPaths = [Paths.LOGIN, Paths.TWOFACTOR];

export const PrivatePaths = [
  Paths.MEDIA,
  Paths.POOLS,
  Paths.POOLS_DETAIL,
  Paths.POOLS_SCHEDULES,
  Paths.POOLS_TRIGGERS,
  Paths.POOLS_ARRANGEMENTS,
  Paths.POOLS_MEDIA,
  Paths.POOLS_INFO,
];
