import React from 'react';
import DatePicker from 'react-datepicker';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Label from 'components/form/label/Label';
import Icon from 'components/icon/Icon';

import 'react-datepicker/dist/react-datepicker.css';
import './DateTime.scss';

const DateInput = ({
  label,
  value,
  placeholder,
  id,
  name,
  onChange,
  error,
  isChanged,
  disabled,
}) => {
  return (
    <Label idFor={id} label={label}>
      <div className="form__datetime__container">
        <DatePicker
          selected={value}
          name={name}
          placeholder={placeholder}
          onChange={date => onChange(name, date)}
          dateFormat="dd-MM-yyyy"
          className={classNames('form__datetime', {
            'form__datetime--changed': isChanged,
            'form__datetime--disabled': disabled,
          })}
          disabled={disabled}
          minDate={new Date()}
        />
        <Icon
          name="calendar"
          className={classNames('form__datetime__icon', {
            'form__datetime__icon--disabled': disabled,
          })}
        />
        {error && <span className="form__container-error" />}
      </div>
    </Label>
  );
};

DateInput.propTypes = {
  label: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  value: PropTypes.any,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  isChanged: PropTypes.bool,
  disabled: PropTypes.bool,
};

DateInput.defaultProps = {
  label: '',
  value: new Date(),
  placeholder: '',
  name: '',
  onChange: () => {},
  error: false,
  isChanged: false,
  disabled: false,
};

export default DateInput;
