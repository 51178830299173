import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { reverse } from 'named-urls';

import { Paths } from 'constants/Routes';
import fetchData from 'store/fetchData';

import './ArrangementLayout.scss';

const ArrangementLayout = ({
  templateId,
  gridGap,
  active,
  bottomMargin,
  showNumbers,
}) => {
  const dispatch = useDispatch();
  const [template, setTemplate] = useState(null);
  const borderWidth = 20;

  useEffect(() => {
    const promise = dispatch(
      fetchData(reverse(Paths.API.TEMPLATE, { id: templateId })),
    );

    promise
      .then(json => json.json())
      .then(result => {
        setTemplate(result);
      });
  }, [templateId]);

  return (
    template && (
      <svg
        viewBox={`0 0 ${template.width -
          gridGap +
          borderWidth} ${template.height - gridGap + borderWidth}`}
        className={classNames('arrangement-layout', {
          'arrangement-layout--active': active,
          'arrangement-layout--bottom-margin': bottomMargin,
        })}
      >
        {template.areas &&
          template.areas.map(area => {
            return (
              area.height > 0 &&
              area.width > 0 && (
                <React.Fragment
                  key={`fragment-${area.x}x${area.y}-${area.width}x${area.height}-${area.area_number}`}
                >
                  <rect
                    className={
                      area.name === 'Background'
                        ? 'arrangement-layout--background'
                        : 'arrangement-layout--rect'
                    }
                    key={`${area.x}x${area.y}-${area.width}x${area.height}-${area.area_number}`}
                    height={area.height - gridGap}
                    width={area.width - gridGap}
                    rx={gridGap === 0 ? 0 : 50}
                    x={area.x + borderWidth / 2}
                    y={area.y + borderWidth / 2}
                  />
                  {showNumbers && area.name !== 'Background' && (
                    <svg
                      height={area.height - gridGap}
                      width={area.width - gridGap}
                      x={area.x + borderWidth / 2}
                      y={area.y + borderWidth / 2}
                    >
                      <text
                        x="50%"
                        y="50%"
                        className="arrangement-layout__text"
                        fontSize={template.height / 8}
                        fill="currentColor"
                        alignmentBaseline="middle"
                        textAnchor="middle"
                      >
                        {area.area_number}
                      </text>
                    </svg>
                  )}
                </React.Fragment>
              )
            );
          })}
      </svg>
    )
  );
};

ArrangementLayout.propTypes = {
  templateId: PropTypes.number.isRequired,
  gridGap: PropTypes.number,
  active: PropTypes.bool,
  bottomMargin: PropTypes.bool,
  showNumbers: PropTypes.bool,
};

ArrangementLayout.defaultProps = {
  gridGap: 50,
  active: false,
  bottomMargin: false,
  showNumbers: false,
};

export default ArrangementLayout;
