import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, matchPath, withRouter } from 'react-router-dom';
import classNames from 'classnames';

import { Paths } from 'constants/Routes';
import useAuthLogout from 'hooks/useAuthLogout';

import { hasUserRights } from 'store/auth/hasUserRights';
import UserRights from 'constants/UserRights';
import Logo from 'components/logo/Logo';
import './Header.scss';

const Header = props => {
  const dispatch = useDispatch();
  const [logoutAction] = useAuthLogout();
  const { location } = props;
  const hasMediaViewRights = dispatch(hasUserRights(UserRights.MEDIA_VIEW));
  const { selectable } = useSelector(state => state.media);
  const routes = [
    { Key: 'groups', Path: Paths.POOLS, Name: 'Groups', ViewRights: true },
    {
      Key: 'media',
      Path: Paths.MEDIA,
      Name: 'Media',
      ViewRights: hasMediaViewRights,
    },
  ].map(route => {
    return {
      ...route,
      Active: !!matchPath(location.pathname, route.Path),
    };
  });

  return (
    <header className="page-header">
      <nav className="page-header__inner">
        {selectable ? (
          <span className="page-header__logo page-header__logo--disabled">
            <Logo brandMark />
          </span>
        ) : (
          <Link to={Paths.HOME} className="page-header__logo">
            <Logo brandMark />
          </Link>
        )}

        <div className="page-header__links">
          {routes.map(
            route =>
              route.ViewRights && (
                <>
                  {selectable ? (
                    <span
                      className={classNames(
                        'page-header__link page-header__link--disabled',
                        {
                          'page-header__link--active': route.Active,
                        },
                      )}
                    >
                      {route.Name}
                    </span>
                  ) : (
                    <Link
                      key={route.Key}
                      to={route.Path}
                      className={classNames('page-header__link', {
                        'page-header__link--active': route.Active,
                      })}
                    >
                      {route.Name}
                    </Link>
                  )}
                </>
              ),
          )}
        </div>

        <div className="page-header__profile">
          <button type="button" disabled={selectable} onClick={logoutAction}>
            Logout
          </button>
        </div>
      </nav>
    </header>
  );
};

export default withRouter(Header);
