import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { hasUserRights } from 'store/auth/hasUserRights';
import { editMediaFolderAction } from 'store/media/mediaActions';
import { setInfoBarShow } from 'store/info/infoActions';
import { popupActionSet } from 'store/popup/popupActions';
import UserRights from 'constants/UserRights';
import PopupTypes from 'constants/PopupTypes';
import Heading from 'components/typography/heading/Heading';

import './FolderHeader.scss';

const FolderHeader = ({ title, buttonType, editable, dynamicHeaderTitle }) => {
  const dispatch = useDispatch();
  const hasMediaFoldersEditRights = dispatch(
    hasUserRights(UserRights.MEDIA_FOLDERS_EDIT),
  );
  const { folder_info } = useSelector(state => state.media);
  const folderName = folder_info ? folder_info.title : title;

  const editFolderClick = folderInfo => {
    dispatch(editMediaFolderAction(folderInfo.id));
    dispatch(setInfoBarShow(false));
  };

  const newFolderClick = () => {
    const folderId = folder_info ? folder_info.id : null;
    dispatch(popupActionSet(PopupTypes.MEDIA_FOLDER, { parent_id: folderId }));
    dispatch(setInfoBarShow(false));
  };

  return (
    <div className="folder-header">
      <Heading level={2} className="folder-header__folder-name">
        {dynamicHeaderTitle ? folderName : title}
      </Heading>
      {folder_info &&
        hasMediaFoldersEditRights &&
        buttonType === 'editFolder' &&
        editable && (
          <button
            type="button"
            onClick={() => editFolderClick(folder_info)}
            onKeyDown={e => e.key === 13 && editFolderClick(folder_info)}
            className="button__interaction folder-header__folder-button"
          >
            Edit
          </button>
        )}
      {hasMediaFoldersEditRights && buttonType === 'newFolder' && editable && (
        <button
          type="button"
          onClick={newFolderClick}
          onKeyDown={e => e.key === 13 && newFolderClick}
          className="button__interaction folder-header__folder-button"
        >
          Create new folder
        </button>
      )}
    </div>
  );
};

FolderHeader.propTypes = {
  title: PropTypes.string,
  buttonType: PropTypes.oneOf(['newFolder', 'editFolder']).isRequired,
  editable: PropTypes.bool,
  dynamicHeaderTitle: PropTypes.bool,
};

FolderHeader.defaultProps = {
  title: '',
  editable: true,
  dynamicHeaderTitle: false,
};

export default FolderHeader;
