export const RecurringOptions = {
  NO_REPEAT: 'no-repeat',
  DAILY: 'daily',
  WEEKLY: 'weekly',
  MONTHLY_DAY: 'monthly_day',
  MONTHLY_NUMBER: 'monthly_number',
};

export const MonthlyIntervalOptions = {
  FIRST: 1,
  SECOND: 2,
  THIRD: 3,
  FOURTH: 4,
  LAST: -1,
};
