import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Container from 'components/container/Container';
import Folders from 'components/media/folders/Folders';
import Files from 'components/media/files/Files';
import MediaUpload from 'components/media/upload/Upload';
import Breadcrumbs from 'components/breadcrumbs/Breadcrumbs';
import PoolButton from 'components/pool/button/PoolButton';
import PopupTypes from 'constants/PopupTypes';
import Heading from 'components/typography/heading/Heading';
import { hasUserRights } from 'store/auth/hasUserRights';
import UserRights from 'constants/UserRights';
import Button from 'components/button/Button';
import DeckEmpty from 'components/deck/DeckEmpty';
import FolderHeader from 'components/media/folders/folderHeader/folderHeader';
import MediaFilter from 'components/media/filter/MediaFilter';

import './Overview.scss';

const MediaOverview = () => {
  const dispatch = useDispatch();
  const { folder_info } = useSelector(state => state.media);

  const hasMediaViewRights = dispatch(hasUserRights(UserRights.MEDIA_VIEW));
  const hasMediaEditRights = dispatch(hasUserRights(UserRights.MEDIA_EDIT));

  const noMediaViewRightsSubTitle = (
    <p>
      In case you should have view rights please contact your supervisor or send
      an email to{' '}
      <Button
        hasShadow={false}
        scheme="link"
        link="mailto:support@gamingsupport.com"
        text="support@gamingsupport.com"
        size="inline"
      />
    </p>
  );

  return (
    <>
      {hasMediaViewRights ? (
        <div className="media-page">
          <div className="media-page__filter">
            <Container>
              <Heading level={1}>Media</Heading>
              <MediaFilter />
            </Container>
          </div>

          <Container className="media-page__content">
            <Breadcrumbs />
            <FolderHeader
              title="All media"
              buttonType="editFolder"
              dynamicHeaderTitle
            />

            {hasMediaEditRights && (
              <MediaUpload folderId={folder_info && folder_info.id} />
            )}

            <FolderHeader title="Folders" buttonType="newFolder" />
            <Folders editable />

            <Files />

            {hasMediaEditRights && (
              <PoolButton text="Add media" popupType={PopupTypes.MEDIA} />
            )}
          </Container>
        </div>
      ) : (
        <DeckEmpty
          title="No view rights for media"
          subtitle={noMediaViewRightsSubTitle}
        />
      )}
    </>
  );
};

export default MediaOverview;
