import React from 'react';
import classNames from 'classnames';

import './Card.scss';

const Card = ({ className, zoomable, children }) => {
  return (
    <div
      className={classNames('card', className, {
        'card--zoom': zoomable,
      })}
    >
      {children}
    </div>
  );
};

export default Card;
