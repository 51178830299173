import React from 'react';

import './Label.scss';

const Label = ({ label, idFor, className, children }) => {
  return (
    <label htmlFor={idFor} className={className}>
      {label !== '' && <span className="form__label">{label}</span>}
      {children}
    </label>
  );
};

export default Label;
