import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import PopupTypes from 'constants/PopupTypes';
import PoolButton from 'components/pool/button/PoolButton';
import Deck from 'components/deck/Deck';
import TriggerCard from 'components/trigger/card/TriggerCard';
import { hasUserRights } from 'store/auth/hasUserRights';
import UserRights from 'constants/UserRights';
import DeckEmpty from 'components/deck/DeckEmpty';
import Button from 'components/button/Button';
import { fetchTriggersAction } from 'store/triggers/triggersActions';

const PoolsDetail = () => {
  const dispatch = useDispatch();

  const pool = useSelector(state => state.pool);
  const { triggers } = useSelector(state => state.triggers);

  const hasTriggersEditRights = dispatch(
    hasUserRights(UserRights.TRIGGERS_EDIT),
  );
  const hasTriggersViewRights = dispatch(
    hasUserRights(UserRights.TRIGGERS_VIEW),
  );

  useEffect(() => {
    if (pool && hasTriggersViewRights) {
      dispatch(fetchTriggersAction(pool.id));
    }
  }, [hasTriggersViewRights, pool, dispatch]);

  const noTriggersViewRightsSubTitle = (
    <p>
      In case you should have view rights please contact your supervisor or send
      an email to{' '}
      <Button
        hasShadow={false}
        scheme="link"
        link="mailto:support@gamingsupport.com"
        text="support@gamingsupport.com"
        size="inline"
      />
    </p>
  );

  return (
    <>
      {hasTriggersViewRights ? (
        <>
          {triggers.length > 0 && (
            <Deck>
              {triggers.map(trigger => {
                return (
                  <TriggerCard
                    key={trigger.id}
                    id={trigger.id}
                    name={trigger.name}
                    when={trigger.trigger_topic_name}
                    show={trigger.arrangement_name}
                    until={
                      trigger.duration_minutes
                        ? `For ${trigger.duration_minutes} minute${
                            trigger.duration_minutes === 1 ? '' : 's'
                          }`
                        : ''
                    }
                    state={trigger.state}
                  />
                );
              })}
            </Deck>
          )}
          {triggers.length === 0 && (
            <DeckEmpty
              title="No triggers added"
              subtitle="Click on the blue + to start adding triggers to the group"
            />
          )}
          {hasTriggersEditRights && (
            <PoolButton text="Add trigger" popupType={PopupTypes.TRIGGER} />
          )}
        </>
      ) : (
        <DeckEmpty
          title="No view rights for triggers"
          subtitle={noTriggersViewRightsSubTitle}
        />
      )}
    </>
  );
};

export default PoolsDetail;
