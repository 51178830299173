export const PopupActionTypes = {
  POPUP_SET: '@@popup/set',
  POPUP_UPDATE: '@@popup/update',
  POPUP_CLEAR: '@@popup/clear',
};

export const popupActionSet = (type, data) => ({
  type: PopupActionTypes.POPUP_SET,
  payload: {
    type,
    data,
  },
});

export const popupActionUpdate = data => ({
  type: PopupActionTypes.POPUP_UPDATE,
  payload: data,
});

export const popupActionClear = () => ({
  type: PopupActionTypes.POPUP_CLEAR,
});
