import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { popupActionClear } from 'store/popup/popupActions';
import { setInfoBarShow } from 'store/info/infoActions';
import PopupTypes from 'constants/PopupTypes';

import Arrangement from 'components/popup/arrangement/Arrangement';
import Event from 'components/popup/event/Event';
import Trigger from 'components/popup/trigger/Trigger';
import Media from 'components/popup/media/Media';
import MediaPreview from 'components/popup/media-preview/MediaPreview';
import Info from 'components/popup/info/Info';
import Group from 'components/popup/group/Group';
import Device from 'components/popup/device/Device';
import DeviceDetail from 'components/popup/device/DeviceDetail';
import { cancelUpdateMediaItems } from 'store/media/mediaActions';
import MediaFolder from 'components/popup/media-folder/MediaFolder';
import { deleteArrangementAction } from 'store/arrangements/arrangementsActions';

import './Popup.scss';

const Popup = ({ position }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const type = useSelector(state => state.popup.type);
  const data = useSelector(state => state.popup.data);
  const infoBar = useSelector(state => state.info.bar);

  const [content, setContent] = useState(null);
  const [backgroundAction, setBackgroundAction] = useState(null);
  const { state } = history.location;

  const closePopup = () => {
    if (type === PopupTypes.ARRANGEMENT) {
      const arrangementId = data.id;

      if (state?.newArrangement) {
        dispatch(deleteArrangementAction(arrangementId, false, history));
      } else {
        history.push({
          state: {
            fromArrangement: undefined,
          },
        });
      }
    }
    dispatch(popupActionClear());

    // hide infoBar in case it is displayed
    if (infoBar.show) {
      dispatch(setInfoBarShow(false));
    }

    if (backgroundAction) {
      dispatch(backgroundAction);
    }
  };

  useEffect(() => {
    switch (type) {
      case PopupTypes.ARRANGEMENT:
        setContent(<Arrangement {...data} />);

        if (data.id) {
          setBackgroundAction(() => cancelUpdateMediaItems(data.id));
        }
        break;
      case PopupTypes.EVENT:
        setContent(<Event {...data} />);
        break;
      case PopupTypes.TRIGGER:
        setContent(<Trigger {...data} />);
        break;
      case PopupTypes.MEDIA:
        setContent(<Media {...data} />);
        break;
      case PopupTypes.MEDIA_PREVIEW:
        setContent(<MediaPreview {...data} />);
        break;
      case PopupTypes.INFO:
        setContent(<Info>{data}</Info>);
        break;
      case PopupTypes.DEVICE:
        setContent(<Device>{data}</Device>);
        break;
      case PopupTypes.DEVICE_DETAIL:
        setContent(<DeviceDetail {...data} />);
        break;
      case PopupTypes.GROUP:
        setContent(<Group {...data} />);
        break;
      case PopupTypes.MEDIA_FOLDER:
        setContent(<MediaFolder {...data} />);
        break;
      default:
        setContent(null);
    }

    if (type) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, data]);

  return (
    <div className="popup">
      <CSSTransition
        in={!!content}
        timeout={{
          enter: 300,
          exit: 0,
        }}
        classNames="fade"
        unmountOnExit
      >
        <>
          <div
            className="popup__background"
            role="presentation"
            onClick={() => closePopup()}
          />
          <div
            className={classNames('popup__content', {
              [`popup__content--position-${position}`]: position,
            })}
          >
            <div className="popup__wrapper">{content}</div>
          </div>
        </>
      </CSSTransition>
    </div>
  );
};

Popup.propTypes = {
  position: PropTypes.oneOf(['top', 'center']),
};

Popup.defaultProps = {
  position: 'top',
};

export default Popup;
