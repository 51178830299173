import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';

import Heading from 'components/typography/heading/Heading';
import Input from 'components/form/input/Input';
import Button from 'components/button/Button';
import MediaTypeDropdown from 'components/media/type-dropdown/MediaTypeDropdown';
import { filterMediaAction } from 'store/media/mediaActions';

import './MediaFilter.scss';

const MediaFilter = () => {
  const dispatch = useDispatch();
  const initialValues = {
    name: '',
    filetype: 0,
  };

  const filterMedia = useCallback(
    values => {
      console.log(values);
      dispatch(filterMediaAction(values.name, values.filetype));
    },
    [dispatch],
  );

  const { values, handleSubmit, handleChange } = useFormik({
    initialValues,
    onChange: filterMedia,
    onSubmit: filterMedia,
  });

  return (
    <form
      onSubmit={handleSubmit}
      onChange={handleChange}
      className="media-filter"
    >
      <div className="media-filter__bar">
        <div className="media-filter__input">
          <Input
            label={
              <Heading level={3} noMargin>
                Search
              </Heading>
            }
            name="name"
            value={values.name}
            scheme="white"
            placeholder="What are you looking for?"
          />

          <MediaTypeDropdown
            label="File type"
            value={values.filetype}
            width={220}
          />
        </div>

        <div className="media-filter__options">
          <Button text="Reset" size="medium" scheme="link" hasShadow={false} />
          <Button tag="button" type="submit" text="Search" size="medium" />
        </div>
      </div>
    </form>
  );
};

export default MediaFilter;
