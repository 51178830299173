import React from 'react';
import classNames from 'classnames';

import './Container.scss';

const Container = ({ children, className, ...props }) => {
  return (
    <div className={classNames('container', className)} {...props}>
      {children}
    </div>
  );
};

export default Container;
