import React from 'react';

import Card from 'components/card/Card';
import Button from 'components/button/Button';
import Icon from 'components/icon/Icon';

import './TriggerCard.scss';

const TriggerCard = ({ id, name, state, when, show, until }) => {
  const cardClick = () => {
    console.log(id);
  };

  return (
    <button
      type="button"
      tabIndex="0"
      onClick={cardClick}
      onKeyDown={e => e.key === 13 && cardClick}
      className="button__interaction"
    >
      <Card className="trigger-card" zoomable>
        <h3 className="trigger-card__title">{name}</h3>

        <div className="trigger-card__list">
          <div className="trigger-card__list-item">
            <Icon name="triangle" />
            {when}
          </div>
          <div className="trigger-card__list-item">
            <Icon name="circle" />
            {show}
          </div>
          <div className="trigger-card__list-item">
            <Icon name="rectangle" />
            {until}
          </div>
        </div>

        <div className="card__state">{state}</div>

        <Button
          tag="span"
          text={<Icon name="arrow-right" />}
          size="small"
          hasShadow={false}
          className="card__button"
          scheme="card"
        />
      </Card>
    </button>
  );
};

export default TriggerCard;
