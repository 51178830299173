import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { reverse } from 'named-urls';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';

import FormBlock from 'components/form/block/FormBlock';
import fetchData from 'store/fetchData';
import { Paths } from 'constants/Routes';
import Button from 'components/button/Button';
import Icon from 'components/icon/Icon';
import { popupActionClear } from 'store/popup/popupActions';
import { upsertArrangementAction } from 'store/arrangements/arrangementsActions';

import './ArrangementMedia.scss';
import { hasUserRights } from 'store/auth/hasUserRights';
import UserRights from 'constants/UserRights';

const ArrangementMedia = ({
  templateId,
  arrangementId,
  media,
  formValues,
  pending_approval,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const pool = useSelector(state => state.pool);
  const authToken = useSelector(state => state.auth.auth_token);

  const [spots, setSpots] = useState([]);
  const [dimension, setDimension] = useState({ height: 0, width: 0 });
  const [loading, setLoading] = useState(false);
  const [mediaItems, setMediaItems] = useState(media);
  const hasArrangementEditRights = dispatch(
    hasUserRights(UserRights.ARRANGEMENTS_EDIT),
  );

  useEffect(() => {
    setMediaItems(media);
  }, [media]);

  useEffect(() => {
    if (templateId) {
      setLoading(true);

      const promise = dispatch(
        fetchData(reverse(Paths.API.TEMPLATE, { id: templateId })),
      );

      promise
        .then(json => json.json())
        .then(({ areas, height, width }) => {
          setDimension({ height, width });
          setSpots(areas);
        })
        .catch(() => {
          setSpots([]);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [templateId]);

  const openMediaLibrary = spotId => {
    if (spotId >= 0) {
      const location = (arrangementId, newArrangement) => {
        return {
          pathname: reverse(Paths.POOLS_MEDIA, { id: pool.id }),
          state: {
            fromArrangement: arrangementId,
            fromSpot: spotId,
            fromArrangementFormFormValues: formValues,
            newArrangement:
              newArrangement || history?.location?.state?.newArrangement,
          },
        };
      };

      if (arrangementId === undefined) {
        const promise = dispatch(
          upsertArrangementAction(formValues, arrangementId, pool),
        );

        promise
          .then(json => json.json())
          .then(result => {
            const arrId = result.arrangement.id;

            dispatch(popupActionClear());
            history.push(location(arrId, true));
          });
      } else {
        dispatch(popupActionClear());
        history.push(location(arrangementId, undefined));
      }
    }
  };

  const deleteMediaItem = (id, spot) => {
    dispatch(
      fetchData(reverse(Paths.API.ARRANGEMENT_MEDIA_ITEMS_DELETE, { id }), {
        method: 'DELETE',
        headers: {
          Authorization: authToken,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }),
    );

    setMediaItems(prevItems => {
      const newItems = {
        ...prevItems,
        [spot]: prevItems[spot].filter(i => i.id !== id),
      };

      return newItems;
    });
  };

  /**
   * Only allow adding media items when spot has at least one item and spot contains an image item.
   * @param {Array<Object>} items - Media items
   * @param {Object} items.item - Media item
   * @param {Boolean} items.item.active
   * @param {String} items.item.created_at
   * @param {Number} items.item.file_size
   * @param {Boolean} items.item.pending_removal
   * @param {String} items.item.filename
   * @param {String} items.item.filetype
   * @param {Number} items.item.id
   * @param {String} items.item.media_file_url
   * @param {String} items.item.name
   * @param {String} items.item.updated_at
   * @param {Number} items.item.used_count
   * @param {Object} spot
   * @param {Number} spot.area_number
   * @param {Number} spot.height
   * @param {String} spot.name
   * @param {Number} spot.width
   * @param {Number} spot.x
   * @param {Number} spot.y
   * @returns {boolean}
   */

  const showSpotAddMediaItemsButton = (items, spot) => {
    if (!items || Object.values(items).includes(null)) {
      return true;
    }

    const spotHasNoItems = items && items[spot.area_number] === undefined;
    const spotHasItems = items && items[spot.area_number];
    const spotContainsNoImageItemsInPendingRemovalStatus =
      spotHasItems &&
      items[spot.area_number].filter(
        item => item.filetype === 'image' && !item.pending_removal,
      ).length === 0;

    const spotContainsNoPendingApprovalImageItem =
      spotHasItems &&
      items[spot.area_number].filter(item => item.pending_approval).length ===
        0;

    const spotContainsPendingRemovalImageItems =
      spotHasItems &&
      items[spot.area_number].filter(item => item.pending_removal).length >= 1;

    return (
      spotHasNoItems ||
      spotContainsNoImageItemsInPendingRemovalStatus ||
      (spotContainsPendingRemovalImageItems &&
        spotContainsNoPendingApprovalImageItem)
    );
  };

  return (
    <>
      {templateId && (
        <fieldset className="arrangement-media">
          <legend>Media</legend>

          {loading && <div>Loading media areas</div>}

          {spots &&
            spots.length > 0 &&
            spots.map(spot => (
              <FormBlock
                key={`${spot.name}_${spot.area_number}`}
                className="arrangement-media__list"
              >
                <div className="arrangement-media__block">
                  <h3 className="arrangement-media__title">
                    {`${spot.area_number}. `}
                    {spot.name} ({spot.width || dimension.width}px-
                    {spot.height || dimension.height}px)
                  </h3>
                  {mediaItems && mediaItems[spot.area_number] && (
                    <div className="arrangement-media__order">
                      {mediaItems[spot.area_number].map(mediaItem => (
                        <div
                          key={mediaItem.id}
                          className={classNames(
                            'arrangement-media__order-item',
                            {
                              'arrangement-media__order-item--added': !mediaItem.active,
                              'arrangement-media__order-item--disabled':
                                (pending_approval &&
                                  !mediaItem.pending_approval &&
                                  !mediaItem.pending_removal) ||
                                !hasArrangementEditRights,
                              'arrangement-media__order-item--changed':
                                pending_approval && mediaItem.pending_approval,
                              'arrangement-media__order-item--deleted':
                                mediaItem.pending_removal,
                            },
                          )}
                        >
                          {mediaItem.name}

                          {pending_approval || mediaItem.pending_removal ? (
                            <div className="arrangement-media__delete">
                              <Icon name="cross" size="small" />
                            </div>
                          ) : (
                            <Button
                              text={<Icon name="cross" size="small" />}
                              hasShadow={false}
                              scheme="link"
                              size="inline"
                              className="arrangement-media__delete"
                              onClick={() =>
                                deleteMediaItem(mediaItem.id, spot.area_number)
                              }
                              disabled={!hasArrangementEditRights}
                            />
                          )}
                        </div>
                      ))}
                    </div>
                  )}
                  {showSpotAddMediaItemsButton(mediaItems, spot) && (
                    <div className="form__upload form__upload--small">
                      <div className="form__upload__text">
                        {false && <>Drag and drop your media here or </>}
                        <Button
                          tag="span"
                          role="button"
                          scheme="link"
                          hasShadow={false}
                          size="medium"
                          handler={() =>
                            !pending_approval
                              ? openMediaLibrary(spot.area_number)
                              : null
                          }
                          text="browse the media library"
                          disabled={
                            pending_approval || !hasArrangementEditRights
                          }
                        />
                      </div>
                    </div>
                  )}
                </div>
              </FormBlock>
            ))}
        </fieldset>
      )}
    </>
  );
};

export default ArrangementMedia;
