import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { fetchMediaAction } from 'store/media/mediaActions';

import Deck from 'components/deck/Deck';
import MediaCard from 'components/media/card/MediaCard';
import Heading from 'components/typography/heading/Heading';
import PropTypes from 'prop-types';
import { Paths } from 'constants/Routes';

const Files = ({ selectable, selectedMedia, setSelected }) => {
  const dispatch = useDispatch();

  const { media } = useSelector(state => state.media);
  const poolId = useSelector(state => state.pool?.id);

  const { folder_id } = useParams();

  useEffect(() => {
    const pathName = window.location.pathname;

    if (pathName.startsWith(Paths.MEDIA)) {
      dispatch(fetchMediaAction({ folder_id }));
    } else {
      dispatch(fetchMediaAction({ poolId, folder_id }));
    }
  }, [folder_id]);

  return (
    <>
      <Heading level={2}>Media items</Heading>
      <Deck>
        {media &&
          media.map(item => (
            <MediaCard
              id={item.id}
              key={item.id}
              title={item.name}
              type={item.filetype || item.source_type}
              image={item.media_file_url}
              selectable={selectable}
              selected={selectedMedia.indexOf(item.id) >= 0}
              setSelected={setSelected}
            />
          ))}
        {!media.length && (
          <p className="folders__empty">No media in folder found</p>
        )}
      </Deck>
    </>
  );
};

Files.propTypes = {
  selectable: PropTypes.bool,
  selectedMedia: PropTypes.arrayOf(PropTypes.any),
  setSelected: PropTypes.func,
};

Files.defaultProps = {
  selectable: false,
  selectedMedia: [],
  setSelected: null,
};

export default Files;
