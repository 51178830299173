import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import './Button.scss';

const Button = ({
  tag,
  link,
  text,
  hoverText,
  hoverTextInverse,
  size,
  hasShadow,
  scheme,
  className,
  handler,
  block,
  color,
  disabled,
  ...props
}) => {
  return React.createElement(
    tag,
    {
      className: classNames('button', className, {
        [`button--${size}`]: size,
        [`button--scheme-${scheme}`]: scheme,
        'button--no-shadow': !hasShadow,
        'button--text-inverse': hoverTextInverse,
        'button--block': block,
        [`button--color-${color}`]: color,
        'button--scheme-link--disabled': scheme === 'link' && disabled,
      }),
      disabled,
      href: link || null,
      onClick: handler || null,
      onKeyDown: e => (e.key === 13 && handler) || null,
      type: (!!handler || !link) && 'button',
      ...props,
    },
    <>
      <span className="button__text">{text}</span>
      {hoverText && (
        <>
          <span className="button__hover-spacing" />
          <span className="button__hover-text">{hoverText}</span>
        </>
      )}
    </>,
  );
};

Button.propTypes = {
  tag: PropTypes.string,
  link: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  text: PropTypes.any,
  hoverText: PropTypes.string,
  hoverTextInverse: PropTypes.bool,
  size: PropTypes.oneOf(['', 'small', 'large', 'medium', 'inline']),
  hasShadow: PropTypes.bool,
  scheme: PropTypes.oneOf(['', 'card', 'link']),
  handler: PropTypes.func,
  block: PropTypes.bool,
  color: PropTypes.oneOf(['', 'red', 'green']),
  disabled: PropTypes.bool,
};

Button.defaultProps = {
  tag: 'a',
  link: '',
  text: '',
  hoverText: '',
  hoverTextInverse: false,
  size: '',
  hasShadow: true,
  scheme: '',
  handler: null,
  block: false,
  color: '',
  disabled: false,
};

export default Button;
