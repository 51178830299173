import { ArrangementsActionTypes } from './arrangementsActions';

export const arrangementsInitialState = {
  request: false,
  arrangements: [],
};

const arrangementsReducer = (state = arrangementsInitialState, action) => {
  let updateArrangements;

  switch (action.type) {
    case ArrangementsActionTypes.ARRANGEMENT_REQUESTED:
      return { ...state, request: true };
    case ArrangementsActionTypes.ARRANGEMENT_SUCCEEDED:
      return { ...action.payload, request: false };
    case ArrangementsActionTypes.ARRANGEMENT_FAILED:
      return { ...arrangementsInitialState, request: false };
    case ArrangementsActionTypes.ARRANGEMENT_ADDED:
      return {
        ...state,
        arrangements: [action.payload, ...state.arrangements],
      };

    case ArrangementsActionTypes.ARRANGEMENT_DELETED:
      return {
        ...state,
        arrangements: state.arrangements.filter(
          item => item.id !== action.payload,
        ),
      };

    case ArrangementsActionTypes.ARRANGEMENT_ACTIVATED:
      updateArrangements = state.arrangements.map(item => {
        if (item.id === action.payload.id) {
          return {
            ...item,
            active: true,
          };
        }
        return item;
      });

      return {
        ...state,
        arrangements: updateArrangements,
      };

    case ArrangementsActionTypes.ARRANGEMENT_DEACTIVATED:
      updateArrangements = state.arrangements.map(item => {
        if (item.id === action.payload.id) {
          return {
            ...item,
            active: false,
          };
        }
        return item;
      });

      return {
        ...state,
        arrangements: updateArrangements,
      };

    case ArrangementsActionTypes.ARRANGEMENT_CLEARED:
      return arrangementsInitialState;

    case ArrangementsActionTypes.ARRANGEMENT_APPROVED:
      return {
        ...state,
        arrangements: state.arrangements.map(arrangement => {
          if (arrangement.id === action.payload.id) {
            return {
              ...arrangement,
              ...action.payload,
              pending_approval: action.payload.approval_state !== 'approved',
            };
          }

          return arrangement;
        }),
      };

    default:
      return state;
  }
};

export default arrangementsReducer;
