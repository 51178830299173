const useCalendarDates = (
  startDate = null,
  endDate = null,
  roundTime = true,
) => {
  let start = startDate || new Date();
  let end = endDate;

  const getRoundedTime = date => {
    const newDate = new Date(date);
    const minutes = newDate.getMinutes();

    if (minutes > 30) {
      // round to next hour
      newDate.setMinutes(0);
      newDate.setHours(newDate.getHours() + 1);
    } else if (minutes > 0) {
      // round to next half hour
      newDate.setMinutes(30);
    }

    return newDate;
  };

  if (!endDate) {
    end = new Date(start);
    end.setHours(end.getHours() + 1);
  }

  if (roundTime) {
    start = getRoundedTime(start);
    end = getRoundedTime(end);
  }

  return [new Date(start), new Date(end)];
};

export default useCalendarDates;
