import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import './Heading.scss';

const Heading = ({ children, level, stylingLevel, noMargin, className }) => {
  return React.createElement(
    `h${level}`,
    {
      className: classNames(
        {
          [`heading--h${stylingLevel}`]: stylingLevel,
          'heading--no-margin': noMargin,
        },
        className,
      ),
    },
    children,
  );
};

Heading.propTypes = {
  level: PropTypes.oneOf([1, 2, 3, 4, 5, 6]),
  stylingLevel: PropTypes.oneOf([1, 2, 3, 4, 5, 6]),
  className: PropTypes.string,
  noMargin: PropTypes.bool,
  children: PropTypes.node,
};

Heading.defaultProps = {
  level: 1,
  stylingLevel: null,
  className: null,
  noMargin: false,
  children: [],
};

export default Heading;
