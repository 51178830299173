/* eslint-disable no-console */
import Storage from 'constants/Storage';

export const loadState = () => {
  try {
    const serializedState = localStorage.getItem(Storage.STATE);
    return serializedState ? JSON.parse(serializedState) : undefined;
  } catch {
    return undefined;
  }
};

export const saveState = state => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem(Storage.STATE, serializedState);
  } catch {
    console.error('Failed to save state to localstorage');
    console.info(state);
  }
};
