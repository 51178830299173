import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import './Status.scss';

const Status = ({ isActive, needsApproval }) => {
  return (
    <div className="status">
      <span
        className={classNames('status__light', {
          'status__light--active': isActive && !needsApproval,
          'status__light--pending': needsApproval,
        })}
      />
      <span>
        {needsApproval && 'Needs approval'}
        {!needsApproval && isActive && 'Active'}
        {!needsApproval && !isActive && 'Inactive'}
      </span>
    </div>
  );
};

Status.propTypes = {
  isActive: PropTypes.bool,
  needsApproval: PropTypes.bool,
};

Status.defaultProps = {
  isActive: true,
  needsApproval: false,
};

export default Status;
