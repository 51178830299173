import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Formik } from 'formik';
import { CSSTransition } from 'react-transition-group';
import * as Yup from 'yup';

import fetchData from 'store/fetchData';
import { Paths } from 'constants/Routes';
import FormBlock from 'components/form/block/FormBlock';
import Input from 'components/form/input/Input';
import Dropdown from 'components/form/dropdown/Dropdown';
import ArrangementDropdown from 'components/arrangement/dropdown/ArrangementDropdown';
import Button from 'components/button/Button';
import { popupActionClear } from 'store/popup/popupActions';
import Icon from 'components/icon/Icon';
import TriggerDropdown from 'components/trigger/dropdown/TriggerDropdown';
import { triggerAdded } from 'store/triggers/triggersActions';
import { setInfoBarMessage } from 'store/info/infoActions';

const Trigger = () => {
  const dispatch = useDispatch();
  const pool = useSelector(state => state.pool);

  const initialForm = {
    name: '',
    trigger: 0,
    show: 0,
    until: 'timer',
    untilTimer: '',
    untilTrigger: 0,
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is a required field'),
    trigger: Yup.number()
      .min(1, 'Trigger is a required field')
      .required('Trigger is a required field'),
    show: Yup.number()
      .min(1, 'Show is a required field')
      .required('Show is a required field'),
    until: Yup.string().required('Until is a required field'),
    untilTimer: Yup.number().when('until', {
      is: until => until === 'timer',
      then: Yup.number()
        .min(1, 'Timer is a required field')
        .required('Timer is a required field'),
    }),
  });

  const untilOptions = [
    { label: 'A timer is expired', value: 'timer' },
    // { label: 'A trigger is triggered', value: 'trigger' },   // Until trigger is not implemented in backend yet so disabled the option here
  ];

  const onSubmit = useCallback(
    values => {
      const triggerData = new FormData();
      triggerData.append('name', values.name);
      triggerData.append('pool_id', pool.id);
      triggerData.append('topic_id', values.trigger);
      triggerData.append('arrangement_id', values.show);
      triggerData.append('duration_minutes', values.untilTimer);

      const promise = dispatch(
        fetchData(
          Paths.API.TRIGGERS,
          {
            method: 'POST',
            body: triggerData,
          },
          false,
        ),
      );
      promise
        .then(json => json.json())
        .then(({ pool_trigger, message }) => {
          dispatch(setInfoBarMessage(message));
          dispatch(triggerAdded(pool_trigger));
          dispatch(popupActionClear());
        });
    },
    [dispatch, pool],
  );

  return (
    <Formik
      initialValues={initialForm}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({
        values,
        handleChange,
        setFieldValue,
        handleSubmit,
        errors,
        touched,
      }) => (
        <form onSubmit={handleSubmit}>
          <fieldset>
            <FormBlock>
              <Input
                type="text"
                name="name"
                id="name"
                placeholder="New trigger"
                value={values.name}
                onChange={handleChange}
                fontSize="large"
                suffix={<Icon name="edit" />}
                error={touched.name && errors.name}
              />
            </FormBlock>

            <div className="form__information">
              <span>
                {pool.name} - {pool.location.name}
              </span>
            </div>
          </fieldset>

          <fieldset>
            <legend>Trigger rules</legend>

            <FormBlock>
              <TriggerDropdown
                label={
                  <>
                    <Icon name="triangle" />
                    When this is activated
                  </>
                }
                poolId={pool.id}
                name="trigger"
                id="trigger"
                onChange={setFieldValue}
                value={values.trigger}
                error={touched.trigger && errors.trigger}
                width={300}
              />
              <></>
            </FormBlock>

            <FormBlock>
              <ArrangementDropdown
                label={
                  <>
                    <Icon name="circle" />
                    Show this
                  </>
                }
                poolId={pool.id}
                name="show"
                id="show"
                onChange={setFieldValue}
                value={values.show}
                error={touched.show && errors.show}
                width={300}
              />
              <></>
            </FormBlock>

            <FormBlock>
              <Dropdown
                label={
                  <>
                    <Icon name="rectangle" />
                    Until
                  </>
                }
                name="until"
                id="until"
                width={300}
                values={untilOptions}
                value={values.until}
                error={touched.until && errors.until}
                onChange={setFieldValue}
              />

              <CSSTransition
                in={values.until === 'timer'}
                timeout={{
                  enter: 300,
                  exit: 0,
                }}
                classNames="fade"
                unmountOnExit
              >
                <Input
                  type="number"
                  label="Timer"
                  name="untilTimer"
                  id="untilTimer"
                  scheme="white"
                  value={values.untilTimer}
                  placeholder="15"
                  onChange={handleChange}
                  error={touched.untilTimer && errors.untilTimer ? true : null}
                  suffix="minutes"
                  width={150}
                />
              </CSSTransition>
              <CSSTransition
                in={values.until === 'trigger'}
                timeout={{
                  enter: 300,
                  exit: 0,
                }}
                classNames="fade"
                unmountOnExit
              >
                <Dropdown
                  label="Trigger"
                  name="untilTrigger"
                  id="untilTrigger"
                  width={300}
                  onChange={setFieldValue}
                />
              </CSSTransition>
            </FormBlock>
            <FormBlock error={touched.untilTimer && errors.untilTimer} />
          </fieldset>

          <FormBlock hasInlineChildren>
            <Button
              tag="button"
              type="submit"
              size="medium"
              text="Cancel"
              scheme="link"
              hasShadow={false}
              handler={() => {
                dispatch(popupActionClear());
              }}
            />

            <Button
              tag="button"
              type="submit"
              size="medium"
              text="Save and close"
            />
          </FormBlock>
        </form>
      )}
    </Formik>
  );
};

export default Trigger;
