import React from 'react';
import classNames from 'classnames';

import './FormBlock.scss';

const FormBlock = ({
  hasInlineChildren,
  ignoreSplit,
  noSpacing,
  isEnd,
  error,
  children,
}) => {
  const hasMultipleChildren =
    React.Children.count(children) > 1 && !hasInlineChildren;

  return (
    <div
      className={classNames('form__block', {
        'form__block--split': hasMultipleChildren && !ignoreSplit,
        'form__block--split-inline': hasInlineChildren,
        'form__block--inline': ignoreSplit,
        'form__block--no-spacing': noSpacing,
        'form__block--end': isEnd,
        'form__block--error': error,
      })}
    >
      {error && <span>{error}</span>}

      {children}
    </div>
  );
};

export default FormBlock;
