import { PoolsActionTypes } from './poolsActions';

export const poolsInitialState = {
  request: false,
  pools: [],
  error: null,
};

const poolsReducer = (state = poolsInitialState, action) => {
  switch (action.type) {
    case PoolsActionTypes.POOLS_REQUESTED:
      return { ...poolsInitialState, request: true };
    case PoolsActionTypes.POOLS_SUCCEEDED:
      return { ...action.payload, request: false, error: null };
    case PoolsActionTypes.POOLS_FAILED:
      return { ...poolsInitialState, request: false, error: action.payload };
    case PoolsActionTypes.POOLS_CLEARED:
      return poolsInitialState;
    default:
      return state;
  }
};

export default poolsReducer;
