import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Paths } from 'constants/Routes';
import fetchData from 'store/fetchData';
import Dropdown from 'components/form/dropdown/Dropdown';

const DevicesDropdown = ({ poolId, ...props }) => {
  const dispatch = useDispatch();
  const [devices, setDevices] = useState([]);
  const [loadingDevices, setloadingDevices] = useState(false);
  const pool = useSelector(state => state.pool);

  useEffect(() => {
    setloadingDevices(true);

    const promise = dispatch(
      fetchData(`${Paths.API.DEVICES}?pool_id=${pool.id}&unassigned=true`),
    );

    promise
      .then(json => json.json())
      .then(({ signs }) => {
        const devices = signs.map(sign => {
          return {
            label: sign.name,
            value: sign.id,
          };
        });
        setDevices(devices);
      })
      .catch(err => {
        console.error(err);
        setDevices([]);
      })
      .finally(() => {
        setloadingDevices(false);
      });

    return () => {
      setloadingDevices(false);
      setDevices([]);
    };
  }, [dispatch, poolId]);

  return <Dropdown {...props} values={devices} isLoading={loadingDevices} />;
};

export default DevicesDropdown;
