import fetchData from 'store/fetchData';
import { Paths } from 'constants/Routes';
import { reverse } from 'named-urls';

export const TriggerActionTypes = {
  TRIGGERS_REQUESTED: '@@triggers/requested',
  TRIGGERS_SUCCEEDED: '@@triggers/success',
  TRIGGERS_FAILED: '@@triggers/error',
  TRIGGERS_CLEARED: '@@triggers/cleared',
  TRIGGER_DELETED: '@@triggers/deleted',
  TRIGGER_ADDED: '@@triggers/added',
};

export const triggersRequested = () => ({
  type: TriggerActionTypes.TRIGGERS_REQUESTED,
});

export const triggersSucceeded = triggers => ({
  type: TriggerActionTypes.TRIGGERS_SUCCEEDED,
  payload: triggers,
});

export const triggersFailed = () => ({
  type: TriggerActionTypes.TRIGGERS_FAILED,
});

export const triggerAdded = trigger => ({
  type: TriggerActionTypes.TRIGGER_ADDED,
  payload: trigger,
});

export const triggersCleared = () => ({
  type: TriggerActionTypes.TRIGGERS_CLEARED,
});

export const triggerDeleted = id => ({
  type: TriggerActionTypes.TRIGGER_DELETED,
  payload: id,
});

export const fetchTriggersAction = pool_id => async dispatch => {
  dispatch(triggersRequested());

  const promise = dispatch(
    fetchData(reverse(Paths.API.TRIGGERS_BY_POOL, { pool_id })),
  );
  promise
    .then(json => json.json())
    .then(({ pool_triggers }) => {
      if (pool_triggers) {
        dispatch(triggersSucceeded(pool_triggers));
      } else {
        dispatch(triggersFailed());
      }
    })
    .catch(() => {
      dispatch(triggersFailed());
    });
};
