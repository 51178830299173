import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import equal from 'fast-deep-equal';

import FormBlock from 'components/form/block/FormBlock';
import Button from 'components/button/Button';
import { setInfoBarConfirm } from 'store/info/infoActions';
import { popupActionClear } from 'store/popup/popupActions';
import {
  saveMediaFolderAction,
  deleteMediaFolderAction,
} from 'store/media/mediaActions';
import { hasUserRights } from 'store/auth/hasUserRights';
import UserRights from 'constants/UserRights';
import Input from 'components/form/input/Input';
import MediaFolderDropdown from 'components/media/folder-dropdown/MediaFolderDropdown';

const MediaFolder = ({ title, id, parent_id }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const breadCrumbs = useSelector(state => state.media?.breadcrumbs);
  const hasMediaFoldersEditRights = dispatch(
    hasUserRights(UserRights.MEDIA_FOLDERS_EDIT),
  );

  const initialForm = {
    title: title || '',
    media_folder_id: parent_id || undefined,
  };

  const validationSchema = Yup.object().shape({
    title: Yup.string().required('Title is required'),
  });

  const onSubmit = values => {
    if (equal(values, initialForm)) {
      dispatch(popupActionClear());
      return;
    }

    dispatch(saveMediaFolderAction(values, id, history));
    dispatch(popupActionClear());
  };

  return (
    <Formik
      initialValues={initialForm}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ handleChange, values, errors, setFieldValue, handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <FormBlock>
            <Input
              type="text"
              name="title"
              id="title"
              placeholder="New folder"
              value={values.title}
              error={errors.title}
              onChange={handleChange}
              fontSize="large"
            />
          </FormBlock>

          {id && (
            <fieldset>
              <legend className="form__legend--spacing">
                Placement of folder
              </legend>
              <FormBlock>
                <MediaFolderDropdown
                  name="media_folder_id"
                  label="Where should the folder be located"
                  onChange={setFieldValue}
                  folderId={id}
                />
              </FormBlock>
            </fieldset>
          )}

          <FormBlock hasInlineChildren isEnd>
            {hasMediaFoldersEditRights && !!id ? (
              <Button
                tag="button"
                type="button"
                size="medium"
                text="Remove folder"
                scheme="link"
                hasShadow={false}
                handler={() => {
                  dispatch(
                    setInfoBarConfirm(
                      'Are you sure you want to remove this folder?',
                      {
                        text: 'Yes, Delete',
                        handle: () =>
                          dispatch(
                            deleteMediaFolderAction(id, breadCrumbs, history),
                          ),
                      },
                    ),
                  );
                }}
              />
            ) : (
              <Button
                tag="button"
                type="button"
                size="medium"
                text="Cancel"
                scheme="link"
                hasShadow={false}
                handler={() => {
                  dispatch(popupActionClear());
                }}
              />
            )}
            <Button
              tag="button"
              type="submit"
              size="medium"
              text="Save and close"
            />
          </FormBlock>
        </form>
      )}
    </Formik>
  );
};

export default MediaFolder;
