import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { reverse } from 'named-urls';

import PopupTypes from 'constants/PopupTypes';
import { Paths } from 'constants/Routes';
import PoolButton from 'components/pool/button/PoolButton';
import MediaUpload from 'components/media/upload/Upload';
import Button from 'components/button/Button';
import Icon from 'components/icon/Icon';
import { infoBarSet, setInfoBarShow } from 'store/info/infoActions';
import fetchData from 'store/fetchData';
import { hasUserRights } from 'store/auth/hasUserRights';
import UserRights from 'constants/UserRights';
import DeckEmpty from 'components/deck/DeckEmpty';
import Breadcrumbs from 'components/breadcrumbs/Breadcrumbs';
import FolderHeader from 'components/media/folders/folderHeader/folderHeader';
import Folders from 'components/media/folders/Folders';
import Files from 'components/media/files/Files';

import { selectMediaItems } from 'store/media/mediaActions';

const PoolsDetail = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { folder_info, selectable } = useSelector(state => state.media);
  const hasMediaViewRights = dispatch(hasUserRights(UserRights.MEDIA_VIEW));
  const hasMediaEditRights = dispatch(hasUserRights(UserRights.MEDIA_EDIT));
  const pool = useSelector(state => state.pool);
  const [selectedMedia, setSelected] = useState([]);

  const noMediaViewRightsSubTitle = (
    <p>
      In case you should have view rights please contact your supervisor or send
      an email to{' '}
      <Button
        hasShadow={false}
        scheme="link"
        link="mailto:support@gamingsupport.com"
        text="support@gamingsupport.com"
        size="inline"
      />
    </p>
  );

  useEffect(() => {
    const { state } = history.location;

    if (state && state.fromArrangement && state.fromSpot >= 0) {
      dispatch(selectMediaItems(true));
    }
  }, [history.location]);

  useEffect(() => {
    const mediaCount = selectedMedia.length;

    if (mediaCount > 0) {
      dispatch(
        infoBarSet({
          message: `${mediaCount} media item(s) selected`,
          timeout: 0,
          action: {
            text: 'deselect',
            handle: () => setSelected([]),
          },
        }),
      );
      dispatch(setInfoBarShow(true));
    } else {
      dispatch(setInfoBarShow(false));
    }
  }, [selectedMedia]);

  const toggleSelectedMedia = id => {
    setSelected(prevSelected => {
      if (prevSelected.indexOf(id) >= 0) {
        return prevSelected.filter(s => s !== id);
      }
      return [...prevSelected, id];
    });
  };

  const addMediaToArrangement = () => {
    const { state } = history.location;
    const arrangementId = state.fromArrangement;
    const spotId = state.fromSpot;
    const formValues = state.fromArrangementFormFormValues;
    const { newArrangement } = state;

    const promise = Promise.all(
      selectedMedia.map(id => {
        const formData = new FormData();
        formData.append('area_number', spotId);
        formData.append('media_item_id', id);
        formData.append('arrangement_id', arrangementId);

        return dispatch(
          fetchData(
            Paths.API.ARRANGEMENT_MEDIA_ITEMS_POST,
            {
              method: 'POST',
              body: formData,
            },
            false,
          ),
        );
      }),
    );

    promise
      .then(jsons => jsons.map(json => json))
      .then(result => {
        dispatch(selectMediaItems(false));

        const unprocessableMediaItems =
          result.filter(item => item.status === 422).length > 0;

        const location = {
          pathname: reverse(Paths.POOLS_ARRANGEMENTS, { id: pool.id }),
          state: {
            fromArrangement: arrangementId,
            fromArrangementFormFormValues: formValues,
            unprocessableMediaItems,
            newArrangement,
          },
        };
        history.push(location);
      });
  };

  const returnToArrangement = () => {
    dispatch(selectMediaItems(false));

    const { state } = history.location;
    const arrangementId = state.fromArrangement;
    const formValues = state.fromArrangementFormFormValues;
    const { newArrangement } = state;

    const location = {
      pathname: reverse(Paths.POOLS_ARRANGEMENTS, { id: pool.id }),
      state: {
        fromArrangement: arrangementId,
        fromArrangementFormFormValues: formValues,
        newArrangement,
      },
    };
    history.push(location);
  };

  return (
    <>
      {hasMediaViewRights ? (
        <>
          <Breadcrumbs />
          {(!selectable || !!folder_info) && (
            <FolderHeader
              title="All media"
              buttonType="editFolder"
              editable={!selectable}
              dynamicHeaderTitle
            />
          )}

          {!selectable && hasMediaEditRights && (
            <MediaUpload
              poolId={pool.id}
              folderId={folder_info && folder_info.id}
            />
          )}

          <FolderHeader
            title="Folders"
            buttonType="newFolder"
            editable={!selectable}
          />
          <Folders editable={!selectable} />

          <Files
            selectable={selectable}
            selectedMedia={selectedMedia}
            setSelected={toggleSelectedMedia}
          />

          {!selectable && hasMediaEditRights && (
            <PoolButton text="Add media" popupType={PopupTypes.MEDIA} />
          )}
          {selectedMedia.length > 0 && (
            <div className="detail-button">
              <Button
                text={<Icon name="plus" size="medium" />}
                hoverText="Insert media"
                hoverTextInverse
                onClick={() => addMediaToArrangement()}
              />
            </div>
          )}
          {selectable && selectedMedia.length === 0 && (
            <div className="detail-page__cancel-button">
              <Button
                text={<Icon name="cross" size="medium" />}
                hoverText="Cancel"
                color="red"
                onClick={() => returnToArrangement()}
                hoverTextInverse
              />
            </div>
          )}
        </>
      ) : (
        <DeckEmpty
          title="No view rights for media"
          subtitle={noMediaViewRightsSubTitle}
        />
      )}
    </>
  );
};

export default PoolsDetail;
