import React from 'react';
import DatePicker from 'react-datepicker';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import 'react-datepicker/dist/react-datepicker.css';
import './DateTime.scss';

import Label from 'components/form/label/Label';

const TimeInput = ({
  label,
  value,
  placeholder,
  id,
  name,
  onChange,
  isChanged,
  disabled,
}) => {
  return (
    <Label idFor={id} label={label}>
      <DatePicker
        selected={value}
        name={name}
        placeholder={placeholder}
        onChange={date => onChange(name, date)}
        dateFormat="HH:mm"
        className={classNames('form__datetime', 'form__datetime--time', {
          'form__datetime--changed': isChanged,
          'form__datetime--disabled': disabled,
        })}
        showTimeSelect
        showTimeSelectOnly
        timeInterval={30}
        disabled={disabled}
      />
    </Label>
  );
};

TimeInput.propTypes = {
  label: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  value: PropTypes.any,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  isChanged: PropTypes.bool,
  disabled: PropTypes.bool,
};

TimeInput.defaultProps = {
  label: '',
  value: new Date(),
  placeholder: '',
  name: '',
  onChange: () => {},
  isChanged: false,
  disabled: false,
};

export default TimeInput;
