import React from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { Paths } from 'constants/Routes';
import { reverse } from 'named-urls';
import { useHistory } from 'react-router-dom';

import './Breadcrumbs.scss';

const Breadcrumbs = ({ className, children, ...props }) => {
  const history = useHistory();
  const pool = useSelector(state => state.pool);

  let { breadcrumbs } = useSelector(state => state.media);

  const { folder_id } = useParams();

  if (folder_id && breadcrumbs) {
    breadcrumbs = breadcrumbs.filter(item => item.id !== Number(folder_id));
  }

  const itemClick = id => {
    const folderPath = (poolId, folderId) => {
      const pathName = window.location.pathname;

      switch (true) {
        case poolId !== undefined && !pathName.startsWith(Paths.MEDIA): {
          if (folderId !== undefined) {
            return reverse(Paths.POOLS_MEDIA_FOLDER, {
              id: poolId,
              folder_id: folderId,
            });
          }
          return reverse(Paths.POOLS_MEDIA, { id: poolId });
        }
        default: {
          if (folderId) {
            return reverse(Paths.MEDIA_FOLDER, { folder_id: folderId });
          }
          return Paths.MEDIA;
        }
      }
    };

    const path = folderPath(pool.id, id);
    const { state } = history.location;

    history.push({ pathname: path, state });
  };

  return (
    <div className={classNames('breadcrumbs', className)} {...props}>
      <button
        className="breadcrumb__link"
        type="button"
        onClick={() => itemClick()}
        onKeyDown={e => e.key === 13 && itemClick}
      >
        All media
      </button>
      {breadcrumbs &&
        breadcrumbs.map(
          ({ title, id }) =>
            title && (
              <button
                className="breadcrumb__link"
                type="button"
                onClick={() => itemClick(id)}
                onKeyDown={e => e.key === 13 && itemClick(id)}
                key={`${id}`}
              >
                {title}
              </button>
            ),
        )}
      {children}
    </div>
  );
};

export default Breadcrumbs;
