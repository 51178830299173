import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Formik } from 'formik';
import { CSSTransition } from 'react-transition-group';
import * as Yup from 'yup';

import Input from 'components/form/input/Input';
import FormBlock from 'components/form/block/FormBlock';
import Button from 'components/button/Button';
import Upload from 'components/form/upload/Upload';
import fetchData from 'store/fetchData';
import { popupActionClear } from 'store/popup/popupActions';
import { Paths } from 'constants/Routes';
import { addMediaAction } from 'store/media/mediaActions';
import MediaTypeDropdown from 'components/media/type-dropdown/MediaTypeDropdown';
import Icon from 'components/icon/Icon';
import { infoBarHide, setInfoBarError } from 'store/info/infoActions';
import { hasUserRights } from 'store/auth/hasUserRights';
import UserRights from 'constants/UserRights';
import RegexOptions from 'constants/RegexOptions';

const Media = ({ title }) => {
  const dispatch = useDispatch();
  const pool = useSelector(state => state.pool);
  const authToken = useSelector(state => state.auth.auth_token);
  const { folder_info } = useSelector(state => state.media);

  const infoBar = useSelector(state => state.info.bar);
  const hasMediaEditRights = dispatch(hasUserRights(UserRights.MEDIA_EDIT));
  const initialForm = {
    name: title || '',
    url: '',
    file: null,
  };

  const validationScheme = Yup.object().shape({
    name: Yup.string(),
    url: Yup.string().when('mediaType', {
      is: val => !!val,
      then: Yup.string()
        .matches(RegexOptions.URL, 'URL must be a valid URL')
        .required('URL is a required field'),
    }),
  });

  const onSubmit = values => {
    const mediaData = new FormData();
    mediaData.append(
      'media[name]',
      values.name || (values.file && values.file.name) || values.url,
    );

    // Media should no longer belong to a pool
    // mediaData.append('media[pool_ids]', [pool.id]);

    if (values.mediaType) {
      mediaData.append('media[media_item_type_id]', values.mediaType);

      if (values.url) {
        mediaData.append('media[source_url]', values.url);
      }
    }

    if (values.file) {
      mediaData.append('media[img_file]', values.file);
    }

    if (folder_info) {
      mediaData.append('media[media_folder_id]', folder_info.id);
    }

    const promise = dispatch(
      fetchData(Paths.API.MEDIA_POST, {
        method: 'POST',
        headers: {
          Authorization: authToken,
        },
        body: mediaData,
      }),
    );

    promise
      .then(json => json.json())
      .then(result => {
        dispatch(addMediaAction(result));
      })
      .catch(() => {
        dispatch(setInfoBarError('Could not add media item'));
      });
  };

  return (
    <Formik
      initialValues={initialForm}
      validationSchema={validationScheme}
      onSubmit={onSubmit}
    >
      {({
        values,
        handleChange,
        setFieldValue,
        handleSubmit,
        initialTouched,
        errors,
        touched,
      }) => (
        <form onSubmit={handleSubmit} className="form">
          <fieldset>
            <FormBlock>
              <Input
                type="text"
                name="name"
                id="name"
                placeholder="New media"
                value={values.name}
                error={errors.name}
                onChange={handleChange}
                fontSize="large"
                suffix={<Icon name="edit" />}
                disabled={!hasMediaEditRights}
              />
            </FormBlock>

            {pool.name && (
              <div className="form__information">
                <span>
                  {pool.name} - {pool.location.name}
                </span>
              </div>
            )}
          </fieldset>

          <fieldset>
            <legend>Upload or create</legend>

            <FormBlock>
              <Upload
                id="file"
                name="file"
                file={values.file}
                onChange={setFieldValue}
                disabled={!hasMediaEditRights}
              />
            </FormBlock>

            <FormBlock>
              <MediaTypeDropdown
                label="Or"
                name="mediaType"
                id="media_type"
                onChange={setFieldValue}
                value={values.mediaType}
                disabled={!!values.file || !hasMediaEditRights}
              />
            </FormBlock>

            <CSSTransition
              in={!!values.mediaType}
              timeout={300}
              classNames="fade"
              unmountOnExit
            >
              <FormBlock>
                <Input
                  type="text"
                  label=""
                  name="url"
                  id="url"
                  placeholder="URL"
                  scheme="white"
                  disabled={!!values.file || !hasMediaEditRights}
                  value={values.url}
                  onChange={handleChange}
                  error={touched.url && errors.url}
                />
              </FormBlock>
            </CSSTransition>
          </fieldset>

          <FormBlock hasInlineChildren isEnd>
            <Button
              tag="button"
              type="button"
              size="medium"
              text="Cancel"
              scheme="link"
              hasShadow={false}
              handler={() => {
                dispatch(popupActionClear());
                if (infoBar.show) {
                  dispatch(infoBarHide());
                }
              }}
            />
            <Button
              tag="button"
              type="submit"
              size="medium"
              text={initialTouched ? 'Save and close' : 'Close'}
              disabled={!hasMediaEditRights}
            />
          </FormBlock>
        </form>
      )}
    </Formik>
  );
};

export default Media;
