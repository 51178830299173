import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { logoutAction } from 'store/auth/loginActions';

const useAuthLogout = () => {
  const dispatch = useDispatch();

  const logoutCallback = useCallback(() => {
    dispatch(logoutAction());
  }, [dispatch]);

  return [logoutCallback];
};

export default useAuthLogout;
