import { twoFactorUnset } from 'store/auth/twoFactorActions';

const fetchData = (url, options, asJson = true) => async (
  dispatch,
  getState,
) => {
  const authToken = getState().auth.auth_token;

  let jsonHeaders = {};
  if (asJson) {
    jsonHeaders = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    };
  }

  const response = await fetch(`${process.env.REACT_APP_BASE_API}${url}`, {
    method: 'GET',
    headers: {
      Authorization: authToken,
      ...jsonHeaders,
    },
    ...options,
  });

  switch (response.status) {
    case 427:
      dispatch(twoFactorUnset());
      break;
    case 201:
    case 200:
      return response;
    default:
      break;
  }

  return response;
};

export default fetchData;
