import React, { useEffect, createRef } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import './Input.scss';

const Input = ({
  label,
  type,
  value,
  placeholder,
  id,
  name,
  disabled,
  className,
  size,
  fontSize,
  scheme,
  onChange,
  suffix,
  width,
  error,
  isChanged,
  ...props
}) => {
  const suffixRef = createRef();
  const inputRef = createRef();

  useEffect(() => {
    if (suffix && suffixRef.current) {
      const suffixEl = suffixRef.current;
      const inputEl = inputRef.current;
      const width = suffixEl.offsetWidth;

      inputEl.style.paddingRight = `${width + 4}px`;
    }
  }, [suffix, suffixRef, inputRef]);

  return (
    <label htmlFor={id}>
      {label && <span className="form__label">{label}</span>}

      <div className="form__input__container" style={{ width }}>
        <input
          type={type}
          id={id}
          value={value}
          placeholder={placeholder}
          name={name}
          disabled={disabled}
          onChange={onChange}
          className={classNames('form__input', className, {
            'form__input--error': !!error,
            'form__input--font-large': fontSize === 'large',
            'form__input--large': size === 'large',
            'form__input--changed': isChanged,
            [`form__input--${scheme}`]: scheme,
          })}
          ref={inputRef}
          {...props}
        />
        {suffix && (
          <div
            className={classNames('form__input__suffix', {
              'form__input__suffix--disabled': disabled,
            })}
          >
            {suffix}
          </div>
        )}
        {error && scheme !== '' && <span className="form__container-error" />}
      </div>

      {error && error !== true && (
        <p
          className={classNames('form__error', {
            'form__error--wide': scheme === '',
          })}
        >
          {error}
        </p>
      )}
    </label>
  );
};

Input.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  value: PropTypes.any,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  size: PropTypes.oneOf(['', 'large']),
  fontSize: PropTypes.oneOf(['', 'large']),
  scheme: PropTypes.oneOf(['', 'white', 'login']),
  onChange: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  suffix: PropTypes.any,
  width: PropTypes.number,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  isChanged: PropTypes.bool,
};

Input.defaultProps = {
  label: '',
  type: 'text',
  value: null,
  placeholder: '',
  name: '',
  disabled: false,
  className: '',
  size: '',
  fontSize: '',
  scheme: '',
  onChange: null,
  suffix: null,
  width: null,
  error: '',
  isChanged: false,
};

export default Input;
