import { TriggerActionTypes } from './triggersActions';

export const triggersInitialState = {
  request: false,
  triggers: [],
  error: null,
};

const triggersReducer = (state = triggersInitialState, action) => {
  switch (action.type) {
    case TriggerActionTypes.TRIGGERS_REQUESTED:
      return { ...triggersInitialState, request: true };
    case TriggerActionTypes.TRIGGERS_SUCCEEDED:
      return { triggers: [...action.payload], request: false, error: null };
    case TriggerActionTypes.TRIGGERS_FAILED:
      return { ...triggersInitialState, request: false, error: action.payload };
    case TriggerActionTypes.TRIGGER_ADDED:
      return {
        ...state,
        triggers: [action.payload, ...state.triggers],
      };
    case TriggerActionTypes.TRIGGERS_CLEARED:
      return triggersInitialState;
    case TriggerActionTypes.TRIGGER_DELETED:
      return {
        ...state,
        triggers: state.triggers.filter(item => item.id !== action.payload),
      };
    default:
      return state;
  }
};

export default triggersReducer;
