import fetchData from 'store/fetchData';
import { Paths } from 'constants/Routes';
import { reverse } from 'named-urls';

import {
  setInfoBarMessage,
  setInfoBarApproval,
  setInfoBarError,
  setInfoBarWarning,
  setInfoBarShow,
} from 'store/info/infoActions';
import {
  popupActionClear,
  popupActionSet,
  popupActionUpdate,
} from 'store/popup/popupActions';
import PopupTypes from 'constants/PopupTypes';
import { hasAdminRights, hasUserRights } from 'store/auth/hasUserRights';
import UserRights from 'constants/UserRights';

export const ArrangementsActionTypes = {
  ARRANGEMENT_REQUESTED: '@@arrangement/requested',
  ARRANGEMENT_SUCCEEDED: '@@arrangement/success',
  ARRANGEMENT_FAILED: '@@arrangement/error',
  ARRANGEMENT_ADDED: '@@arrangement/added',
  ARRANGEMENT_DELETED: '@@arrangement/deleted',
  ARRANGEMENT_CLEARED: '@@arrangement/cleared',
  ARRANGEMENT_ACTIVATED: '@@arrangement/activated',
  ARRANGEMENT_DEACTIVATED: '@@arrangement/deactivated',
  ARRANGEMENT_APPROVED: '@@arrangement/approved',
};

export const arrangementRequested = () => ({
  type: ArrangementsActionTypes.ARRANGEMENT_REQUESTED,
});

export const arrangementSucceeded = arrangements => ({
  type: ArrangementsActionTypes.ARRANGEMENT_SUCCEEDED,
  payload: arrangements,
});

export const arrangementFailed = () => ({
  type: ArrangementsActionTypes.ARRANGEMENT_FAILED,
});

export const arrangementAdded = arrangement => ({
  type: ArrangementsActionTypes.ARRANGEMENT_ADDED,
  payload: arrangement,
});

export const arrangementDeleted = id => ({
  type: ArrangementsActionTypes.ARRANGEMENT_DELETED,
  payload: id,
});

export const arrangementActivated = arrangement => ({
  type: ArrangementsActionTypes.ARRANGEMENT_ACTIVATED,
  payload: arrangement,
});

export const arrangementDeactivated = arrangement => ({
  type: ArrangementsActionTypes.ARRANGEMENT_DEACTIVATED,
  payload: arrangement,
});

export const arrangementCleared = () => ({
  type: ArrangementsActionTypes.ARRANGEMENT_CLEARED,
});

export const arrangementApproved = arrangement => ({
  type: ArrangementsActionTypes.ARRANGEMENT_APPROVED,
  payload: arrangement,
});

export const addArrangementAction = ({ message, arrangement }) => dispatch => {
  dispatch(popupActionClear());

  // remove existing arrangement from state to avoid duplicates
  dispatch(arrangementDeleted(arrangement.id));

  // add new arrangement to state
  dispatch(
    arrangementAdded({
      approval_state: arrangement.approval_state,
      pending_approval: arrangement.pending_approval,
      id: arrangement.id,
      name: arrangement.name,
      template: {
        id: arrangement.template_id,
      },
    }),
  );
  if (message) {
    dispatch(setInfoBarMessage(message));
  }
};

export const approvalArrangementAction = (
  id,
  defaultId,
  url,
  arrangementMedia,
  approved,
) => dispatch => {
  const promise = dispatch(
    fetchData(reverse(url, { id }), {
      method: 'POST',
    }),
  );

  promise
    .then(json => json.json())
    .then(result => {
      if (result.deleted) {
        dispatch(arrangementDeleted(id));
        dispatch(popupActionClear());
        dispatch(setInfoBarShow(false));
        return;
      }

      const { arrangement, message } = result;
      if (arrangement) {
        dispatch(arrangementApproved(arrangement));
      }

      const updateArrangement = {
        ...arrangement,
        pending_approval:
          arrangement.approval_state !== 'approved' &&
          arrangement.approval_state !== 'rejected',
      };

      const arrangementMediaObject = Object.entries(arrangementMedia); // generate object containing entries, so we also get the spot indexes

      if (!approved) {
        // Filter media without 'pending_approval' state from arrangementMediaObject, so we do not return media that was in 'pending_approval'
        const mediaWithoutPendingApproval = arrangementMediaObject.map(spot => {
          if (Array.isArray(spot) && spot.length) {
            const filteredSpot = spot[1].filter(
              media => media.approval_state !== 'pending_approval',
            );
            if (filteredSpot.length > 0) {
              return {
                ...spot[0],
                1: filteredSpot,
              };
            }
            return [];
          }
          return [];
        });

        // Set media approval state back to previous media state, so we get object containing spot indexes of previous arrangementMedia store state
        const previousMedia = mediaWithoutPendingApproval.map(spots => {
          if (Object.values(spots).length) {
            const media = spots[1].map(media => {
              if (media.pending_removal) {
                return {
                  ...media,
                  approval_state: 'approved',
                  pending_removal: false,
                };
              }
              return media;
            });
            return {
              ...spots[0],
              1: media,
            };
          }
          return spots;
        });

        updateArrangement.pending_changes = null;
        updateArrangement.media = Object.fromEntries(previousMedia); // Remove entries so spots containing previous media store state is returned
      }

      if (approved) {
        // Remove media containing 'pending_removal' state from arrangementMediaObject, so we do not return media that was in 'pending_removal' (removed items should not be returned in the store state)
        const newMedia = arrangementMediaObject.map(spot => {
          if (Array.isArray(spot) && spot.length) {
            const filteredSpot = spot[1].filter(
              media => !media.pending_removal,
            );

            if (filteredSpot.length > 0) {
              return {
                ...spot[0],
                1: filteredSpot,
              };
            }
            return [];
          }
          return [];
        });

        updateArrangement.pending_changes = null;
        updateArrangement.media = Object.fromEntries(newMedia); // Remove entries so spots containing new media store state is returned
      }

      dispatch(popupActionUpdate(updateArrangement));
      dispatch(setInfoBarMessage(message));
    });
};

/**
 * Delete arrangement and remove it from store state
 * @param {Number} id - ArrangementId
 * @param {Boolean} showNotification - Show/hide item deleted notification
 * @param {Object=} history - History state object
 */
export const deleteArrangementAction = (
  id,
  showNotification,
  history,
) => dispatch => {
  // call delete endpoint
  const promise = dispatch(
    fetchData(reverse(Paths.API.ARRANGEMENTS_DELETE, { id }), {
      method: 'DELETE',
    }),
  );

  promise
    .then(json => json.json())
    .then(result => {
      const { message } = result;

      // arrangement is deleted successfully - clear popup and show message
      dispatch(arrangementDeleted(id));
      dispatch(popupActionClear());
      if (showNotification) {
        dispatch(setInfoBarMessage(message));
      }

      if (history) {
        history.push({
          state: {
            fromArrangement: undefined,
            newArrangement: undefined,
          },
        });
      }
    })
    .catch(err => {
      // arrangement could not be deleted - show error
      console.error(err);
      dispatch(setInfoBarError(err));
    });
};

/**
 * Edit arrangement and update store state accordingly
 * @param {Number} id - ArrangementId
 * @param {Number} defaultId - Default arrangementId
 */
export const editArrangementAction = (id, defaultId) => dispatch => {
  const isAdmin = dispatch(hasAdminRights());
  const hasArrangementEditRights = dispatch(
    hasUserRights(UserRights.ARRANGEMENTS_EDIT),
  );
  const promise = dispatch(
    fetchData(reverse(Paths.API.ARRANGEMENTS_DETAIL, { id })),
  );

  promise
    .then(json => json.json())
    .then(arrangement => {
      dispatch(
        popupActionSet(PopupTypes.ARRANGEMENT, {
          ...arrangement,
          isDefault: arrangement.id === defaultId,
        }),
      );

      if (arrangement.pending_approval) {
        if (isAdmin) {
          dispatch(
            setInfoBarApproval(
              () => {
                dispatch(
                  approvalArrangementAction(
                    id,
                    defaultId,
                    Paths.API.ARRANGEMENTS_APPROVE,
                    arrangement.media,
                    true,
                  ),
                );
              },
              () => {
                dispatch(
                  approvalArrangementAction(
                    id,
                    defaultId,
                    Paths.API.ARRANGEMENTS_REJECT,
                    arrangement.media,
                    false,
                  ),
                );
              },
            ),
          );
        }
      }

      if (!hasArrangementEditRights) {
        dispatch(
          setInfoBarWarning('You only have the rights to view this show'),
        );
      }
    })
    .catch(err => {
      dispatch(setInfoBarError(err));
    });
};

/**
 * PATCH arrangement in case it already exist or POST arrangement in case it does not exist yet.
 * @param {Object} values - Values returned from form
 * @param {Number} id - ArrangementId
 * @param {Object} pool
 */
export const upsertArrangementAction = (values, id, pool) => dispatch => {
  const arrangementData = new FormData();
  arrangementData.append('arrangement[name]', values.name || 'New show');
  arrangementData.append('arrangement[template_id]', values.template);
  arrangementData.append('pool_id', pool.id);

  const promise = dispatch(
    fetchData(
      id
        ? reverse(Paths.API.ARRANGEMENTS_PATCH, { id })
        : Paths.API.ARRANGEMENTS_POST,
      {
        method: id ? 'PATCH' : 'POST',
        body: arrangementData,
      },
      false,
    ),
  );

  return promise;
};

/**
 * Toggle active arrangement (update it in database + update store state accordingly
 * @param {Number} id
 * @param {Boolean} isActive
 * @param {Function} setActive
 */
export const toggleActiveArrangementAction = (
  id,
  isActive,
  setActive,
) => dispatch => {
  const url = isActive
    ? Paths.API.ARRANGEMENTS_DEACTIVATE
    : Paths.API.ARRANGEMENTS_ACTIVATE;

  const promise = dispatch(
    fetchData(reverse(url, { id }), {
      method: 'POST',
    }),
  );
  promise
    .then(json => json.json())
    .then(result => {
      const { arrangement } = result;
      const arrangementStatus = arrangement.active;

      setActive(arrangementStatus);

      if (arrangementStatus) {
        dispatch(arrangementActivated(arrangement));
      } else {
        dispatch(arrangementDeactivated(arrangement));
      }
      dispatch(setInfoBarMessage(result.message));
    });
};

/**
 * Fetch arrangements and update store state accordingly
 * @param {Number} pool_id
 * @param {Boolean} unprocessableMediaItems - Media items that cannot be processed because it is not allowed (for instance adding image to spot that already contains a video
 * @param {Object=} history - History state object
 */
export const fetchArrangementsAction = (
  pool_id,
  unprocessableMediaItems,
  history,
) => dispatch => {
  dispatch(arrangementRequested());
  const promise = dispatch(
    fetchData(reverse(Paths.API.ARRANGEMENTS_BY_POOL, { pool_id })),
  );
  const historyLocationState = history?.location?.state;

  promise
    .then(json => json.json())
    .then(result => {
      let { arrangements } = result;

      // hide new arrangement from overview
      if (historyLocationState?.newArrangement) {
        arrangements = arrangements.map(arrangement => {
          if (arrangement.id === historyLocationState?.fromArrangement) {
            return {
              hidden: true,
              ...arrangement,
            };
          }
          return arrangement;
        });
      }

      dispatch(arrangementSucceeded({ arrangements }));

      if (unprocessableMediaItems) {
        const clearHistoryUnprocessableMediaItemsState = {
          location: {
            state: {
              unprocessableMediaItems: undefined,
            },
          },
        };
        history.replace(clearHistoryUnprocessableMediaItemsState);
        dispatch(
          setInfoBarError(
            'You tried adding media to a spot that cannot be added, because only one item of that type is allowed within the spot',
          ),
        );
      }
    })
    .catch(() => {
      dispatch(arrangementFailed());
      dispatch(
        setInfoBarError('Could not fetch arrangements', {
          text: 'retry',
          handle: () => dispatch(fetchArrangementsAction(pool_id)),
        }),
      );
    });
};
