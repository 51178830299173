import React from 'react';
import { useDispatch } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';

import FormBlock from 'components/form/block/FormBlock';
import Input from 'components/form/input/Input';
import Button from 'components/button/Button';
import { popupActionClear } from 'store/popup/popupActions';
import Icon from 'components/icon/Icon';
import { addPoolAction } from 'store/pool/poolActions';
import LocationDropdown from 'components/location/dropdown/LocationDropdown';

const Group = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const initialForm = {
    name: '',
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    location: Yup.number().required('Location is required'),
  });

  const onSubmit = values => {
    dispatch(addPoolAction(values, history));
  };

  return (
    <Formik
      initialValues={initialForm}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({
        submitCount,
        values,
        errors,
        touched,
        handleChange,
        setFieldValue,
        handleSubmit,
      }) => (
        <form onSubmit={handleSubmit}>
          <fieldset>
            <FormBlock>
              <Input
                type="text"
                name="name"
                id="name"
                placeholder="New group"
                value={values.name}
                error={touched.name && errors.name}
                onChange={handleChange}
                fontSize="large"
                suffix={<Icon name="edit" />}
              />
            </FormBlock>
          </fieldset>

          <fieldset>
            <FormBlock>
              <LocationDropdown
                id="group_location"
                name="location"
                label="Location"
                value={values.location}
                error={(touched.location || submitCount > 0) && errors.location}
                onChange={setFieldValue}
              />
            </FormBlock>
          </fieldset>

          <FormBlock hasInlineChildren isEnd>
            <Button
              tag="button"
              type="submit"
              size="medium"
              text="Cancel"
              scheme="link"
              hasShadow={false}
              handler={() => {
                dispatch(popupActionClear());
              }}
            />

            <Button
              tag="button"
              type="submit"
              size="medium"
              text="Save and close"
            />
          </FormBlock>
        </form>
      )}
    </Formik>
  );
};

export default Group;
