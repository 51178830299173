import fetchData from 'store/fetchData';
import { Paths } from 'constants/Routes';
import { setInfoBarMessage } from 'store/info/infoActions';

export const PoolsActionTypes = {
  POOLS_REQUESTED: '@@pools/requested',
  POOLS_SUCCEEDED: '@@pools/success',
  POOLS_FAILED: '@@pools/error',
  POOLS_CLEARED: '@@pools/cleared',
};

export const poolsRequested = () => ({
  type: PoolsActionTypes.POOLS_REQUESTED,
});

export const poolsSucceeded = pools => ({
  type: PoolsActionTypes.POOLS_SUCCEEDED,
  payload: pools,
});

export const poolsFailed = () => ({
  type: PoolsActionTypes.POOLS_FAILED,
});

export const poolsCleared = () => ({
  type: PoolsActionTypes.POOLS_CLEARED,
});

export const fetchPoolsAction = (name, location, history) => async dispatch => {
  dispatch(poolsRequested());

  const url = Paths.API.POOLS;
  const isFiltering = !!name || !!location;
  const params = new URLSearchParams();

  if (name) {
    params.append('name', name);
  }
  if (location) {
    params.append('location_id', location);
  }

  const promise = dispatch(
    fetchData(`${url}${isFiltering ? `?${params.toString()}` : ''}`),
  );
  promise
    .then(json => json.json())
    .then(result => {
      const { state } = history.location;
      if (state && state.groupDeleted) {
        const clearHistoryGroupDeletedState = {
          state: {
            groupDeleted: undefined,
          },
        };
        history.replace(clearHistoryGroupDeletedState);
        dispatch(setInfoBarMessage('Group is removed'));
      }
      dispatch(poolsSucceeded(result));
    })
    .catch(() => {
      dispatch(poolsFailed());
    });
};
