import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as LogoFull } from '../../assets/logo/logoFull.svg';
import { ReactComponent as LogoBrandMark } from '../../assets/logo/logoBrandMark.svg';

import './Logo.scss';

const Logo = ({ brandMark }) => {
  return brandMark ? <LogoBrandMark /> : <LogoFull className="logo-full" />;
};

Logo.propTypes = {
  brandMark: PropTypes.bool,
};

Logo.defaultProps = {
  brandMark: false,
};

export default Logo;
