const PopupTypes = {
  ARRANGEMENT: 'arrangement',
  EVENT: 'event',
  TRIGGER: 'trigger',
  MEDIA: 'media',
  MEDIA_PREVIEW: 'media-preview',
  INFO: 'info',
  GROUP: 'group',
  DEVICES: 'devices',
  DEVICE_DETAIL: 'device-detail',
  MEDIA_FOLDER: 'media-folder',
};

export default PopupTypes;
