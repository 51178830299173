import React, { useCallback } from 'react';
import classNames from 'classnames';
import { useDropzone } from 'react-dropzone';

import './Upload.scss';

const Upload = ({ id, name, text, file, onChange }) => {
  const onDrop = useCallback(
    files => {
      // single select
      const file = files[0];
      onChange(name, file);

      const reader = new FileReader();
      reader.onload = () => {
        const { result } = reader;
        console.info(result);
      };
      reader.readAsArrayBuffer(file);
    },
    [name, onChange],
  );

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <div
      className={classNames('form__upload', {
        'form__upload--selected': !!file,
      })}
      {...getRootProps()}
    >
      {(!file && (
        <>
          <p className="form__upload__text">
            {text || (
              <>
                Drag and drop your media or{' '}
                <span>browse the media library</span>
              </>
            )}
          </p>
          <input id={id} name={name} type="upload" {...getInputProps()} />
        </>
      )) || <>{file.name}</>}
    </div>
  );
};

export default Upload;
