import React from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';

import Label from 'components/form/label/Label';
import Icon from 'components/icon/Icon';

import './DropdownMultiple.scss';
import classNames from 'classnames';

const Dropdown = ({
  label,
  name,
  values,
  value,
  onChange,
  id,
  width,
  disabled,
  isLoading,
  isChanged,
  error,
}) => {
  const dropdownStyling = {
    container: provided => ({
      ...provided,
      width,
    }),
    indicatorSeparator: () => ({
      border: 0,
    }),
    dropdownIndicator: provided => ({
      ...provided,
      display: 'none',
    }),
    multiValueLabel: provided => ({
      ...provided,
      fontSize: '100%',
    }),
  };

  const selectedValues =
    values &&
    value &&
    value.map(v => {
      if (v && (v.value || v.value === 0) && v.label) {
        return v;
      }

      return values.find(o => o.value === v);
    });

  return (
    <Label label={label} idFor={id}>
      <div
        className={classNames('form__dropdown__container', {
          'form__dropdown__container--disabled': disabled,
        })}
      >
        <Select
          styles={dropdownStyling}
          name={name}
          id={id}
          className={classNames('form__dropdown', {
            'form__dropdown--changed': isChanged,
          })}
          classNamePrefix="form__dropdown"
          options={values}
          value={selectedValues}
          isDisabled={disabled}
          isLoading={isLoading}
          onChange={e => onChange(e)}
          menuPosition="fixed"
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          isClearable={false}
          isMulti
        />

        <Icon
          name="chevron"
          className={classNames('', {
            'form__dropdown__icon--disabled': disabled,
          })}
        />
      </div>

      {error && <p className="form__error">{error}</p>}
    </Label>
  );
};

Dropdown.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  values: PropTypes.arrayOf(PropTypes.any),
  // eslint-disable-next-line react/forbid-prop-types
  value: PropTypes.any,
  onChange: PropTypes.func,
  width: PropTypes.number,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  isChanged: PropTypes.bool,
};

Dropdown.defaultProps = {
  label: '',
  name: '',
  values: [],
  value: '',
  onChange: () => {},
  width: null,
  disabled: false,
  isLoading: false,
  error: false,
  isChanged: false,
};

export default Dropdown;
