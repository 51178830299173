import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Upload from 'components/form/upload/Upload';
import fetchData from 'store/fetchData';
import { addMediaAction } from 'store/media/mediaActions';
import { Paths } from 'constants/Routes';

import './Upload.scss';

const MediaUpload = ({ poolId, folderId }) => {
  const dispatch = useDispatch();
  const authToken = useSelector(state => state.auth.auth_token);
  const [file, setFile] = useState(null);

  const onFileDrop = (name, file) => {
    setFile(file);

    const mediaData = new FormData();
    mediaData.append('media[img_file]', file);
    mediaData.append('media[name]', file.name);

    if (poolId) {
      mediaData.append('media[pool_id]', poolId);
    }

    if (folderId) {
      mediaData.append('media[media_folder_id]', folderId);
    }

    const promise = dispatch(
      fetchData(Paths.API.MEDIA_POST, {
        method: 'POST',
        headers: {
          Authorization: authToken,
        },
        body: mediaData,
      }),
    );

    promise
      .then(json => json.json())
      .then(result => {
        if (result) {
          dispatch(addMediaAction(result));

          setTimeout(() => {
            setFile(null);
          }, 5000);
        }
      });
  };

  return (
    <div className="media-upload">
      <Upload
        id="mediaupload"
        name="mediaupload"
        file={file}
        onChange={onFileDrop}
      />
    </div>
  );
};

export default MediaUpload;
