import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { reverse } from 'named-urls';
import filesize from 'filesize';
import { useHistory } from 'react-router-dom';
import equal from 'fast-deep-equal';

import FormBlock from 'components/form/block/FormBlock';
import Button from 'components/button/Button';
import fetchData from 'store/fetchData';
import { Paths } from 'constants/Routes';
import Input from 'components/form/input/Input';
import {
  mediaAdded,
  mediaDeleted,
  deleteMediaAction,
} from 'store/media/mediaActions';
import {
  infoBarHide,
  setInfoBarShow,
  setInfoBarConfirm,
  setInfoBarMessage,
} from 'store/info/infoActions';
import { popupActionClear } from 'store/popup/popupActions';
import MediaTypeDropdown from 'components/media/type-dropdown/MediaTypeDropdown';
import Icon from 'components/icon/Icon';
import { hasUserRights } from 'store/auth/hasUserRights';
import UserRights from 'constants/UserRights';
import MediaFolderDropdown from 'components/media/folder-dropdown/MediaFolderDropdown';
import RegexOptions from 'constants/RegexOptions';

import './MediaPreview.scss';

const MediaPreview = ({
  id,
  name,
  filename,
  filetype,
  file_size,
  media_file_url,
  created_at,
  source_url,
  media_item_type_id,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const hasMediaEditRights = dispatch(hasUserRights(UserRights.MEDIA_EDIT));
  const infoBar = useSelector(state => state.info.bar);

  const initialForm = {
    name: name || '',
    url: source_url || '',
    mediaType: media_item_type_id || '',
    mediaFolderId: undefined,
  };

  const validationScheme = Yup.object().shape({
    name: Yup.string().required(),
    url: Yup.string().when('mediaType', {
      is: val => !!val,
      then: Yup.string()
        .matches(RegexOptions.URL, 'URL must be a valid URL')
        .required('URL is a required field'),
    }),
  });

  const onSubmit = values => {
    if (equal(values, initialForm)) {
      dispatch(popupActionClear());
      return;
    }

    const mediaData = new FormData();
    mediaData.append('name', values.name);

    if (values.url) {
      mediaData.append('source_url', values.url);
    }

    if (values.mediaType) {
      mediaData.append('media_item_type_id', values.mediaType);
    }

    if (values.mediaFolderId >= 0) {
      mediaData.append(
        'media_folder_id',
        values.mediaFolderId === 0 ? '' : values.mediaFolderId,
      );
    }

    const promise = dispatch(
      fetchData(
        reverse(Paths.API.MEDIA_DETAIL, { id }),
        {
          method: 'PATCH',
          body: mediaData,
        },
        false,
      ),
    );

    promise
      .then(json => json.json())
      .then(result => {
        dispatch(mediaDeleted(id));

        if (result.media) {
          if (!values.mediaFolderId && values.mediaFolderId !== 0) {
            dispatch(mediaAdded(result.media));
          }
          dispatch(popupActionClear());
        }

        if (values.mediaFolderId >= 0) {
          dispatch(
            setInfoBarMessage(
              'The media item has been repositioned',
              {
                handle: () => {
                  dispatch(setInfoBarShow(false));
                  history.push({
                    pathname:
                      values.mediaFolderId === 0
                        ? Paths.MEDIA
                        : reverse(Paths.MEDIA_FOLDER, {
                            folder_id: values.mediaFolderId,
                          }),
                  });
                },
                text: 'Go to folder',
              },
              true,
            ),
          );
        } else {
          dispatch(setInfoBarMessage(result.message));
        }
      });
  };

  return (
    <Formik
      initialValues={initialForm}
      validationSchema={validationScheme}
      onSubmit={onSubmit}
    >
      {({
        values,
        handleSubmit,
        handleChange,
        setFieldValue,
        initialTouched,
        errors,
      }) => (
        <form onSubmit={handleSubmit} className="form">
          <FormBlock>
            <Input
              type="text"
              name="name"
              id="name"
              placeholder="Media name"
              value={values.name}
              onChange={handleChange}
              fontSize="large"
              error={errors.name}
              suffix={<Icon name="edit" />}
              disabled={!hasMediaEditRights}
            />
          </FormBlock>

          {!!media_item_type_id && (
            <>
              <FormBlock>
                <MediaTypeDropdown
                  label="Type"
                  name="mediaType"
                  id="media_type"
                  onChange={setFieldValue}
                  value={values.mediaType}
                  disabled={!!values.file || !hasMediaEditRights}
                />
              </FormBlock>

              <FormBlock>
                <Input
                  type="text"
                  label="URL"
                  name="url"
                  id="url"
                  placeholder="URL"
                  scheme="white"
                  disabled={!!values.file || !hasMediaEditRights}
                  value={values.url}
                  onChange={handleChange}
                  error={errors.url}
                />
              </FormBlock>
            </>
          )}

          <div className="media-preview">
            {['image', 'video'].indexOf(filetype) >= 0 && (
              <div className="media-preview__image">
                {filetype === 'image' && (
                  <img src={`${media_file_url}`} alt={name} />
                )}

                {filetype === 'video' && (
                  // eslint-disable-next-line jsx-a11y/media-has-caption
                  <video controls>
                    <source src={`${media_file_url}`} />
                  </video>
                )}
              </div>
            )}

            <div className="media-preview__info">
              <h3 className="media-preview__info__title">Media details</h3>
              <ul className="media-preview__info__list list--reset">
                {filename && (
                  <li>
                    <strong>Original file name:</strong>
                    <span>{filename}</span>
                  </li>
                )}
                {file_size && (
                  <li>
                    <strong>Filesize:</strong>
                    <span>{filesize(file_size)}</span>
                  </li>
                )}
                <li>
                  <strong>Uploaded on:</strong>
                  <span>{created_at}</span>
                </li>
              </ul>
            </div>
          </div>

          {hasMediaEditRights && (
            <fieldset>
              <legend className="form__legend--spacing">
                Placement of media item
              </legend>
              <FormBlock>
                <MediaFolderDropdown
                  name="mediaFolderId"
                  label="Where should the media item be located"
                  onChange={setFieldValue}
                />
              </FormBlock>
            </fieldset>
          )}

          <FormBlock hasInlineChildren isEnd>
            {(hasMediaEditRights && (
              <Button
                tag="button"
                type="button"
                size="medium"
                text="Delete"
                scheme="link"
                hasShadow={false}
                handler={() => {
                  dispatch(
                    setInfoBarConfirm(
                      'Are you sure you want to delete this media item?',
                      {
                        text: 'Yes, Delete',
                        handle: () => dispatch(deleteMediaAction(id)),
                      },
                    ),
                  );
                }}
              />
            )) || (
              <Button
                tag="button"
                type="submit"
                size="medium"
                text="Cancel"
                scheme="link"
                hasShadow={false}
                handler={() => {
                  dispatch(popupActionClear());
                  if (infoBar.show) {
                    dispatch(infoBarHide());
                  }
                }}
              />
            )}

            <Button
              tag="button"
              type="submit"
              size="medium"
              text={initialTouched ? 'Save and close' : 'Close'}
              disabled={!hasMediaEditRights}
            />
          </FormBlock>
        </form>
      )}
    </Formik>
  );
};

export default MediaPreview;
