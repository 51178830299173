import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router';

import Overview from 'pages/pools/Overview';
import PoolsDetail from 'pages/pools/Detail';
import Login from 'pages/auth/Login';
import TwoFactor from 'pages/auth/TwoFactor';
import Media from 'pages/media/Overview';

import { Paths, PrivatePaths, PublicPaths } from 'constants/Routes';
import PrivateBody from 'components/body/private/Private';
import PublicBody from 'components/body/public/Public';
import useAuthRouting from 'hooks/useAuthRouting';
import Popup from 'components/popup/Popup';
import Infobar from 'components/infobar/Infobar';
import UserRights from 'constants/UserRights';
import { hasUserRights } from './store/auth/hasUserRights';

const App = () => {
  useAuthRouting();
  const dispatch = useDispatch();
  const auth = useSelector(state => state.auth);

  const privateRoutes = () => {
    const redirect = () => {
      const hasEventsViewRights =
        auth.user?.rights && dispatch(hasUserRights(UserRights.EVENTS_VIEW));
      const hasTriggersViewRights =
        auth.user?.rights && dispatch(hasUserRights(UserRights.TRIGGERS_VIEW));
      const hasArrangementsViewRights =
        auth.user?.rights &&
        dispatch(hasUserRights(UserRights.ARRANGEMENTS_VIEW));
      const hasMediaViewRights =
        auth.user?.rights && dispatch(hasUserRights(UserRights.MEDIA_VIEW));
      const hasDevicesViewRights =
        auth.user?.rights && dispatch(hasUserRights(UserRights.SIGNS_VIEW));

      const rights = {
        hasEventsViewRights,
        hasTriggersViewRights,
        hasArrangementsViewRights,
        hasMediaViewRights,
        hasDevicesViewRights,
      };
      switch (true) {
        case rights.hasEventsViewRights:
          return Paths.POOLS_SCHEDULES;
        case rights.hasTriggersViewRights:
          return Paths.POOLS_TRIGGERS;
        case rights.hasArrangementsViewRights:
          return Paths.POOLS_ARRANGEMENTS;
        case rights.hasMediaViewRights:
          return Paths.POOLS_MEDIA;
        case rights.hasDevicesViewRights:
          return Paths.POOLS_DEVICES;
        default:
          return Paths.POOLS_INFO;
      }
    };

    return (
      <PrivateBody>
        <Switch>
          <Route path={Paths.POOLS} exact component={Overview} />
          <Redirect path={Paths.POOLS_DETAIL} exact to={redirect()} />
          <Route path={Paths.POOLS_DETAIL} component={PoolsDetail} />
          <Route path={[Paths.MEDIA_FOLDER, Paths.MEDIA]} component={Media} />
        </Switch>

        <Infobar />
        <Popup />
      </PrivateBody>
    );
  };

  const publicRoutes = () => (
    <PublicBody>
      <Route path={Paths.TWOFACTOR} exact component={TwoFactor} />
      <Route path={Paths.LOGIN} component={Login} />
      <Popup position="center" />
    </PublicBody>
  );

  return (
    <Switch>
      <Route path={PublicPaths} component={publicRoutes} />
      <Route path={PrivatePaths} component={privateRoutes} />
    </Switch>
  );
};

export default App;
