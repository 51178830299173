import React from 'react';
import { useDispatch } from 'react-redux';

import Calendar from 'components/calendar/Calendar';
import PoolButton from 'components/pool/button/PoolButton';
import PopupTypes from 'constants/PopupTypes';
import { hasUserRights } from 'store/auth/hasUserRights';
import UserRights from 'constants/UserRights';
import DeckEmpty from 'components/deck/DeckEmpty';
import Button from 'components/button/Button';

const PoolsDetail = () => {
  const dispatch = useDispatch();
  const hasEventsEditRights = dispatch(hasUserRights(UserRights.EVENTS_EDIT));
  const hasEventsViewRights = dispatch(hasUserRights(UserRights.EVENTS_VIEW));

  const noEventsViewRightsSubTitle = (
    <p>
      In case you should have view rights please contact your supervisor or send
      an email to{' '}
      <Button
        hasShadow={false}
        scheme="link"
        link="mailto:support@gamingsupport.com"
        text="support@gamingsupport.com"
        size="inline"
      />
    </p>
  );

  return (
    <>
      {hasEventsViewRights ? (
        <>
          <Calendar />
          {hasEventsEditRights && (
            <PoolButton text="Add event" popupType={PopupTypes.EVENT} />
          )}
        </>
      ) : (
        <DeckEmpty
          title="No view rights for events"
          subtitle={noEventsViewRightsSubTitle}
        />
      )}
    </>
  );
};

export default PoolsDetail;
