import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { Paths } from 'constants/Routes';
import fetchData from 'store/fetchData';
import Dropdown from 'components/form/dropdown/Dropdown';

const LocationDropdown = ({ poolId, ...props }) => {
  const dispatch = useDispatch();
  const [locations, setLocations] = useState([]);
  const [loadingLocations, setLoadingLocations] = useState(false);

  useEffect(() => {
    setLoadingLocations(true);
    const promise = dispatch(fetchData(Paths.API.LOCATIONS));
    promise
      .then(json => json.json())
      .then(result => {
        const locs = result.locations.map(loc => {
          return {
            label: loc.name,
            value: loc.id,
          };
        });

        setLocations(locs);
      })
      .catch(err => {
        console.error(err);
        setLocations([]);
      })
      .finally(() => {
        setLoadingLocations(false);
      });

    return () => {
      setLoadingLocations(false);
      setLocations([]);
    };
  }, [poolId]);

  return (
    <Dropdown {...props} values={locations} isLoading={loadingLocations} />
  );
};

export default LocationDropdown;
