import React from 'react';
import { Link } from 'react-router-dom';
import { reverse } from 'named-urls';
import { useSelector, useDispatch } from 'react-redux';

import { Paths } from 'constants/Routes';
import Container from 'components/container/Container';
import Card from 'components/card/Card';
import PoolFilter from 'components/pool/filter/PoolFilter';
import PoolButton from 'components/pool/button/PoolButton';
import PopupTypes from 'constants/PopupTypes';

import './Overview.scss';
import { hasUserRights } from 'store/auth/hasUserRights';
import UserRights from 'constants/UserRights';

const PoolsOverview = () => {
  const dispatch = useDispatch();
  const hasPoolsEditRights = dispatch(hasUserRights(UserRights.POOLS_EDIT));
  const { pools } = useSelector(state => state.pools);

  return (
    <div className="overview-page">
      <div className="overview-page__filter">
        <Container>
          <PoolFilter />
        </Container>
      </div>
      <div className="overview-page__content">
        <Container>
          <h1>All groups</h1>

          <section className="overview-list">
            <header className="overview-list__header">
              <span>Groups</span>
              <span>Location</span>
              <span>Devices</span>
              <span>Notification</span>
            </header>

            <ul className="overview-list__list">
              {pools &&
                pools.map(
                  ({
                    id,
                    name,
                    location,
                    approval_requested,
                    sign_information,
                  }) => (
                    <li key={id}>
                      <Link to={reverse(Paths.POOLS_DETAIL, { id })}>
                        <Card className="overview-list__list-item">
                          <span>{name}</span>
                          <span>{location}</span>
                          <span>{sign_information.count}</span>
                          <span>
                            {approval_requested ? 'Approval needed' : null}
                          </span>
                        </Card>
                      </Link>
                    </li>
                  ),
                )}
            </ul>
          </section>
        </Container>
        {hasPoolsEditRights && (
          <PoolButton text="Add group" popupType={PopupTypes.GROUP} />
        )}
      </div>
    </div>
  );
};

export default PoolsOverview;
