import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import FormBlock from 'components/form/block/FormBlock';
import Button from 'components/button/Button';
import List from 'components/list/List';

import { popupActionClear } from 'store/popup/popupActions';
import { infoBarHide, setInfoBarConfirm } from 'store/info/infoActions';
import { deleteDeviceAction } from 'store/devices/devicesActions';
import { hasUserRights } from 'store/auth/hasUserRights';
import UserRights from 'constants/UserRights';

const DeviceDetail = ({ name, id, values }) => {
  const dispatch = useDispatch();
  const hasDevicesEditRights = dispatch(hasUserRights(UserRights.SIGNS_EDIT));
  const infoBar = useSelector(state => state.info.bar);

  return (
    <div>
      <h2>Device details</h2>
      <h3>{name}</h3>
      <List values={values} id={id} />

      <FormBlock hasInlineChildren>
        {hasDevicesEditRights && (
          <Button
            tag="button"
            type="button"
            size="medium"
            text="Remove device from group"
            scheme="link"
            hasShadow={false}
            handler={() => {
              dispatch(
                setInfoBarConfirm(
                  'Are you sure you want to remove the device from this group?',
                  {
                    text: 'Yes, Delete',
                    handle: () => dispatch(deleteDeviceAction(id)),
                  },
                ),
              );
            }}
          />
        )}

        <Button
          tag="button"
          type="submit"
          size="medium"
          text="Close"
          handler={() => {
            dispatch(popupActionClear());
            if (infoBar.show) {
              dispatch(infoBarHide());
            }
          }}
        />
      </FormBlock>
    </div>
  );
};

export default DeviceDetail;
