import React from 'react';
import { useDispatch } from 'react-redux';

import Card from 'components/card/Card';
import Button from 'components/button/Button';
import Icon from 'components/icon/Icon';
import { editDevicesAction } from 'store/devices/devicesActions';
import Status from 'components/status/Status';
import Heading from 'components/typography/heading/Heading';

import './DevicesCard.scss';

const DevicesCard = ({ id, name, active, pendingApproval }) => {
  const dispatch = useDispatch();

  const cardClick = () => {
    dispatch(editDevicesAction(id));
  };

  return (
    <button
      type="button"
      tabIndex="0"
      onClick={cardClick}
      onKeyDown={e => e.key === 13 && cardClick}
      className="button__interaction"
    >
      <Card className="devices-card" zoomable>
        <Heading className="devices-card__title" level={3}>
          {name}
        </Heading>

        <div className="card__state">
          <Status
            isActive={active !== 'offline'}
            needsApproval={pendingApproval}
          />
        </div>

        <Button
          tag="span"
          text={<Icon name="arrow-right" />}
          size="small"
          hasShadow={false}
          className="card__button"
          scheme="card"
        />
      </Card>
    </button>
  );
};

export default DevicesCard;
