import { MediaActionTypes } from './mediaActions';

export const mediaInitialState = {
  request: false,
  media: [],
  folders: [],
  breadcrumbs: [],
  folder_info: null,
  selectable: false,
};

const mediaReducer = (state = mediaInitialState, action) => {
  switch (action.type) {
    case MediaActionTypes.MEDIA_SELECTABLE:
      return { ...state, selectable: action.payload };
    case MediaActionTypes.MEDIA_REQUESTED:
      return { ...state, request: true };
    case MediaActionTypes.MEDIA_SUCCEEDED:
      return { ...state, ...action.payload, request: false };
    case MediaActionTypes.MEDIA_FAILED:
      return { ...mediaInitialState, request: false };
    case MediaActionTypes.MEDIA_FOLDER_UPDATED:
      return {
        ...state,
        folders: state.folders.map(folder => {
          if (folder.id === action.payload.folder_info.id) {
            return {
              ...folder,
              ...action.payload.folder_info,
            };
          }
          return folder;
        }),
        folder_info:
          action.payload.folder_info.id === state.folder_info?.id
            ? action.payload.folder_info
            : state.folder_info,
      };
    case MediaActionTypes.MEDIA_FOLDER_ADDED:
      return { ...state, folders: [action.payload, ...state.folders] };
    case MediaActionTypes.MEDIA_FOLDER_DELETED:
      return {
        ...state,
        folders: state.folders.filter(item => item.id !== action.payload),
      };
    case MediaActionTypes.MEDIA_ADDED:
      return { ...state, media: [action.payload, ...state.media] };
    case MediaActionTypes.MEDIA_DELETED:
      return {
        ...state,
        media: state.media.filter(item => item.id !== action.payload),
      };
    case MediaActionTypes.MEDIA_CLEARED:
      return mediaInitialState;
    default:
      return state;
  }
};

export default mediaReducer;
