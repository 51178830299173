import React from 'react';

const List = ({ values, id }) => {
  return (
    <ul className="list--reset">
      {values.map(
        ({ label, value }) =>
          value !== null &&
          value !== '' && (
            <li key={`${id}_${value}`}>
              <b>{label}:</b> {value}
            </li>
          ),
      )}
    </ul>
  );
};

export default List;
