import React, { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from 'components/button/Button';
import UserRights from 'constants/UserRights';
import { hasUserRights } from 'store/auth/hasUserRights';
import { deletePoolAction } from 'store/pool/poolActions';
import { setInfoBarDelete, infoBarHide } from 'store/info/infoActions';
import { useHistory } from 'react-router-dom';
import List from 'components/list/List';

const PoolsInfo = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const pool = useSelector(state => state.pool);
  const hasPoolsEditRights = dispatch(hasUserRights(UserRights.POOLS_EDIT));

  const groupDetails = [
    {
      label: 'ID',
      value: pool.id,
    },
    {
      label: 'Created on',
      value: pool.created_at,
    },
  ];

  return (
    <div>
      <h2>Group details</h2>
      <List values={groupDetails} id={pool.id} />

      {hasPoolsEditRights && (
        <Button
          tag="a"
          size="medium"
          scheme="link"
          text="Delete this group"
          hasShadow={false}
          handler={() => {
            dispatch(
              setInfoBarDelete(
                'Are you sure you want to continue deleting this group?',
                () => {
                  dispatch(deletePoolAction(pool.id, history));
                },
                () => {
                  dispatch(infoBarHide());
                },
              ),
            );
          }}
        />
      )}

      {pool.signs && pool.signs.length > 0 && <h3>Signs:</h3>}
      <ul>
        {pool.signs.map(sign => {
          return (
            <li key={sign.serial_number}>
              {Object.entries(sign).map(([key, value]) => {
                if (!(value instanceof Object)) {
                  return (
                    <Fragment key={key}>
                      {key}: {value} <br />
                    </Fragment>
                  );
                }
                return '';
              })}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default PoolsInfo;
