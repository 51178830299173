import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { submitLoginAction } from 'store/auth/loginActions';
import Input from 'components/form/input/Input';
import Button from 'components/button/Button';
import Logo from 'components/logo/Logo';
import FormMessage from 'components/form/formMessage/FormMessage';

import './Login.scss';
import Text from '../../components/typography/text/Text';
import { popupActionSet } from '../../store/popup/popupActions';
import PopupTypes from '../../constants/PopupTypes';

const Login = () => {
  const dispatch = useDispatch();
  const authError = useSelector(state => state.auth?.error);

  const initialValues = {
    username: '',
    password: '',
  };

  const loginSchema = Yup.object().shape({
    username: Yup.string().required(),
    password: Yup.string().required(),
  });

  const forgotCredentials = () => {
    const children = (
      <>
        <h2>Contact your supervisor</h2>
        <p>
          To request a new password send an email to{' '}
          <Button
            hasShadow={false}
            scheme="link"
            link="mailto:support@gamingsupport.com"
            text="support@gamingsupport.com"
            size="inline"
          />
        </p>
      </>
    );

    dispatch(popupActionSet(PopupTypes.INFO, children));
  };

  return (
    <>
      <Logo />
      <Formik
        initialValues={initialValues}
        validationSchema={loginSchema}
        onSubmit={async values => {
          dispatch(submitLoginAction(values.password, values.username));
        }}
      >
        {({ values, errors, handleChange, handleSubmit }) => (
          <form onSubmit={handleSubmit} className="form">
            <div className="login-page__fields-container">
              <div className="login-page__field">
                <Input
                  type="text"
                  name="username"
                  id="username"
                  placeholder="Username"
                  size="large"
                  scheme="login"
                  aria-label="Username"
                  value={values.username}
                  error={errors.username ? true : null}
                  onChange={handleChange}
                />
              </div>
              <div className="login-page__field">
                <Input
                  type="password"
                  name="password"
                  id="password"
                  placeholder="Password"
                  size="large"
                  scheme="login"
                  aria-label="Password"
                  value={values.password}
                  error={errors.password ? true : null}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="login-page__error-message-container">
              <FormMessage name="username" type="formikErrorMessage" />
              <FormMessage name="password" type="formikErrorMessage" />
              <FormMessage errorMessage={authError} />
            </div>
            <div className="login-page__login-button">
              <Button
                tag="button"
                type="submit"
                size="large"
                text="Log in"
                block
              />
            </div>
            <Text
              size="small"
              color="grey-dark"
              flatten
              className="login-page__lost-credentials-link"
            >
              I lost my credentials.{' '}
              <Button
                onClick={() => forgotCredentials()}
                scheme="link"
                text="What should I do?"
                size="inline"
                hasShadow={false}
              />
            </Text>
          </form>
        )}
      </Formik>
    </>
  );
};

export default Login;
