import React from 'react';
import { useDispatch } from 'react-redux';

import Button from 'components/button/Button';
import { popupActionSet } from 'store/popup/popupActions';
import { setInfoBarShow } from 'store/info/infoActions';
import Icon from 'components/icon/Icon';

const PoolButton = ({ text, popupType }) => {
  const dispatch = useDispatch();

  return (
    <div className="detail-button">
      <Button
        text={<Icon name="plus" size="medium" />}
        hoverText={text}
        hoverTextInverse
        handler={() => {
          dispatch(popupActionSet(popupType, {}));
          dispatch(setInfoBarShow(false));
        }}
      />
    </div>
  );
};

export default PoolButton;
