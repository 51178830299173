import { combineReducers, createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';

import authReducer from './auth/authReducer';
import poolsReducer from './pools/poolsReducer';
import poolReducer from './pool/poolReducer';
import popupReducer from './popup/popupReducer';
import infoReducer from './info/infoReducer';
import mediaReducer from './media/mediaReducer';
import eventsReducer from './events/eventsReducer';
import devicesReducer from './devices/devicesReducer';
import arrangementsReducer from './arrangements/arrangementsReducer';
import triggersReducer from './triggers/triggersReducer';
import { loadState, saveState } from './localStorage';

const rootReducer = combineReducers({
  auth: authReducer,
  info: infoReducer,
  pools: poolsReducer,
  pool: poolReducer,
  events: eventsReducer,
  popup: popupReducer,
  media: mediaReducer,
  arrangements: arrangementsReducer,
  devices: devicesReducer,
  triggers: triggersReducer,
});

const persistedStorage = loadState();
const composeEnhancers = composeWithDevTools({
  trace: true,
  traceLimit: 25,
});
const store = createStore(
  rootReducer,
  persistedStorage,
  composeEnhancers(applyMiddleware(thunk)),
);

store.subscribe(() => {
  saveState({
    auth: store.getState().auth,
  });
});

export default store;
