import fetchData from 'store/fetchData';
import { Paths } from 'constants/Routes';
import { reverse } from 'named-urls';
import {
  setInfoBarMessage,
  setInfoBarError,
  setInfoBarApproval,
  setInfoBarWarning,
  setInfoBarShow,
} from 'store/info/infoActions';
import {
  popupActionClear,
  popupActionSet,
  popupActionUpdate,
} from 'store/popup/popupActions';
import PopupTypes from 'constants/PopupTypes';
import { hasAdminRights, hasUserRights } from 'store/auth/hasUserRights';
import UserRights from 'constants/UserRights';

export const EventsActionTypes = {
  EVENTS_REQUESTED: '@@events/requested',
  EVENTS_SUCCEEDED: '@@events/success',
  EVENTS_FAILED: '@@events/error',
  EVENTS_ADDED: '@@events/added',
  EVENTS_DELETED: '@@events/deleted',
  EVENTS_CLEARED: '@@events/cleared',
  EVENTS_APPROVED: '@@events/approved',
  EVENTS_ACTIVATED: '@@events/activated',
};

export const eventsRequested = () => ({
  type: EventsActionTypes.EVENTS_REQUESTED,
});

export const eventsSucceeded = events => ({
  type: EventsActionTypes.EVENTS_SUCCEEDED,
  payload: events,
});

export const eventsFailed = () => ({
  type: EventsActionTypes.EVENTS_FAILED,
});

export const eventsAdded = event => ({
  type: EventsActionTypes.EVENTS_ADDED,
  payload: event,
});

export const eventsDeleted = id => ({
  type: EventsActionTypes.EVENTS_DELETED,
  payload: id,
});

export const eventsCleared = () => ({
  type: EventsActionTypes.EVENTS_CLEARED,
});

export const eventsApproved = event => ({
  type: EventsActionTypes.EVENTS_APPROVED,
  payload: event,
});

export const eventsActivated = (id, active) => ({
  type: EventsActionTypes.EVENTS_ACTIVATED,
  payload: {
    id,
    active,
  },
});

export const approvalEventAction = (id, url) => dispatch => {
  const promise = dispatch(
    fetchData(reverse(url, { id }), {
      method: 'POST',
    }),
  );

  promise
    .then(json => json.json())
    .then(result => {
      if (result.deleted) {
        dispatch(eventsDeleted(id));
        dispatch(popupActionClear());
        dispatch(setInfoBarShow(false));
        return;
      }

      const { scheduled_event, message } = result;
      const event = JSON.parse(scheduled_event);

      if (event) {
        dispatch(eventsApproved(event));
      }

      const newEvent = {
        ...event,
        title: event.title,
        startDate: event.start_at,
        endDate: event.end_at,
        arrangement: event.arrangement_id,
        rrule: event.rrule,
        pending_approval: event.approval_state !== 'approved',
      };

      if (event.approval_state === 'approved') {
        newEvent.pending_changes = null;
      }

      dispatch(popupActionUpdate(newEvent));
      dispatch(setInfoBarMessage(message));
    });
};

export const deleteEventAction = id => dispatch => {
  // call delete endpoint
  const promise = dispatch(
    fetchData(reverse(Paths.API.EVENTS_DELETE, { id }), { method: 'DELETE' }),
  );

  promise
    .then(json => json.json())
    .then(result => {
      const { message } = result;
      // media is deleted succesfully - clear popup and show message
      dispatch(eventsDeleted(id));
      dispatch(popupActionClear());

      dispatch(setInfoBarMessage(message));
    })
    .catch(err => {
      // event could not be deleted - show error
      dispatch(setInfoBarError(err));
    });
};

export const editEventAction = id => async dispatch => {
  const isAdmin = dispatch(hasAdminRights());
  const hasEventEditRights = dispatch(hasUserRights(UserRights.EVENTS_EDIT));

  const promise = dispatch(fetchData(reverse(Paths.API.EVENTS_DETAIL, { id })));
  promise
    .then(json => json.json())
    .then(({ scheduled_event }) => {
      dispatch(
        popupActionSet(PopupTypes.EVENT, {
          id: scheduled_event.id,
          title: scheduled_event.title,
          startDate: scheduled_event.start_at,
          endDate: scheduled_event.end_at,
          rrule: scheduled_event.rrule,
          arrangement: scheduled_event.arrangement.id,
          active: scheduled_event.active,
          roundTime: false,
          pending_approval: scheduled_event.pending_approval,
          pending_changes: scheduled_event?.pending_changes,
        }),
      );

      if (scheduled_event.pending_approval) {
        if (isAdmin) {
          dispatch(
            setInfoBarApproval(
              () => {
                dispatch(approvalEventAction(id, Paths.API.EVENTS_APPROVE));
              },
              () => {
                dispatch(approvalEventAction(id, Paths.API.EVENTS_REJECT));
              },
            ),
          );
        }
      }

      if (!hasEventEditRights) {
        dispatch(
          setInfoBarWarning('You only have the rights to view this event'),
        );
      }
    })
    .catch(err => {
      console.error(err);
      dispatch(setInfoBarError(err));
    });
};

export const addEventAction = (event, id) => dispatch => {
  const isEditing = !!id;

  // create or edit event
  const promise = dispatch(
    fetchData(
      isEditing
        ? reverse(Paths.API.EVENTS_PATCH, { id })
        : Paths.API.EVENTS_POST,
      {
        method: isEditing ? 'PATCH' : 'POST',
        body: event,
      },
      false,
    ),
  );

  promise
    .then(json => json.json())
    .then(result => {
      const { message, scheduled_event } = result;
      const returnEvent = JSON.parse(scheduled_event);

      // successfully added or edited an event
      dispatch(popupActionClear());
      dispatch(setInfoBarMessage(message));

      if (isEditing) {
        // remove existing event from state to avoid duplicates
        dispatch(eventsDeleted(id));
      }
      // add new event to state
      dispatch(
        eventsAdded({
          ...returnEvent,
          pending_approval: returnEvent.approval_state === 'pending',
        }),
      );
    })
    .catch(err => {
      console.error(err);
      dispatch(setInfoBarError('Failed to add new event'));
    });
};

export const toggleActiveEventAction = (
  id,
  isActive,
  setActive,
  pending_approval,
) => dispatch => {
  if (!pending_approval) {
    const url = isActive
      ? Paths.API.EVENTS_DEACTIVATE
      : Paths.API.EVENTS_ACTIVATE;

    const promise = dispatch(
      fetchData(reverse(url, { id }), {
        method: 'POST',
      }),
    );
    promise
      .then(json => json.json())
      .then(result => {
        setActive(result.scheduled_event.active);
        dispatch(eventsActivated(id, result.scheduled_event.active));
        dispatch(setInfoBarMessage(result.message));
      });
  }
};

export const fetchEventsAction = (
  poolId,
  startDate,
  endDate,
) => async dispatch => {
  dispatch(eventsRequested());

  const dateParams = new URLSearchParams({
    start_date: startDate,
    end_date: endDate,
  });

  const promise = dispatch(
    fetchData(
      `${reverse(Paths.API.EVENTS_BY_POOL, {
        pool_id: poolId,
      })}?${dateParams.toString()}`,
    ),
  );

  promise
    .then(json => json.json())
    .then(result => {
      const events = result.pool.scheduled_events;
      dispatch(eventsSucceeded(events));
    })
    .catch(() => {
      dispatch(eventsFailed());
    });
};
