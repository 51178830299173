import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './BlankButton.scss';

const BlankButton = ({
  tag,
  handler,
  className,
  children,
  elementRef,
  ...props
}) => {
  return React.createElement(
    tag,
    {
      type: 'button',
      tabIndex: -1,
      onClick: handler || null,
      onKeyDown: e => (e.key === 13 && handler) || null,
      className: classNames('blank-button', className),
      ref: elementRef,
      ...props,
    },
    children,
  );
};

BlankButton.propTypes = {
  tag: PropTypes.string,
};

BlankButton.defaultProps = {
  tag: 'button',
};

export default BlankButton;
