const UserRights = {
  POOLS_EDIT: 'pools_edit',
  SIGNS_VIEW: 'signs',
  SIGNS_EDIT: 'signs_edit',
  EVENTS_VIEW: 'scheduled_events',
  EVENTS_EDIT: 'scheduled_events_edit',
  ARRANGEMENTS_VIEW: 'arrangements',
  ARRANGEMENTS_EDIT: 'arrangements_edit',
  MEDIA_VIEW: 'media',
  MEDIA_EDIT: 'media_edit',
  TRIGGERS_VIEW: 'triggers',
  TRIGGERS_EDIT: 'triggers_edit',
  MEDIA_FOLDERS_EDIT: 'media_folders_edit',
};

export default UserRights;
