import fetchData from 'store/fetchData';
import { Paths } from 'constants/Routes';
import { reverse } from 'named-urls';

import {
  setInfoBarMessage,
  setInfoBarError,
  setInfoBarWarning,
} from 'store/info/infoActions';

import { popupActionClear, popupActionSet } from 'store/popup/popupActions';
import PopupTypes from 'constants/PopupTypes';
import { hasUserRights } from '../auth/hasUserRights';
import UserRights from '../../constants/UserRights';

export const DevicesActionTypes = {
  DEVICES_REQUESTED: '@@devices/requested',
  DEVICES_SUCCEEDED: '@@devices/success',
  DEVICES_FAILED: '@@devices/error',
  DEVICES_CLEARED: '@@devices/cleared',
  DEVICE_DELETED: '@@devices/deleted',
  DEVICE_ADDED: '@@devices/added',
};

export const devicesRequested = () => ({
  type: DevicesActionTypes.DEVICES_REQUESTED,
});

export const devicesSucceeded = devices => ({
  type: DevicesActionTypes.DEVICES_SUCCEEDED,
  payload: devices,
});

export const devicesFailed = () => ({
  type: DevicesActionTypes.DEVICES_FAILED,
});

export const deviceAdded = sign => ({
  type: DevicesActionTypes.DEVICE_ADDED,
  payload: sign,
});

export const devicesCleared = () => ({
  type: DevicesActionTypes.DEVICES_CLEARED,
});

export const deviceDeleted = id => ({
  type: DevicesActionTypes.DEVICE_DELETED,
  payload: id,
});

export const fetchDevicesAction = (pool_id, history) => async dispatch => {
  dispatch(devicesRequested());

  const promise = dispatch(
    fetchData(`${Paths.API.DEVICES}?pool_id=${pool_id}`),
  );
  promise
    .then(json => json.json())
    .then(result => {
      if (result.signs) {
        dispatch(devicesSucceeded(result));

        const { state } = history.location;
        if (state && state.groupAdded) {
          const clearHistoryGroupAddedState = {
            state: {
              groupAdded: undefined,
            },
          };
          history.replace(clearHistoryGroupAddedState);
          dispatch(
            setInfoBarMessage(
              'The new group was added. Now add some devices you want to show content on.',
            ),
          );
        }
      } else {
        dispatch(devicesFailed());
      }
    })
    .catch(() => {
      dispatch(devicesFailed());
    });
};

export const editDevicesAction = id => async dispatch => {
  const hasDevicesEditRights = dispatch(hasUserRights(UserRights.SIGNS_EDIT));
  const promise = dispatch(
    fetchData(reverse(Paths.API.DEVICES_DETAIL, { id })),
  );
  promise
    .then(json => json.json())
    .then(result => {
      const resolution =
        result.height && result.width ? `${result.height}x${result.width}` : '';

      dispatch(
        popupActionSet(PopupTypes.DEVICE_DETAIL, {
          name: result.name,
          id: result.id,
          values: [
            { label: 'Location', value: result.location },
            { label: 'Serial', value: result.serial_number },
            { label: 'Status', value: result.online_status },
            { label: 'Added date', value: result.created_at },
            { label: 'Resolution', value: resolution },
            { label: 'Software version', value: result.version_number },
            { label: 'API key', value: result.api_key },
            { label: 'Available storage', value: result.available_storage },
            { label: 'Total storage', value: result.total_storage },
          ],
        }),
      );

      if (!hasDevicesEditRights) {
        dispatch(
          setInfoBarWarning('You only have the rights to view this device'),
        );
      }
    })
    .catch(err => {
      console.error(err);
      // dispatch(setInfoBarError(err));
    });
};

export const addDeviceAction = ({ id, pool_id }) => async dispatch => {
  const signData = new FormData();
  signData.append('sign[pool_id]', pool_id);

  console.log('toet');

  const promise = dispatch(
    fetchData(
      reverse(Paths.API.DEVICES_PATCH, { id }),
      {
        method: 'PATCH',
        body: signData,
      },
      false,
    ),
  );
  promise
    .then(json => json.json())
    .then(result => {
      dispatch(popupActionClear());
      dispatch(deviceAdded(result.sign));
      dispatch(setInfoBarMessage(result.message));
    })
    .catch(err => {
      console.error(err);
      dispatch(setInfoBarError(err));
    });
};

export const deleteDeviceAction = id => async dispatch => {
  const promise = dispatch(
    fetchData(reverse(Paths.API.DEVICE_DELETE, { id }), { method: 'POST' }),
  );
  promise
    .then(result => {
      if (result) {
        // device is succesfully deleted - clear popup and show message
        dispatch(deviceDeleted(id));
        dispatch(setInfoBarMessage('Device removed from group'));
      }

      dispatch(popupActionClear());
    })
    .catch(err => {
      console.error(err);
      // dispatch(setInfoBarError(err));
    });
};
