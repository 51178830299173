import React, { useEffect, createRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, withRouter, matchPath } from 'react-router-dom';
import { reverse } from 'named-urls';
import classNames from 'classnames';

import { Paths } from 'constants/Routes';
import Container from 'components/container/Container';

import './PoolNavigation.scss';
import { hasUserRights } from 'store/auth/hasUserRights';
import UserRights from 'constants/UserRights';

require('intersection-observer');

const PoolNavigation = ({ pool, ...props }) => {
  const dispatch = useDispatch();
  const poolRef = createRef();
  const [isStuck, setStuck] = useState(false);
  const { location } = props;

  const hasMediaViewRights = dispatch(hasUserRights(UserRights.MEDIA_VIEW));
  const hasArrangementsViewRights = dispatch(
    hasUserRights(UserRights.ARRANGEMENTS_VIEW),
  );
  const hasEventsViewRights = dispatch(hasUserRights(UserRights.EVENTS_VIEW));
  const hasDevicesViewRights = dispatch(hasUserRights(UserRights.SIGNS_VIEW));
  const hasTriggersViewRights = dispatch(
    hasUserRights(UserRights.TRIGGERS_VIEW),
  );
  const { selectable } = useSelector(state => state.media);

  const routes = [
    {
      Key: 'schedules',
      Path: Paths.POOLS_SCHEDULES,
      Name: 'Schedules',
      ViewRights: hasEventsViewRights,
    },
    {
      Key: 'triggers',
      Path: Paths.POOLS_TRIGGERS,
      Name: 'Triggers',
      ViewRights: hasTriggersViewRights,
    },
    {
      Key: 'arrangements',
      Path: Paths.POOLS_ARRANGEMENTS,
      Name: 'Shows',
      ViewRights: hasArrangementsViewRights,
    },
    {
      Key: 'media',
      Path: Paths.POOLS_MEDIA,
      Name: 'Media',
      ViewRights: hasMediaViewRights,
    },
    {
      Key: 'devices',
      Path: Paths.POOLS_DEVICES,
      Name: 'Devices',
      ViewRights: hasDevicesViewRights,
    },
    {
      Key: 'details',
      Path: Paths.POOLS_INFO,
      Name: 'Details',
      ViewRights: true,
    },
  ].map(route => {
    return {
      ...route,
      Active: !!matchPath(location.pathname, route.Path),
    };
  });

  useEffect(() => {
    const el = poolRef.current;
    const observer = new IntersectionObserver(
      ([entry]) => {
        const stuck = entry.intersectionRatio < 1;
        setStuck(stuck);
      },
      {
        threshold: [1],
      },
    );

    observer.observe(el);

    return () => {
      observer.unobserve(el);
    };
  }, [poolRef, setStuck]);

  return (
    <div
      className={classNames('pool-navigation', {
        'pool-navigation--stuck': isStuck,
      })}
      ref={poolRef}
    >
      <Container>
        <ul className="pool-navigation__links">
          <li className="pool-navigation__title">{pool.name}</li>
          {routes.map(
            route =>
              route.ViewRights && (
                <li key={route.Key}>
                  {selectable ? (
                    <span
                      className={classNames(
                        'pool-navigation__link pool-navigation__link--disabled',
                        {
                          'pool-navigation__link--active': route.Active,
                        },
                      )}
                    >
                      {route.Name}
                    </span>
                  ) : (
                    <Link
                      to={reverse(route.Path, { id: pool.id })}
                      className={classNames('pool-navigation__link', {
                        'pool-navigation__link--active': route.Active,
                      })}
                    >
                      {route.Name}
                    </Link>
                  )}
                </li>
              ),
          )}
        </ul>
      </Container>
    </div>
  );
};

export default withRouter(PoolNavigation);
