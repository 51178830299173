import { LoginActionTypes } from './loginActions';
import { TwoFactorActionTypes } from './twoFactorActions';

export const loginInitialState = {
  request: false,
  auth_token: '',
  two_factor_required: false,
  two_factor_is_set: false,
  two_factor_resend_email: 'unsent',
  user: {},
};

const authReducer = (state = loginInitialState, action) => {
  switch (action.type) {
    case LoginActionTypes.LOGIN_REQUESTED:
      return { ...loginInitialState, ...action.payload };
    case LoginActionTypes.LOGIN_SUCCEEDED:
      return { ...loginInitialState, ...action.payload };
    case LoginActionTypes.LOGIN_FAILED:
      return { ...loginInitialState, ...action.payload };
    case TwoFactorActionTypes.TWOFACTOR_SET:
      return { ...state, two_factor_is_set: true };
    case TwoFactorActionTypes.TWOFACTOR_UNSET:
      return { ...state, two_factor_is_set: false };
    case TwoFactorActionTypes.TWOFACTOR_RESEND_EMAIL_PROCESSING:
      return { ...state, two_factor_resend_email: 'processing' };
    case TwoFactorActionTypes.TWOFACTOR_RESEND_EMAIL_SUCCESS:
      return { ...state, two_factor_resend_email: 'success' };
    case TwoFactorActionTypes.TWOFACTOR_RESEND_EMAIL_FAILED:
      return { ...state, two_factor_resend_email: 'failed' };
    case LoginActionTypes.LOGOUT:
      return { ...loginInitialState };
    default:
      return state;
  }
};

export default authReducer;
