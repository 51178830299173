import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import './Deck.scss';

const Deck = ({ columns, children }) => {
  return (
    <div
      className={classNames('card-deck', {
        [`card-deck--columns-${columns}`]: columns > 0,
      })}
    >
      {children}
    </div>
  );
};

Deck.propTypes = {
  columns: PropTypes.oneOf([0, 2, 3]),
};

Deck.defaultProps = {
  columns: 0,
};

export default Deck;
