import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Icon from 'components/icon/Icon';

import './MediaCardImage.scss';

const MediaCardImage = ({ type, title, image, setImageNotLoaded }) => {
  const [imageIsIcon, setImageIsIcon] = useState(true);
  const [imageContent, setImageContent] = useState(true);

  const setEmptyImage = () => {
    setImageContent(<Icon name="image" />);
    setImageIsIcon(true);
    return true;
  };

  useEffect(() => {
    switch (type) {
      case 'video':
        setImageContent(<Icon name={type} />);
        break;
      case 'image':
        setImageContent(
          <img
            src={`${image}`}
            alt={title}
            onError={e => {
              setEmptyImage();
              setImageNotLoaded(false);
              e.onerror = null;
            }}
          />,
        );
        setImageIsIcon(false);
        break;
      default:
        setImageContent(<Icon name="url" />);
        break;
    }
  }, [type, image, title]);

  return (
    <div
      className={classNames('media-card__image', {
        'media-card__image--icon': imageIsIcon,
      })}
    >
      {imageContent && <>{imageContent}</>}
    </div>
  );
};

MediaCardImage.propTypes = {
  title: PropTypes.string,
  image: PropTypes.string,
  type: PropTypes.oneOf(['rtsp', 'video', 'image', 'rss', 'other']),
};

MediaCardImage.defaultProps = {
  title: '',
  image: '',
  type: 'other',
};

export default MediaCardImage;
