import React from 'react';
import PropTypes from 'prop-types';
import Heading from 'components/typography/heading/Heading';
import Text from 'components/typography/text/Text';

import './DeckEmpty.scss';

const DeckEmpty = ({ title, subtitle }) => {
  return (
    <div className="deck-empty">
      {title && (
        <Heading className="deck-empty__title" level={3}>
          {title}
        </Heading>
      )}
      {subtitle && <Text className="deck-empty__subtitle">{subtitle}</Text>}
    </div>
  );
};

DeckEmpty.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

DeckEmpty.defaultProps = {
  title: '',
  subtitle: '',
};

export default DeckEmpty;
