import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Card from 'components/card/Card';
import { editMediaFolderAction } from 'store/media/mediaActions';
import { setInfoBarShow } from 'store/info/infoActions';
import Icon from 'components/icon/Icon';
import { hasUserRights } from 'store/auth/hasUserRights';
import UserRights from 'constants/UserRights';
import Heading from 'components/typography/heading/Heading';
import BlankButton from 'components/button/BlankButton';

import './MediaCardFolder.scss';

const MediaCardFolder = ({ title, id, link, editable }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const hasMediaFoldersEditRights = dispatch(
    hasUserRights(UserRights.MEDIA_FOLDERS_EDIT),
  );
  const cardClick = () => {
    const { state } = history.location;

    history.push({ pathname: link, state });
  };

  const cardSettingsClick = (e, id) => {
    e.stopPropagation();
    dispatch(editMediaFolderAction(id));
    dispatch(setInfoBarShow(false));
  };

  return (
    <BlankButton tag="a" handler={cardClick} className="button__interaction">
      <Card className="media-card-folder">
        <div className="media-card-folder__inner">
          <div className="media-card-folder__label">
            <Icon name="folder" className="media-card-folder__icon" />
            <Heading level={3} className="media-card-folder__heading">
              {title}
            </Heading>
          </div>

          {hasMediaFoldersEditRights && editable && (
            <BlankButton handler={e => cardSettingsClick(e, id)}>
              <div className="media-card-folder__hover">
                <Icon name="more_options" />
              </div>
            </BlankButton>
          )}
        </div>
      </Card>
    </BlankButton>
  );
};

MediaCardFolder.propTypes = {
  id: PropTypes.number,
  title: PropTypes.string,
  link: PropTypes.string,
  editable: PropTypes.bool,
};

MediaCardFolder.defaultProps = {
  id: null,
  title: '',
  link: '',
  editable: false,
};

export default MediaCardFolder;
